import Cookies from 'js-cookie';
import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter, Input, Button, FormGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import wilayas from "../assets/wilayas.json";
import communes from "../assets/communes.json";
import {useHistory}  from 'react-router-dom';


export default function CreateContact() {
  
  const history = useHistory();
  // work hours modal
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);
  // doctors arround modal
  const [Verticalcenter2, setVerticalcenter2] = useState(false);
  const Verticalcentermodaltoggle2 = () => setVerticalcenter2(!Verticalcenter2);
  const [isLoading, setIsLoading] = useState(false);
  const special = [
    "Allergologie",
    "Anatomie et Cytologie Pathologiques",
    "Andrologie",
    "Anesthésiologie-réanimation",
    "Angiologie",
    "Biologie médicale",
    "Cardiologie et maladies vasculaires",
    "Chirurgie Cardio-vasculaire",
    "Chirurgie digestive",
    "Chirurgie generale",
    "Chirurgie maxillo-faciale",
    "Chirurgie orthopdique",
    "Chirurgie pediatrique",
    "Chirurgie Plastique",
    "Chirurgie thoracique",
    "Chirurgie viscerale",
    "Dermatologie et vénérologie",
    "Endocrinologie et métabolismes",
    "Gastro-entérologie et hépatologie",
    "Gynécologie-Obstetrique",
    "Génétique médicale",
    "Gériatrie",
    "Hématologie et onco-hématologie",
    "Indefinie",
    "Infectiologie",
    "Information Médicale DIM",
    "Informatique Médicale et Technologie",
    "Medecine d'expertise",
    "Medecine de l'addiction",
    "Medecine du sport",
    "Medecine legale",
    "Médecine d'urgence",
    "Médecine du travail",
    "Médecine générale",
    "Médecine hyperbare",
    "Médecine interne",
    "Médecine nucléaire",
    "Médecine nutritionnelle",
    "Médecine physique et de réadaptation",
    "Médecin Légiste",
    "Neuro-chirurgie",
    "Neurologie",
    "Néphrologie",
    "O.R.L.",
    "Oncologie médicale",
    "Oncologie radiothérapique ou R...",
    "Ophtalmologie",
    "Pharmacologie",
    "Pneumologie",
    "Psychiatrie",
    "Psychiatrie infanto-juvenile",
    "Pédiatrie",
    "Radiodiagnostic et Imagerie médicale",
    "Rhumatologie",
    "Santé publique et médecine sociale",
    "Stomatologie",
    "Toxicologie",
    "Urologie"
  ]

  const token = Cookies.get("jwt");
  const [wilayaID, setWilayaID] = useState("");
  const [inputData, setInputData] = useState({
    contact_lastname: "",
    contact_firstname: "",
    contact_wilaya: "",
    contact_city: "",
    contact_sexe: "",
    contact_establishment: "",
    contact_email: "",
    contact_age: "",
    contact_address: "",
    contact_phone: "",
    contact_region: "",
    contract_start_date: "",
    contract_end_date: "",
    contact_space: "",
    contact_location_lat: "",
    contact_location_lng: "",
    referrel_name: "",
    cosmetics: 0,
    gynecology: 0,
    pain: 0,
    pediatrics: 0,
    complements: 0,
    business_type: ""
  });




  const days = ["sat", "sun", "mon", "tue", "wed", "thu", "fri"]
  const [schedual, setSchedual] = useState([])


  const handleWilayaChange = (e) => {
    const wilayaChosen = e.target.value;
    const wilayaID = wilayas.filter((w) => w.nom === wilayaChosen)[0]?.code;
    setWilayaID(wilayaID);
    const contact_city = communes.filter(
      (item) => item.wilaya_id === wilayaID
    )[0]?.nom;
    setInputData({ ...inputData, contact_city, contact_wilaya: wilayaChosen });

  };

  const handleRemove = (key) => {
    const dr = document.getElementById(key)
    dr.remove()
  }

  const addWorkHours = () => {
    let scheduleWork = {};
    for (let index = 0; index < days.length; index++) {
      let startInput = document.getElementById(`${days[index]}start`).value;
      let finishInput = document.getElementById(`${days[index]}finish`).value;
      if (startInput !== "" && finishInput !== "") {
        scheduleWork[days[index]] = startInput + " - " + finishInput;
      }
    }
    setSchedual(scheduleWork)
    Verticalcentermodaltoggle()
  }
  const [around, setAround] = useState([])
  const [drAround, setDrAround] = useState([])
  const addDoctorsArround = () => {
    let docs = []
    for (let i = 0; i < around.length; i++) {
      let firstname = document.getElementById(`${i}doctor_firstname`).value;
      let lastname = document.getElementById(`${i}doctor_lastname`).value;
      let address = document.getElementById(`${i}doctor_address`).value;
      let speciality = document.getElementById(`${i}doctor_speciality`).value;


      if (firstname !== "" && lastname !== "") {
        docs.push({
          "doctor_around_firstname": firstname,
          "doctor_around_lastname": lastname,
          "doctor_around_address": address,
          "doctor_around_speciality": speciality,
        })
      }

    }
    setDrAround(docs)
    Verticalcentermodaltoggle2()
  }


  const create = async () => {
    if (inputData.contact_lastname =="") {
      toast.error("the contact lastname is required !")
      return 
    }
    if (inputData.contact_firstname =="") {
      toast.error("the contact fistname is required !")
      return 
    }
    if (inputData.contact_phone =="") {
      toast.error("the contact phone is required !")
      return 
    }
    if (inputData.contact_email =="") {
      toast.error("the contact email is required !")
      return 
    }
    if (inputData.contact_wilaya =="") {
      toast.error("the contact wilaya is required !")
      return 
    }
    if (inputData.contact_city =="") {
      toast.error("the contact city is required !")
      return 
    }
    if (inputData.contact_location_lng =="") {
      toast.error("the contact location lng is required !")
      return 
    }
    if (inputData.contact_location_lat =="") {
      toast.error("the contact location lat is required !")
      return 
    }
    if (inputData.contract_start_date =="") {
      toast.error("the contract start date is required !")
      return 
    }
    if (inputData.contract_end_date =="") {
      toast.error("the contract end date is required !")
      return 
    }
    if (inputData.contact_space =="") {
      toast.error("the contact must have a 10sec video !")
      return 
    }
    if (inputData.business_type =="") {
      toast.error("business type is required!")
      return 
    }
    if ((parseInt(inputData.cosmetics) + parseInt(inputData.gynecology) + parseInt(inputData.pain) + parseInt(inputData.pediatrics) + parseInt(inputData.complements) != 100) && (parseInt(inputData.cosmetics) + parseInt(inputData.gynecology) + parseInt(inputData.pain) + parseInt(inputData.pediatrics) + parseInt(inputData.complements) != 0)) {
      toast.error("The sum of the potentials is not 100%")
      return
    }
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        ...inputData,
        "scheduleWork": schedual,
        drAround
      })
    }
    try {
      var response = await fetch("https://admins.adspot.pub/api/admins/contact/new", request, { mode: 'cors' });

      const res = await response.json();
      if (response.status === 200) {
        toast.success("Contact added Successfully!");
        history.push(`${process.env.PUBLIC_URL}/contacts/all`);
        setIsLoading(false);
      } else {
        toast.error(res);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  }
  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Contacts" title="" hide={false} />
      <Container fluid="true">
        <Row className='justify-content-center'>
          <Modal
            isOpen={Verticalcenter}
            toggle={Verticalcentermodaltoggle}
            centered
            size="lg"
          >
            <ModalHeader toggle={Verticalcentermodaltoggle}>
              Add Work Hours
            </ModalHeader>
            <ModalBody>
              <Row id="saturday">
                <Col md="2" style={{ lineHeight: "38px" }}>
                  Saturday:
                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="satstart"
                    type="time"
                    placeholder="Start"
                  />

                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="satfinish"
                    type="time"
                    placeholder="Finish"
                  />

                </Col>

              </Row>

              <Row id="sunday">
                <Col md="2" style={{ lineHeight: "38px" }}>
                  Sunday:
                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="sunstart"
                    type="time"
                    placeholder="Start"
                  />

                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="sunfinish"
                    type="time"
                    placeholder="Finish"
                  />

                </Col>

              </Row>

              <Row id="monday">
                <Col md="2" style={{ lineHeight: "38px" }}>
                  Monday:
                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="monstart"
                    type="time"
                    placeholder="Start"
                  />

                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="monfinish"
                    type="time"
                    placeholder="Finish"
                  />

                </Col>

              </Row>

              <Row id="tuesday">
                <Col md="2" style={{ lineHeight: "38px" }}>
                  Tuesday:
                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="tuestart"
                    type="time"
                    placeholder="Start"
                  />

                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="tuefinish"
                    type="time"
                    placeholder="Finish"
                  />

                </Col>

              </Row>

              <Row id="wednesday">
                <Col md="2" style={{ lineHeight: "38px" }}>
                  Wednesday:
                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="wedstart"
                    type="time"
                    placeholder="Start"
                  />

                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="wedfinish"
                    type="time"
                    placeholder="Finish"
                  />

                </Col>

              </Row>

              <Row id="thursday">
                <Col md="2" style={{ lineHeight: "38px" }}>
                  Thursday:
                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="thustart"
                    type="time"
                    placeholder="Start"
                  />

                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="thufinish"
                    type="time"
                    placeholder="Finish"
                  />

                </Col>

              </Row>

              <Row id="friday">
                <Col md="2" style={{ lineHeight: "38px" }}>
                  Friday:
                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="fristart"
                    type="time"
                    placeholder="Start"
                  />

                </Col>
                <Col className="col">
                  <Input
                    className="mb-2"
                    id="frifinish"
                    type="time"
                    placeholder="Finish"
                  />
                </Col>

              </Row>

            </ModalBody>
            <ModalFooter>
              <Button color="success" disabled={isLoading} onClick={addWorkHours}>
                Add
              </Button>
              <Button color="danger" onClick={Verticalcentermodaltoggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={Verticalcenter2}
            toggle={Verticalcentermodaltoggle2}
            centered
            size="lg"
          >
            <ModalHeader toggle={Verticalcentermodaltoggle2}>
              Add Doctors Arround
            </ModalHeader>
            <ModalBody>
              {
                around.map((item, index) => {
                  return <>
                    {index != "0" ? <hr /> : ""}
                    <Row key={index} id={"doc" + index}>
                      <Col md="2" className="form-label">
                        Doctor Firstname
                      </Col>
                      <Col md="3" className="input--group">
                        <FormGroup>
                          <Input
                            className=" "
                            type="text"
                            placeholder="Doctor Firstname"
                            id={index + "doctor_firstname"}
                          // value={drAround[index].doctor_around_firstname}
                          // onChange={(e) => {
                          //   e.preventDefault()
                          //   setDrAround({
                          //     ...drAround[index],
                          //     doctor_around_firstname: e.target.value,
                          //   });
                          // }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        Doctor Lastname
                      </Col>
                      <Col md="3" className="input--group">
                        <FormGroup>
                          <Input
                            className=""
                            type="text"
                            placeholder="Doctor Lastname"
                            id={index + "doctor_lastname"}
                          // value={drAround[index].doctor_around_lastname}
                          // onChange={(e) => {
                          //   e.preventDefault()
                          //   setDrAround({
                          //     ...drAround[index],
                          //     doctor_around_lastname: e.target.value,
                          //   });
                          // }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <Button
                          onClick={() => {
                            handleRemove("doc" + index)
                          }}
                          className=""
                        >
                          -
                        </Button>
                      </Col>


                      <Col md="2" className="form-label">
                        Doctor Address
                      </Col>
                      <Col md="3" className="">
                        <FormGroup>

                          <Input
                            className="form-control "
                            type="text"
                            placeholder="Doctor Address"
                            id={index + "doctor_address"}
                          // value={drAround[index].doctor_around_address}
                          // onChange={(e) => {
                          //   e.preventDefault()
                          //   setDrAround({
                          //     ...drAround[index],
                          //     doctor_around_address: e.target.value,
                          //   });
                          // }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        Doctor Speciality
                      </Col>
                      <Col md="3" >
                        <FormGroup>
                          <input className="form-control" list="datalistOptions" id={index + "doctor_speciality"}

                            // value={drAround[index].doctor_around_speciality}
                            // onChange={(e) => {
                            //   e.preventDefault()
                            //   setDrAround({
                            //     ...drAround[index],
                            //     doctor_around_speciality: e.target.value,
                            //   });
                            // }}
                            placeholder="Type to search..." />
                          <datalist id="datalistOptions">
                            {
                              special.map((elem) => {
                                return <option value={elem} />
                              })
                            }

                          </datalist>

                        </FormGroup>
                      </Col>
                      {index == (around.length - 1) ? <Col md="2">
                        <Button
                          onClick={(e) => {
                            e.preventDefault()
                            setAround([...around, ''])
                          }}
                          color="success"
                        >
                          +
                        </Button>
                      </Col> : ""}


                    </Row>

                  </>
                })
              }

            </ModalBody>
            <ModalFooter>
              <Button color="success" disabled={isLoading} onClick={addDoctorsArround}>
                Add
              </Button>
              <Button color="danger" onClick={() => {
                setAround([])
                Verticalcentermodaltoggle2()
              }}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
          <Col sm="12" md="10" >
            <Card>
              <CardHeader className='p-4'>
                <h5>Create Contact</h5>
              </CardHeader>
              <CardBody>
                <form action="">
                  <Row>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">
                          Contact last name:{" "}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Last name"
                          value={inputData.contact_lastname}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_lastname: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">
                          Contact first name:{" "}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="First name"
                          value={inputData.contact_firstname}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_firstname: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Sex: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          placeholder="Sex"
                          value={inputData.contact_sexe}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_sexe: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled>
                            Sex
                          </option>
                          <option value="Man">Man</option>
                          <option value="Woman">Woman</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Age: </Label>
                        <Input
                          className="form-control"
                          type="number"
                          placeholder="Age"
                          value={inputData.contact_age}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_age: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Phone: </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Phone"
                          value={inputData.contact_phone}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_phone: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Email: </Label>
                        <Input
                          className="form-control"
                          type="email"
                          placeholder="Email"
                          value={inputData.contact_email}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_email: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Pharmacy name: </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Name of the pharmacy"
                          value={inputData.contact_establishment}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_establishment: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Referral name: </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Referral Name"
                          value={!inputData.referrel_name ? "" : inputData.referrel_name}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              referrel_name: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Region: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          value={inputData.contact_region}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_region: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled>
                            Region
                          </option>
                          <option value="East">East</option>
                          <option value="West">West</option>
                          <option value="Center">Center</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Wilaya: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          value={inputData.contact_wilaya}
                          onChange={handleWilayaChange}
                        >
                          <option value="" disabled>
                            Wilaya
                          </option>
                          {wilayas.map((w) => {
                            return (
                              <option key={w.id} value={w.nom}>
                                {w.nom}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">City: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          value={inputData.contact_city}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_city: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled>
                            City
                          </option>
                          {communes
                            .filter((item) => item.wilaya_id === wilayaID)
                            .map((c) => {
                              return (
                                <option key={c.id} value={c.nom}>
                                  {c.nom}
                                </option>
                              );
                            })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Address: </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Address"
                          value={inputData.contact_address}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_address: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Location lng: </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Location lng"
                          value={inputData.contact_location_lng}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_location_lng: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Location lat: </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Location lat"
                          value={inputData.contact_location_lat}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_location_lat: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">
                          Contract start date:{" "}
                        </Label>
                        <Input
                          className="form-control"
                          type="date"
                          placeholder="Start Date"
                          value={inputData.contract_start_date}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contract_start_date: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">
                          Contract end date:{" "}
                        </Label>
                        <Input
                          className="form-control"
                          type="date"
                          placeholder="End Date"
                          value={inputData.contract_end_date}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contract_end_date: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">
                          Contact has 10sec video:{" "}
                        </Label>
                        <Input
                          className="form-control"
                          type="select"
                          value={inputData.contact_space}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              contact_space: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled>
                            Contact video
                          </option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Complements(%): </Label>
                        <Input
                          className="form-control"
                          type="number"
                          placeholder="Complements"
                          value={inputData.complements}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              complements: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Cosmetics(%): </Label>
                        <Input
                          className="form-control"
                          type="number"
                          placeholder="Cosmetics"
                          value={inputData.cosmetics}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              cosmetics: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Gynecology(%): </Label>
                        <Input
                          className="form-control"
                          type="number"
                          placeholder="Gynecology"
                          value={inputData.gynecology}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              gynecology: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Pain(%): </Label>
                        <Input
                          className="form-control"
                          type="number"
                          placeholder="Pain"
                          value={inputData.pain}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              pain: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Pediatrics(%): </Label>
                        <Input
                          className="form-control"
                          type="number"
                          placeholder="Pediatrics"
                          value={inputData.pediatrics}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              pediatrics: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Business type:  </Label>
                        <Input
                          name="select"
                          type="select"
                          onChange={(e) => { setInputData({ ...inputData, business_type: e.target.value}) }}
                        >
                          <option value={"Pharma"}>
                            Pharma
                          </option>
                          <option value={"Retail"}>
                            Retail
                          </option>
                          <option value={"Exposition"}>
                            Exposition
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                </form>
              </CardBody>
              <CardFooter className='p-4'>
                <Row>
                  <Col md="6">

                    <Button className="btn-block" color="info" disabled={isLoading} onClick={() => {
                      setAround([...around, ''])
                      setDrAround([...drAround, {
                        doctor_around_firstname: "",
                        doctor_around_lastname: "",
                        doctor_around_address: "",
                        doctor_around_speciality: ""
                      }])
                      Verticalcentermodaltoggle2()
                    }}>
                      Add Doctors Arround
                    </Button>
                  </Col>
                  <Col md="6">

                    <Button className="btn-block" color="warning" disabled={isLoading} onClick={Verticalcentermodaltoggle}>
                      Add Work Hours
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
              <CardFooter className='p-4'>
                <Button className="btn-block" color="success" disabled={isLoading} onClick={create}>
                  Add
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
