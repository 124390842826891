import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import StatusPill from "../components/StatusPill";
import DataTable from "react-data-table-component";
let time = new Date();

export default function Space() {
  const history = useHistory();
  const { slug } = useParams();
  const location = useLocation();
  const [tvs, setTvs] = useState([]);
  const data = location.state;
  const {
    space_text_length,
    text_space_time_slots,
    text_space_start_date,
    text_space_end_date,
    text_space_discount,
    client_name,
  } = data || {};
  

  const token = Cookies.get("jwt");

  const GetTvs = async () => {
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/clients/textspace?text_space_id=${slug}`,
        request,
        { mode: "cors" }
      );
      if (response.status === 200) {
        let tvs = await response.json();
        setTvs(tvs);
      } else {
        toast.error("error !");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    GetTvs();
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  const TvColumns = [
    {
      name: "#",
      selector: (row, index) => parseInt(index.toString()) + 1,
      sortable: false,
      center: false,
      width: "50px",
    },
    {
      name: "SN°",
      selector: (row) => row.tv_serial_number,
      sortable: true,
      center: true,
    },
    {
      name: "Contact",
      selector: (row) => row.contact_lastname + " " + row.contact_firstname,
      sortable: true,
      center: true,
    },
    {
      name: "Phone",
      selector: (row) => row.contact_phone,
      sortable: false,
      center: true,
    },
    {
      name: "Wilaya",
      selector: (row) => row.contact_wilaya,
      sortable: true,
      center: true,
    },
    {
      name: "City",
      selector: (row) => row.contact_city,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="Broadcast" title="" hide={true} />
      <Container fluid="true">
        <Row className="justify-content-center">
          <Col sm="12">
            <Card>
              <CardBody className="p-4">
                <div className="d-flex justify-content-between ">
                  <div className="me-auto ">
                    <h5 style={{ color: "darkorange" }}>
                      Text Space of {client_name}
                    </h5>
                  </div>
                  <div className="">
                    <Button
                      className="mr-3 mb-3"
                      onClick={() => {
                        history.push({
                          pathname: `${process.env.PUBLIC_URL}/textspaces/modify:${slug}`,
                          state: data,
                        });
                      }}
                      color="secondary"
                    >
                      Modify
                    </Button>
                  </div>
                </div>

                <hr />

                <p className="mb-2 f-16">
                  <span className="h6">Time Slots: </span>
                  <span className="">
                    {text_space_time_slots?.map((range, index) => {
                      return (
                        <span key={index} className="badge badge-light">
                          {range}
                        </span>
                      );
                    })}
                  </span>
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">Space size: </span>
                  {space_text_length}
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">Space discount: </span>
                  {text_space_discount}%
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">Start date: </span>
                  {text_space_start_date?.slice(0, 10)}
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">End date: </span>
                  {text_space_end_date?.slice(0, 10)}
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">Number of TVs: </span>
                  {tvs?.length}
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    columns={TvColumns}
                    data={tvs}
                    pagination
                    highlightOnHover
                    paginationPerPage={30}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
