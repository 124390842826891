import Cookies from 'js-cookie';
import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Input, Button, FormGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import { useParams, useLocation } from 'react-router-dom';


export default function ModifyContact() {
    const { slug } = useParams();
    const location = useLocation();
    const data = location.state?.data || {}
    const [isLoading, setIsLoading] = useState(false);
    const token = Cookies.get("jwt");
    const [inputData, setInputData] = useState({
        contract_start_date: data?.contract_start_date.split(" ",1) || "",
        contract_end_date: data?.contract_end_date.split(" ",1) || "",
        contract_video_length: data?.video_length || "",
        contract_discount: data?.contract_discount || "",
        contract_max_budjet_ttc: data?.max_budjet_ttc || "",
        contract_total_ttc: data?.total_ttc || "",
        contract_name: data?.contract_name || "",
    });

    const update = async () => {
        for (const [key, value] of Object.entries(inputData)) {
            if (inputData.key === "") {
                toast.error(key + "is missing !")
                return
            }
        }
        const request = {
            method: "PUT",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers":
                    "Origin, X-requested-With, Content-Type, Accept",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                ...inputData,
            })
        }
        try {
            var response = await fetch("https://admins.adspot.pub/api/admins/client/contract/new&contract_id="+slug, request, { mode: 'cors' });
            if (response.status === 200) {
                toast.success("Contract updated successfully!")
                window.location.href = `${process.env.PUBLIC_URL}/contracts/contract:${slug}`
            } else {
                toast.error("error!");
                setIsLoading(false);
            }
        } catch (err) {
            toast.error(err.message);
            setIsLoading(false);
        }

    }
    return (
        <Fragment>
            <Loader isLoading={isLoading} />
            <Breadcrumb parent="Contacts" title="" hide={false} />
            <Container fluid="true">
                <Row className='justify-content-center'>

                    <Col sm="12" md="10" xl="8">
                        <Card>
                            <CardHeader className='p-4'>
                                <h5>Modify Contract</h5>
                            </CardHeader>
                            <CardBody>
                                        <Col col="12">
                                            <FormGroup>
                                                <Label className="form-label">Contract Name: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Name"
                                                    value={inputData.contract_name || ""}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contract_name: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col col="12">
                                            <FormGroup>
                                                <Label className="form-label">Video length: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="In seconds"
                                                    value={inputData.contract_video_length || ""}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contract_video_length: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col col="12">
                                            <FormGroup>
                                                <Label className="form-label">Start Date: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="date"
                                                    required
                                                    placeholder="Start Date"
                                                    value={inputData.contract_start_date || ""}
                                                    onChange={(e) => {
                                                        console.log(e.target.value);
                                                        setInputData({
                                                            ...inputData,
                                                            contract_start_date: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col col="12">
                                            <FormGroup>
                                                <Label className="form-label">End Date: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="date"
                                                    required
                                                    placeholder="End Date"
                                                    value={inputData.contract_end_date || ""}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contract_end_date: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col col="12">
                                            <FormGroup>
                                                <Label className="form-label">Max Budjet TTC: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Max budjet TTC"
                                                    value={inputData.contract_max_budjet_ttc || ""}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contract_max_budjet_ttc: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>{" "}
                                        <Col col="12">
                                            <FormGroup>
                                                <Label className="form-label">Total TTC: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Total TTC"
                                                    value={inputData.contract_total_ttc || ""}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contract_total_ttc: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col col="12">
                                            <FormGroup>
                                                <Label className="form-label">Discount: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Discount"
                                                    value={inputData.contract_discount || ""}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contract_discount: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                            </CardBody>

                            <CardFooter className='p-4'>
                                <Button className="btn-block" color="success" disabled={isLoading} onClick={update}>
                                    Save
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
