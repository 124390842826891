import React, { Fragment, useEffect, useState } from 'react'
import Loader from '../../layout/loader';
import { Container, Row, Col, Card, CardBody, Label, Input, FormGroup, } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../layout/breadcrumb';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import StatusPill from '../components/StatusPill';
import DataTable from 'react-data-table-component';


export default function BroadcastingMain() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  //fetch data on first render
  const [data, setData] = useState([]);
  const token = Cookies.get("jwt");
  const [keyword, setkeyword] = useState("");
  const [filterByBusiness, setFilterByBusiness] = useState("all")
  const handleRowClicked = (row) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/broadcasting/broadcast:${row.diffusion_id}`,
      state: row
    });
  };

  const diffusionColumns = [

    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      center: true,
      width: "50px",
      style: { margin: "0px", padding: "0px" }
    }, {
      name: "Client",
      selector: (row) => <Link to={`${process.env.PUBLIC_URL}/clients/client:${row.client_id}`}>{row.client_name}</Link>,
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    }, {
      name: "Product",
      selector: (row) => row.video_product_name,
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Creation Date",
      selector: (row) => { return moment(row.created_at).format("DD/MM/YYYY HH:mm") },
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Start Date",
      selector: (row) => { return moment(row.diffusion_start_date).format("DD/MM/YYYY") },
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "End Date",
      selector: (row) => { return moment(row.diffusion_end_date).isBefore(new Date()) ? <span className="badge badge-danger badge-pill"> {moment(row.diffusion_end_date).format("DD/MM/YYYY")}</span> : <span className="badge badge-success badge-pill"> {moment(row.diffusion_end_date).format("DD/MM/YYYY")}</span> },
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Time Slot",
      selector: (row) => {
        return row.space_time_slots.map((range) => {
          return (
            <>
              <span key={row.space_time_slots.indexOf(range)} className="badge badge-light badge-pill my-1">{range}</span>
            </>
          )
        })
      },
      sortable: false,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Screens Number",
      selector: (row) => row.tvs_count,
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Status",
      selector: (row) => <StatusPill status={row.diffusion_status} />,
      sortable: true,
      center: true,

      style: { margin: "0px", padding: "0px" }
    }

  ];
  const checkFilter = (item) => {
    let business = []

    switch (filterByBusiness) {
      case "Retail":
        business = item.business_type === "Retail"
        break;
      case "Pharma":
        business = item.business_type === "Pharma"
        break;
      case "Exposition":
        business = item.business_type === "Exposition"
        break;
      default:
        business = data;
        break;
    }
    return business
  }
  let filteredArray = data.filter(checkFilter);
  let pattern = new RegExp(keyword, "i");
  let searchedArray = filteredArray.filter(function (diff) {

    if (keyword == "expired") {
      return moment(diff.diffusion_end_date).isBefore(new Date())
    } else if (keyword == "not expired") {
      return moment(diff.diffusion_end_date).isAfter(new Date())
    } else {
      return (
        pattern.test(diff.client_name) ||
        pattern.test(diff.diffusion_status)
      )
    }
  });

  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/diffusions/all`,
        request,
        { mode: "cors" });
      let data = await response.json();

      if (response.status === 200) {
        setData(data);
        setIsLoading(false);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  }
  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    fetchData();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps



  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Broadcast" title="" hide={true} />
      <Container fluid="true">
        <Row>
          <Col sm="6" lg="3" className="input--group">
            
          </Col>
          <Col md="12">
            <Card>

              <CardBody className='p-4 pt-0'>
                <div className="table-responsive product-table">
                  <DataTable title="Broadcasting" className='table-boredered' onRowClicked={handleRowClicked} columns={diffusionColumns} data={searchedArray} pagination highlightOnHover pointerOnHover
                    paginationPerPage={30}
                    subHeader
                    subHeaderComponent={
                      <>
                      <FormGroup className='d-flex align-items-end mb-0'>
              <Label className="form-label mb-0">Business Type: </Label>
              <Input
                className="form-control"
                type="select"
                value={filterByBusiness}
                onChange={(e) => setFilterByBusiness(e.target.value)}
              >
                <option value="all">All</option>
                <option value="Pharma">Pharma</option>
                <option value="Retail">Retail</option>
                <option value="Exposition">Exposition</option>
              </Input>
            </FormGroup>
                      <input type="text" className='form-control w-25 ml-2' title="Search by client or status" placeholder='Search by client or status' value={keyword} onChange={(e) => { setkeyword(e.target.value); }} />
                      </>
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

        </Row>

      </Container>
    </Fragment>
  )
}
