import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col,Card, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardBody, } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';


export default function Admins() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({success:false,users:[]});
  const token = Cookies.get("jwt");

  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);

  const [inputData, setInputData] = useState({});

  const adminColumns = [
    {
      name: "Last Name",
      selector: (row) => row.admin_last_name,
      sortable: true,
      center: true,
    },
    {
      name: "First Name",
      selector: (row) =>row.admin_first_name,
      sortable: true,
      center: true,
    },
    {
      name: "Email",
      selector: (row) =>row.admin_email,
      sortable: true,
      center: true,
    },
    {
      name: "Phone",
      selector: (row) => row.admin_phone,
      sortable: true,
      center: true,
    },
    {
      name: "Type",
      selector: (row) => row.admin_type,
      sortable: true,
      center: true,
    }
  ];

  const addAdmin = async () => {
    setIsLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({ ...inputData })
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/new`, request, { mode: "cors" });
      console.log(request.body);
      // return
      if (response.status === 200) {
        toast.success("User Added Successfully");
        setIsLoading(false);
        fetchData();
      } else {
        toast.error("Something went wrong !");
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    Verticalcentermodaltoggle();
  }


  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    fetchData();

    return () => {
      unmount = true;
      window.scrollTo(0, 0);
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  const fetchData = async function () {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/all`, request, { mode: "cors" });
      if (response.status === 200) {
        let data = await response.json();
        
        setData(data.users);
      } else {
        toast.error("Something went wrong !");
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  }
  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Dashboard" title="Users" hide={false} />
      <Container fluid="true">
        <Row>
          <Col className='mb-4'>
            
            <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered>
              <ModalHeader toggle={Verticalcentermodaltoggle}>
                Add User
              </ModalHeader>
              <ModalBody>
                <form action="">
                  <Row>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Last Name: </Label>
                        <Input className="form-control" required type="text" placeholder="Last Name" value={inputData.admin_last_name} onChange={(e) => { setInputData({ ...inputData, admin_last_name: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">First Name: </Label>
                        <Input className="form-control" required type="text" placeholder="First Name" value={inputData.admin_first_name} onChange={(e) => { setInputData({ ...inputData, admin_first_name: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Email: </Label>
                        <Input className="form-control" required type="email" placeholder="Email" value={inputData.admin_email} onChange={(e) => { setInputData({ ...inputData, admin_email: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Phone: </Label>
                        <Input className="form-control" required type="text" placeholder="Phone" value={inputData.admin_phone} onChange={(e) => { setInputData({ ...inputData, admin_phone: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label" >Type: </Label>
                        <Input type="select" className="form-control" required  placeholder="Type" value={inputData.admin_type} onChange={(e) => { setInputData({ ...inputData, admin_type: e.target.value }) }}>
                          <option value={""}>{"Select type"}</option>
                          <option value={"super_admin"}>{"Super Admin"}</option>
                          <option value={"admin"}>{"Admin"}</option>
                          <option value={"fitter"}>{"Fitter"}</option>
                          <option value={"operator"}>{"Operator"}</option>
                        </Input>
                        {/* <Input className="form-control" required type="text" placeholder="Type" value={inputData.admin_type} onChange={(e) => { setInputData({ ...inputData, admin_type: e.target.value }) }} /> */}
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Password: </Label>
                        <Input className="form-control" required type="password" placeholder="Password" value={inputData.admin_password} onChange={(e) => { setInputData({ ...inputData, admin_password: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="success" disabled={isLoading} onClick={addAdmin}>Add</Button>
                <Button color="danger" onClick={Verticalcentermodaltoggle}>Close</Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col md="12">
           
            <Card>
              <CardBody>

              
            <DataTable columns={adminColumns} data={data} pagination highlightOnHover
                                    actions={<Button color='success' onClick={Verticalcentermodaltoggle}>Add User</Button>} />
          </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
