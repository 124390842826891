import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Card, CardBody,Table,CardHeader,FormGroup,Label,Input,Modal,ModalHeader,ModalBody,ModalFooter,Button } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import moment from 'moment';


export default function Broadcast() {
 
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ space_time_slots: [], diffusion_regions: [], passage: [] });
  const [videoLink, setVideoLink] = useState("");
  const token = Cookies.get("jwt");
  const [tvs, setTvs] = useState([]);
  const [selectedtvs, setselectedtvs] = useState([]);
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);
  const [keyword, setkeyword] = useState("");
  const [filterByBusinessModal, setFilterByBusinessModal] = useState("all")
  const selectedTvsIds = selectedtvs.map((tv) => tv.tv_id);

  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/diffusion/default?default_diffusion_id=${slug}`, request, { mode: "cors" });
      if (response.status === 200) {
        let data = await response.json();
        setData(data);
        let prev_ids=[]
        for (let index = 0; index < data.tv_ids.length; index++) {
          const element = data.tv_ids[index];
          prev_ids.push({
            tv_id:element
          })
        }
        setselectedtvs(prev_ids)
        if (data.landscape_video_link!=="") {
          
          setVideoLink(data.landscape_video_link);
        } else {
          setVideoLink(data.portrait_video_link);
          
        }
        setIsLoading(false);
      } else {
        toast.error(await response.json());
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  }
  const fetchScreens = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response2 = await fetch(`https://admins.adspot.pub/api/admins/tvs/status`, request, { mode: "cors" });
      if (response2.status === 200) {
        let tvs = await response2.json();
        tvs = tvs.filter((x) => {
          return x.install_status == "installed"
        })
        for (let tv in tvs) {
          tvs[tv].selected = false;
        }
        setTvs(tvs);
      } else {
        toast.error(await response2.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }
  const radioStyle = {
    display: "inline-block",
    position: "static",
    margin: "0 5px 0 0",
    opacity: "initial"
  };
  const checkfunc = (tv) => {
    let array = [...selectedtvs];
    return (array.filter(obj => obj.tv_id === tv.tv_id)[0] ? true : false);
  }
  
  
  const selectAllfunc = (checked) => {
    let array = [...selectedtvs];

    if (!checked) {
      let checkInput=document.getElementById('select');
      checkInput.checked=false
      for (let i = 0; i < searchedArray.length; i++) {
        let tv = searchedArray[i];
        let index = array.indexOf(array.filter(obj => obj.tv_id === tv.tv_id)[0]);
        array.splice(index, 1);
      }
      return setselectedtvs(array);
    }

    for (let i = 0; i < searchedArray.length; i++) {
      let tv = searchedArray[i];
      let tvExists = array.filter(obj => obj.tv_id === tv.tv_id)[0] ? true : false;
      if (!tvExists) {
        array.push(tv);
      }
    }
    setselectedtvs(array);
  }
  const tvSelector = (tv) => {
    let array = [...selectedtvs];
    let tvexists = array.filter(obj => obj.tv_id === tv.tv_id)[0] ? true : false;
    if (tvexists) {
      let index = array.indexOf(array.filter(obj => obj.tv_id === tv.tv_id)[0]);
      array.splice(index, 1);
      setselectedtvs(array);
    } else {
      array.push(tv);
      setselectedtvs(array);
    }
  }
  const updateScreensList = async () => {
    setIsLoading(true);
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      },
       body: JSON.stringify({
        default_diffusion_id: slug,
        tv_ids:selectedTvsIds
      }),
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/diffusion/default/update`, request, { mode: "cors" });
      if (response.status === 200) {
        toast.success("Screens List updated Successfully!")
       
      } else {
        toast.error(await response.json());
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    Verticalcentermodaltoggle()
  }
  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    
    fetchData();
    fetchScreens();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const checkFilterModal = (item) => {
    let business = []

    switch (filterByBusinessModal) {
      case "Retail":
        business = item.business_type === "Retail"
        break;
      case "Pharma":
        business = item.business_type === "Pharma"
        break;
      case "Exposition":
        business = item.business_type === "Exposition"
        break;
      default:
        business = tvs;
        break;
    }
    return business
  }
  let filteredArray = tvs.filter(checkFilterModal);
  let pattern = new RegExp(keyword, "i");
  let searchedArray = filteredArray.filter(function (diff) {
    return (
      pattern.test(diff.contact_lastname) ||
      pattern.test(diff.contact_firstname)||
      pattern.test(diff.contact_region)||
      pattern.test(diff.contact_wilaya)
    )
  });
  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Broadcast" title="Broadcast" hide={true} />
      <Container fluid="true">
        <Row>
          <Col md={12}>
          <Modal modalClassName='modal modal-dialog-scrollable d-flex' isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered size='lg'>
          <ModalHeader toggle={Verticalcentermodaltoggle}>
            Screens List
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Input type="text" placeholder='Search for Screens by Name, Region or Wilaya' value={keyword} onChange={(e) => { setkeyword(e.target.value) }} />
                  <Label className="form-label">Selected Screens: {selectedtvs.length}</Label>
                </FormGroup>
              </Col>
              <Col sm="6" lg="3" className="input--group">
                <FormGroup>
                  <Input
                    className="form-control"
                    type="select"
                    value={filterByBusinessModal}
                    onChange={(e) => {setFilterByBusinessModal(e.target.value)}}
                  >
                    <option value="all">All</option>
                    <option value="Pharma">Pharma</option>
                    <option value="Retail">Retail</option>
                    <option value="Exposition">Exposition</option>
                  </Input>
                  <Label className="form-label">Business Type </Label>
                </FormGroup>
              </Col>
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <input
                        id='select'
                          style={radioStyle}
                          type="checkbox"
                          onChange={(e) => {
                            selectAllfunc(e.target.checked);
                          }}
                        />
                        select all
                      </th>
                      <th>Contact</th>
                      <th>Region</th>
                      <th>Wilaya</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchedArray.map((tv) => {
                      let shouldRender = pattern.test(tv.contact_region) || pattern.test(tv.contact_lastname) || pattern.test(tv.contact_firstname) || pattern.test(tv.contact_wilaya);
                    
                      return (shouldRender) && (
                        <tr key={tvs.indexOf(tv)} >
                          <td>
                            <input type="checkbox" checked={checkfunc(tv)} onChange={
                              (e) => {
                                tvSelector(tv)
                              }
                            } />
                          </td>

                          <td>{tv.contact_lastname} {tv.contact_firstname}</td>
                          <td>{tv.contact_region}</td>
                          <td>{tv.contact_wilaya}</td>
                        </tr>
                      ) 
                      
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter >
            <Button color="success" onClick={updateScreensList}>Save</Button>
            <Button color="danger" onClick={Verticalcentermodaltoggle}>Close</Button>
          </ModalFooter>
        </Modal>
            <Card>
              <CardHeader className='p-4'>
                <h4 className=''><span className='client--name__header'>{data.client_name}</span> 
                  
                    <span className="badge badge-success badge-pill f-right">Default</span>
                     <span className="badge badge-warning badge-pill f-right mr-2" role='button' onClick={Verticalcentermodaltoggle}>Screens List</span>
                   </h4>
              </CardHeader>
              <CardBody>
                <Row className='px-0'>

                  <Col sm="6"  className='text-left px-0'>
                    <p><span className=' h6 f-16 '>Phone: </span><span className=''>{data.client_phone}</span></p>
                  </Col>
                  <Col sm="6"  className='text-left  px-0'>
                    <p><span className=' h6 f-16 '>Email: </span><span className='f-12'>{data.client_email}</span></p>
                  </Col>
                  <Col sm="6"  className='text-left  px-0'>
                    <p><span className=' h6 f-16 '>Created at: </span><span className=''>{moment(data.created_at).format("DD/MM/YYYY HH:mm")}</span></p>
                  </Col>
                  <Col sm="6"  className='text-left px-0'>
                    <p><span className=' h6 f-16 '>Total Broadcasts: </span><span className="">{data.passage.reduce((prev, obj) => prev + (+obj.total), 0)}</span></p>
                  </Col>
                  <Col sm="6"  className='text-left px-0'>
                    <p><span className=' h6 f-16 '>Start Date: </span><span className=''>{moment(data.diffusion_start_date).format("DD/MM/YYYY HH:mm")}</span></p>
                  </Col>
                  <Col sm="6"  className='text-left  px-0'>
                    <p><span className=' h6 f-16 '>End Date: </span><span className=''>{moment(data.diffusion_end_date).format("DD/MM/YYYY HH:mm")}</span></p>
                  </Col>
                  <Col sm="6"  className='text-left  px-0'>
                    <p><span className=' h6 f-16 '>Broadcast Duration: </span><span className=''>{moment(data.diffusion_end_date).diff(data.diffusion_start_date, "days")} days</span></p>
                  </Col>
                  <Col sm="6"  className='text-left  px-0'>
                    <p><span className=' h6 f-16 '>Number of Screens: </span><span className=''>{data.tvs_count}</span></p>
                  </Col>

                </Row>

                <hr />
                <Row>
                  <Col md="12" className='text-center'>
                  <span className="f-16 h6 ">Diffusion ranges:</span>
                  </Col>
                    {data.passage.map((p) => {
                      return (
                        <Col md="4" className='px-0'key={data.passage.indexOf(p)}>
                        <p > 
                           <span className="badge badge-primary">{p.diffusion_range}</span> : {p.total}
                        </p>
                  </Col>
                      )
                    })}
                </Row>
               

              </CardBody>
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              <CardHeader className='p-4 text-center client--name__header'>
                <h4>{data.video_product_name}</h4>
              </CardHeader>
              <CardBody>
                <Row className='mb-2'>
                  <Col md="4">
                <p><span className="h4 f-16"> Product name: </span><span >{data.video_product_name}</span></p>
                  </Col>
                  <Col md="4">
                <p><span className="h4 f-16"> Product description: </span><span ><i>{data.video_product_description}</i></span></p>
                  </Col>
                  <Col md="4">
              <p className='mt-3'><span className="h4 f-16"> Business Type: </span><span ><i>{data.business_type}</i></span></p>
                  </Col>
                </Row>
                {videoLink && <video id={data.landscape_video_link} controls width="100%">
                  <source src={videoLink} type="video/mp4" />
                  Your browser does not support the video tag. I suggest you upgrade your browser.
                </video>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

