import Cookies from 'js-cookie';
import React, { Fragment, useEffect, useState } from 'react';
import { File} from 'react-feather'
import { Container, Row, Col, Button, Card, CardHeader, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import DataTable from 'react-data-table-component';
import Chart from 'react-apexcharts';
import moment from "moment";
export default function Statistics() {
    const [isLoading, setIsLoading] = useState(false);
    const maxDate = moment().format("YYYY-MM-DD")
    const [data, setData] = useState({});

    const [dateFilter, setDateFilter] = useState("");
    // On
    const [chart1Series1, setChart1Series1] = useState([]);
    // off
    const [chart1Series2, setChart1Series2] = useState([]);
    const [chart1Xaxis, setChart1Xaxis] = useState([]);

    const [chart2Series, setChart2Series] = useState([]);
    const [chart2Xaxis, setChart2Xaxis] = useState([]);

    const [chart3Series, setChart3Series] = useState([]);
    const [chart3Labels, setChart3Labels] = useState([]);

    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [filterByBusiness, setFilterByBusiness] = useState("all")

    const [dataPerScreen, setDataPerScreen] = useState([]);
    const filteredData = dataPerScreen.filter((elem) => {
        if (dateFilter != "" && elem.last_connection) {
            const date = elem.last_connection.split(' ')
            return date[0] >= dateFilter && elem.last_connection
        }
        return elem
    });
    const token = Cookies.get("jwt");
    const statusPill = (status) => {
        switch (status) {
            case "added":
                return <span className="badge badge-dark badge-pill">
                    {status}</span>
            case "to prepare":
                return <span className="badge badge-info badge-pill">
                    {status}</span>
            case "ready to install":
                return <span className="badge badge-primary badge-pill">
                    {status}</span>
            case "to install":
                return <span className="badge badge-warning badge-pill">
                    {status}</span>
            case "installed":
                return <span className="badge badge-success badge-pill">
                    {status}</span>
            default:

                return <span className="badge badge-danger badge-pill">
                    {status}</span>
        }
    }

    const contactColumns = [
        {
            name: "#",
            selector: (row, index) => parseInt(index.toString()) + 1,
            sortable: false,
            center: false,
            width: "50px"
        },
        {
            name: "Contact FullName ",
            selector: (row) => row.contact_lastname + " " + row.contact_firstname,
            sortable: true,
            center: true,
        },
        {
            name: "Phone",
            selector: (row) => row.contact_phone,
            sortable: false,
            center: true,
        },
        {
            name: "Address",
            selector: (row) => row.contact_wilaya + ", " + row.contact_city,
            sortable: true,
            center: true,
        },
        {
            name: "SN°",
            selector: (row) => row.tv_serial_number,
            sortable: false,
            center: true,
        },
        {
            name: "Status",
            selector: (row) => statusPill(row.install_status),
            sortable: false,
            center: true,
        }, {
            name: "Last Connection",
            selector: (row) => moment(row.last_connection).format('DD/MM/YYYY HH:mm'),
            sortable: true,
            center: true,
        },
        {
            name: "On Days",
            selector: (row) => row.screen_on,
            sortable: true,
            center: true,
        },
        {
            name: "Off Days",
            selector: (row) => row.days_number - row.screen_on,
            sortable: true,
            center: true,
        },
        {
            name: "Diffusions Number",
            selector: (row) => row.diffusion_number,
            sortable: true,
            center: true,
        },

    ];

    const chart1 = {
        options: {

            legend: {
                show: false
            },
            chart: {
                type: 'bar',
                height: 380
            },
            plotOptions: {
                bar: {
                    radius: 10,
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                colors: ['transparent'],
                curve: 'smooth',
                lineCap: 'butt'
            },
            grid: {
                show: false,
                padding: {
                    left: 0,
                    right: 0
                }
            },
            xaxis: {
                categories: chart1Xaxis,
            },
            yaxis: {
                labels: {
                    show: false,
                },
            },
            fill: {
                colors: ['#28a745', '#dc3545'],
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.1,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },

            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " Screens"
                    }
                }
            }
        },
        series: [{
            name: 'Screens On',
            data: chart1Series1
        }, {
            name: 'Screens Off',
            data: chart1Series2
        }],
    }

    const chart2 = {
        options: {

            legend: {
                show: false
            },
            chart: {
                type: 'bar',
                height: 380
            },
            plotOptions: {
                bar: {
                    radius: 10,
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                colors: ['transparent'],
                curve: 'smooth',
                lineCap: 'butt'
            },
            grid: {
                show: false,
                padding: {
                    left: 0,
                    right: 0
                }
            },
            xaxis: {
                categories: chart2Xaxis,
            },
            yaxis: {
                labels: {
                    show: false,
                },
            },
            fill: {
                colors: ['#28a745', '#dc3545'],
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.1,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 0.9,
                    stops: [0, 100]
                }
            },

            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " Diffusions"
                    }
                }
            }
        },
        series: [{
            name: 'Diffusions',
            data: chart2Series
        }],
    }

    const apexPieChart = {

        series: chart3Series,
        options: {
            chart: {
                width: 450,
                type: 'pie',
            },
            labels: chart3Labels,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    }

  
    const getStat = async () => {
        setIsLoading(true)
        try {
            const request = {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            const response = await fetch(
                `https://admins.adspot.pub/api/admins/tvs/statistics?start_date=${start_date}&end_date=${end_date}&business_type=${filterByBusiness}`,
                request,
                { mode: "cors" }
            );

            if (response.status === 200) {
                const data = await response.json();
                setData(data)
                const diffusionsPerVideo = data.numberPerVideo.filter((elem) => {
                    return elem.video_product_name != null
                })
                setDataPerScreen(data.perScreen)
                setChart1Series1(data.ScreenPerDate.map((elem) => {
                    return elem.number_screens_on
                }));
                setChart1Series2(data.ScreenPerDate.map((elem) => {
                    return elem.total_screens - elem.number_screens_on
                }));
                setChart1Xaxis(data.ScreenPerDate.map((elem) => {
                    const date = elem.date.split(' ')
                    return date[0]
                }));

                setChart2Series(data.diffusionNumberPerDate.map((elem) => {
                    return elem.total
                }));
                setChart2Xaxis(data.diffusionNumberPerDate.map((elem) => {
                    const date = elem.diffusion_number_date.split(' ')
                    return date[0]
                }));

                let series = [], labels = []
                series = diffusionsPerVideo.map((elem) => {
                    return parseInt(elem.total)
                })
                setChart3Series(series);
                labels = diffusionsPerVideo.map((elem) => {
                    return elem.video_product_name
                })
                setChart3Labels(labels);

            }
        } catch (error) {
            toast.error(error.message)

        } finally {
            setIsLoading(false)
        }
    };
    const convertArrayOfObjectsToCSV = (array) => {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(filteredData[0]);
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        array.forEach(item => {
          let ctr = 0;
          keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
            result += item[key];
            ctr++;
          });
          result += lineDelimiter;
        });
    
        return result;
      }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
    
        const filename = 'export.csv';
    
        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
      }
    const Export = ({ onExport }) => <Button className="px-2 py-auto" onClick={e => onExport(e.target.value)}><File size="15px" /> Export</Button>;
  
    return (
        <Fragment>
            <Loader isLoading={isLoading} />
            <Breadcrumb parent="Dashboard" title="Statistics" hide={true} />
            <Container fluid="true ">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col col="4" className="input--group">
                                        <FormGroup>
                                            <Label className="form-label">Start Date: </Label>
                                            <Input
                                                className="form-control"
                                                type="date"
                                                value={start_date}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col col="4" className="input--group">
                                        <FormGroup>
                                            <Label className="form-label">End Date: </Label>
                                            <Input
                                                className="form-control"
                                                type="date"
                                                max={maxDate}
                                                value={end_date}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col col="4"  className="input--group">
                    <FormGroup>
                      <Label className="form-label">Business Type: </Label>
                      <Input
                        className="form-control"
                        type="select"
                        value={filterByBusiness}
                        onChange={(e) => setFilterByBusiness(e.target.value)}
                      >
                        <option value="all">All</option>
                        <option value="Pharma">Pharma</option>
                        <option value="Retail">Retail</option>
                        <option value="Exposition">Exposition</option>
                      </Input>
                    </FormGroup>
                  </Col>
                                </Row>
                                <Row>

                                    <Col col="12" className="input--group">
                                        <FormGroup>
                                            <Label className="form-label"></Label>
                                            <Input
                                                className="btn btn-outline-success"
                                                type="button"
                                                value="Send"
                                                onClick={getStat}
                                            >
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                {Object.keys(data).length != 0 ?
                    <>
                        <Row>
                            <Col sm="12" xl="6">
                                <Card className="o-hidden">
                                    <CardHeader>
                                        <h5>On/Off Screens Per Date</h5>
                                    </CardHeader>
                                    <div className="bar-chart-widget">
                                        <CardBody className="bottom-content">
                                            <Row className="row">
                                                <Col xs="12">
                                                    <div id="chart-widget4">
                                                        <Chart series={chart1.series} options={chart1.options} height="350" type="bar" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm="12" xl="6">
                                <Card className="o-hidden">
                                    <CardHeader>
                                        <h5>Diffusions Number Per Video</h5>
                                    </CardHeader>
                                    <CardBody className="apex-chart" >
                                        <div id="piechart">
                                            <Chart options={apexPieChart.options} series={apexPieChart.series} type="pie" height={350} width={450} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm="12" >
                                <Card className="o-hidden">
                                    <CardHeader>
                                        <h5>Global Diffusions Number / Date</h5>
                                    </CardHeader>
                                    <div className="bar-chart-widget">
                                        <CardBody className="bottom-content">
                                            <Row className="row">
                                                <Col xs="12">
                                                    <div id="chart-widget4">
                                                <Chart series={chart2.series} options={chart2.options} height="350" type="bar" />
                                                        
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </div>
                                </Card>
                            </Col>


                        </Row>
                        <Row>
                            <Col sm="12">
                                <Card>

                                    <CardBody>
                                        <div className="table-responsive product-table">
                                            <DataTable title="Statistics Per Screen" columns={contactColumns} data={filteredData} pagination paginationPerPage={30} highlightOnHover
                                                subHeader
                                                actions={<Export onExport={() => downloadCSV(filteredData)} />}
                                                subHeaderComponent={
                                                    <Row>
                                                        <Col col="8" className="input--group">
                                                            <Label>
                                                            Filter By Last connection:
                                                            </Label>
                                                        </Col>
                                                       
                                                            
                                                    <Col col="4" className="input--group">
                                                        
                                                            <Input
                                                                className="form-control"
                                                                type="date"
                                                                max={maxDate}
                                                                placeholder='Filter By Last connection:'
                                                                value={dateFilter}
                                                                onChange={(e) => {
                                                                    setDateFilter(e.target.value);
                                                                }}
                                                                />
                                                     
                                                    </Col>
                                                                </Row>
                                                } />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row></> : ""}


            </Container>
        </Fragment>
    )
}
