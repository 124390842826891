import React, { Fragment, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Input, FormGroup, Label, Button, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import PDFMerger from 'pdf-merger-js';


export default function AddSpace() {
  const location = useLocation();
  const { client_id, client_name } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("jwt");

  const [invoiceInputData, setInvoiceInputData] = useState({
    invoice_number: "",
    invoice_date: "",
    invoice_dead_line: "",
    invoice_total_ht: "",
    invoice_total_ttc: "",
    invoice_paid: "",
  });

  const [invoiceFile, setInvoiceFile] = useState("");
  const [detailsFile, setDetailsFile] = useState("");
  const [mergedFile, setMergedFile] = useState("");

  let merger = new PDFMerger();

  const formData = new FormData();

  const pdfmerge = async () => {
    await merger.add(invoiceFile);
    await merger.add(detailsFile);

    merger.save("MergedFile");
  };

  const addNewInvoice = async () => {
    setIsLoading(true);
    formData.append('invoice_pdf', mergedFile);
    formData.append('invoice_number', invoiceInputData.invoice_number);
    formData.append('invoice_date', invoiceInputData.invoice_date);
    formData.append('total_ht', invoiceInputData.invoice_total_ht);
    formData.append('total_ttc', invoiceInputData.invoice_total_ttc);
    const request = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: formData
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/invoice/new?client_id=${client_id}`, request, { mode: "cors" });
      if (response.status === 200) {
        toast.success("Space added successfully");
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb hide="true" title="" />
      <Container fluid={true}>
        <Row className='justify-content-center'>
          <Col sm="12" md="10" lg="8">
            <Card>
              <CardHeader className='text-center'>
                <h5 >Add Invoice For {client_name}</h5>
              </CardHeader>
              <CardBody>
                <Col md="12" className='input--group'>
                  <FormGroup>
                    <Label className="form-label">Invoice Number</Label>
                    <Input className="form-control" type="text" placeholder="Invoice Number" value={invoiceInputData.invoice_number} onChange={(e) => { setInvoiceInputData({ ...invoiceInputData, invoice_number: e.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col md="12" className='input--group'>
                  <FormGroup>
                    <Label className="form-label">Invoice Date</Label>
                    <Input className="form-control" type="date" placeholder="Invoice Date" value={invoiceInputData.invoice_date} onChange={(e) => { setInvoiceInputData({ ...invoiceInputData, invoice_date: e.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col md="12" className='input--group'>
                  <FormGroup>
                    <Label className="form-label">Invoice Deadline</Label>
                    <Input className="form-control" type="date" placeholder="Invoice Deadline" value={invoiceInputData.invoice_dead_line} onChange={(e) => { setInvoiceInputData({ ...invoiceInputData, invoice_dead_line: e.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col md="12" className='input--group'>
                  <FormGroup>
                    <Label className="form-label">Total HT</Label>
                    <Input className="form-control" type="number" placeholder="Total HT" value={invoiceInputData.invoice_total_ht} onChange={(e) => { setInvoiceInputData({ ...invoiceInputData, invoice_total_ht: e.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col md="12" className='input--group'>
                  <FormGroup>
                    <Label className="form-label">Total TTC</Label>
                    <Input className="form-control" type="number" placeholder="Total TTC" value={invoiceInputData.invoice_total_ttc} onChange={(e) => { setInvoiceInputData({ ...invoiceInputData, invoice_total_ttc: e.target.value }) }} />
                  </FormGroup>
                </Col>

                <Row>

                  <Col sm="6" md="3" className='input--group'>
                    <FormGroup>
                      <Label className="form-label">Invoice file</Label>
                      <Input type="file" accept='.pdf' onChange={(e) => { setInvoiceFile(e.target.files[0]) }} />
                    </FormGroup>
                  </Col>
                  <Col sm="6"  md="3" className='input--group'>
                    <FormGroup>
                      <Label className="form-label">Details file</Label>
                      <Input type="file" accept='.pdf' onChange={(e) => { setDetailsFile(e.target.files[0]) }} />
                    </FormGroup>
                  </Col>
                  <Col sm="6"  md="3">
                    <Button onClick={pdfmerge} color='secondary'>Merge Files</Button>
                  </Col>
                  <Col sm="6"  md="3" className='input--group'>
                    <FormGroup>
                      <Label className="form-label">Merged file</Label>
                      <Input type="file" accept='.pdf' onChange={(e) => { setMergedFile(e.target.files[0]) }} />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                  <Button size="block" color='success' form='addContactForm' onClick={addNewInvoice} >Add Invoice</Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>

      </Container>
    </Fragment>
  )
}


