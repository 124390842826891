import React, { Fragment, useEffect, useState } from 'react'
import Loader from '../../layout/loader';
import { Container, Row, Col, Card, CardBody, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../layout/breadcrumb';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'


export default function BroadcastingMain() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [landscapeVideoFile, setLandscapeVideoFile] = useState("");
  const [portraitVideoFile, setPortraitVideoFile] = useState("");
  const [filterByBusiness, setFilterByBusiness] = useState("all")
  const [filterByBusinessModal, setFilterByBusinessModal] = useState("all")
  const [input, setInput] = useState({
    product_name: "",
    product_description: "",
    start_date: "",
    end_date: "",
    business_type: ""
  });
  const radioStyle = {
    display: "inline-block",
    position: "static",
    margin: "0 5px 0 0",
    opacity: "initial"
  };
  const checkfunc = (tv) => {
    let array = [...selectedtvs];
    return (array.filter(obj => obj.tv_id === tv.tv_id)[0] ? true : false);
  }
  const selectAllfunc = (checked) => {
    let array = [...selectedtvs];

    if (!checked) {
      let checkInput=document.getElementById('select');
      checkInput.checked=false
      for (let i = 0; i < searchedArray.length; i++) {
        let tv = searchedArray[i];
        let index = array.indexOf(array.filter(obj => obj.tv_id === tv.tv_id)[0]);
        array.splice(index, 1);
      }
      return setselectedtvs(array);
    }

    for (let i = 0; i < searchedArray.length; i++) {
      let tv = searchedArray[i];
      let tvExists = array.filter(obj => obj.tv_id === tv.tv_id)[0] ? true : false;
      if (!tvExists) {
        array.push(tv);
      }
    }
    setselectedtvs(array);
  }
  const tvSelector = (tv) => {
    let array = [...selectedtvs];
    let tvexists = array.filter(obj => obj.tv_id === tv.tv_id)[0] ? true : false;
    if (tvexists) {
      let index = array.indexOf(array.filter(obj => obj.tv_id === tv.tv_id)[0]);
      array.splice(index, 1);
      setselectedtvs(array);
    } else {
      array.push(tv);
      setselectedtvs(array);
    }
  }
  const [tvs, setTvs] = useState([]);
  const [selectedtvs, setselectedtvs] = useState([]);
  const [data, setData] = useState([]);
  const [uploading, setUploading] = useState("Upload Video");
  const token = Cookies.get("jwt");
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);
  const [keyword, setkeyword] = useState("");
  const [addspacemodal, setaddspacemodal] = useState(false);
  const addspacemodalToggle = () => setaddspacemodal(!addspacemodal);
  const selectedTvsIds = selectedtvs.map((tv) => tv.tv_id);
  const fetchScreens = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response2 = await fetch(`https://admins.adspot.pub/api/admins/tvs/status`, request, { mode: "cors" });
      if (response2.status === 200) {
        let tvs = await response2.json();
        tvs = tvs.filter((x) => {
          return x.install_status == "installed"
        })
        for (let tv in tvs) {
          tvs[tv].selected = false;
        }
        setTvs(tvs);
      } else {
        toast.error(await response2.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/diffusions/default/all`,
        request,
        { mode: "cors" });
      let data = await response.json();
      if (response.status === 200) {
        setData(data);
        setIsLoading(false);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  }
  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    fetchData();
    fetchScreens();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const checkFilter = (item) => {
    let business = []

    switch (filterByBusiness) {
      case "Retail":
        business = item.business_type === "Retail"
        break;
      case "Pharma":
        business = item.business_type === "Pharma"
        break;
      case "Exposition":
        business = item.business_type === "Exposition"
        break;
      default:
        business = data;
        break;
    }
    return business
  }
  let filteredData = data.filter(checkFilter);
  let dataPattern = new RegExp(keyword, "i");
  let searchedData = filteredData.filter(function (diff) {
    return (
      dataPattern.test(diff.video_name) ||
      dataPattern.test(diff.video_product_name)
    )
  });
  const checkFilterModal = (item) => {
    let business = []

    switch (filterByBusinessModal) {
      case "Retail":
        business = item.business_type === "Retail"
        break;
      case "Pharma":
        business = item.business_type === "Pharma"
        break;
      case "Exposition":
        business = item.business_type === "Exposition"
        break;
      default:
        business = tvs;
        break;
    }
    return business
  }
  let filteredArray = tvs.filter(checkFilterModal);
  let pattern = new RegExp(keyword, "i");
  let searchedArray = filteredArray.filter(function (diff) {
    return (
      pattern.test(diff.contact_lastname) ||
      pattern.test(diff.contact_firstname)||
      pattern.test(diff.contact_region)||
      pattern.test(diff.contact_wilaya)
    )
  });

  //fetch data on first render

  const handleRowClicked = (row) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/broadcasting/default/broadcast:${row.default_diffusion_id}`,
      state: row
    });
  };



  const uploadVideo = async () => {
    setIsLoading(true);
    setUploading("Loading..")
    const formData = new FormData();
    const selectedLandscapeFile = landscapeVideoFile;
    const selectedPortraitFile = portraitVideoFile;
    formData.append('inputLandscapeFile', selectedLandscapeFile);
    formData.append('inputPortraitFile', selectedPortraitFile);
    formData.append('video_product_name', input.product_name);
    formData.append('video_product_description', input.product_description);
    // formData.append('video_start_date', input.start_date);
    // formData.append('video_end_date', input.end_date);
    formData.append('business_type', input.business_type);
    formData.append('tv_ids', selectedTvsIds);

    const request = {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: formData
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/videos/upload`, request, { mode: "cors" });
      let data = await response.json();
      if (response.status === 200) {
        fetchData()
      } else {
        let message = data.errorMessage;
        toast.error(message);
      }
    } catch (err) {
      toast.error(err.message);
    }
    setIsLoading(false);
    setUploading("Upload Video")
    Verticalcentermodaltoggle();

  }

  const handleDelete = (id, option) => {
    Swal.fire({
      // title: 'Are you sure?',
      text: "Are you sure you want to " + option + " this video ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm!'
    }).then((result) => {
      if (result.isConfirmed) {
        changeVideoState(id)
      } else if (result.isDenied) {
        return
      }
    })
  }

  const changeVideoState = async (id) => {
    setIsLoading(true);
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }, body: JSON.stringify({
        default_diffusion_id: id,
      }),
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/diffusions/default/state`, request, { mode: "cors" },
      );
      if (response.status === 200) {
        toast.success("Updated succesfully");
      } else {
        toast.error(await response.json());
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    fetchData();
  }

  const diffusionColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: false,
      center: true,
      width: "50px",
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Start Date",
      selector: (row) => { return moment(row.default_diffusion_start_date).format("DD/MM/YYYY HH:mm") },
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "End Date",
      selector: (row) => { return moment(row.default_diffusion_end_date).format("DD/MM/YYYY HH:mm") },
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Product Name",
      selector: (row) => row.video_product_name,
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Video Name",
      selector: (row) => row.video_name,
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Video length",
      selector: (row) => (row.landscape_video_length!=="0"?row.landscape_video_length:row.portrait_video_length )+ "s",
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Business Type",
      selector: (row) => row.business_type,
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    },
    {
      name: "Action",
      selector: (row) => changeStateBtn(row.is_active, row.default_diffusion_id),
      sortable: true,
      center: true,
      style: { margin: "0px", padding: "0px" }
    }

  ];

  const changeStateBtn = (state, id) => {

    if (state) {
      return <Button className="px-2 btn-danger" title='disable' onClick={(e) => {
        e.preventDefault()
        handleDelete(id, "disable")
      }}><i className='fa fa-times'></i></Button>
    } else {
      return <Button color='success' title='enable' className="px-2" onClick={(e) => {
        e.preventDefault()
        handleDelete(id, "enable")
      }}><i className='fa fa-check'></i></Button>
    }
  }





  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Broadcast" title="" hide={true} />
      <Container fluid="true">
        <Modal modalClassName='modal modal-dialog-scrollable d-flex' isOpen={addspacemodal} toggle={addspacemodalToggle} centered size='lg'>
          <ModalHeader toggle={addspacemodalToggle}>
            Add Screens
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Input type="text" placeholder='Search for Screens by Name, Region or Wilaya' value={keyword} onChange={(e) => { setkeyword(e.target.value) }} />
                  <Label className="form-label">Selected Screens: {selectedtvs.length}</Label>
                </FormGroup>
              </Col>
              <Col sm="6" lg="3" className="input--group">
                <FormGroup>
                  <Input
                    className="form-control"
                    type="select"
                    value={filterByBusinessModal}
                    onChange={(e) => {setFilterByBusinessModal(e.target.value);selectAllfunc(false)}}
                  >
                    <option value="all">All</option>
                    <option value="Pharma">Pharma</option>
                    <option value="Retail">Retail</option>
                    <option value="Exposition">Exposition</option>
                  </Input>
                  <Label className="form-label">Business Type </Label>
                </FormGroup>
              </Col>
              <Col md="12">
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <input
                        id='select'
                          style={radioStyle}
                          type="checkbox"
                          onChange={(e) => {
                            selectAllfunc(e.target.checked);
                          }}
                        />
                        select all
                      </th>
                      <th>Contact</th>
                      <th>Region</th>
                      <th>Wilaya</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchedArray.map((tv) => {
                      let shouldRender = pattern.test(tv.contact_region) || pattern.test(tv.contact_lastname) || pattern.test(tv.contact_firstname) || pattern.test(tv.contact_wilaya);
                      // if (shouldRender) {
                      return (shouldRender) ? (
                        <tr key={tvs.indexOf(tv)} >
                          <td>
                            <input type="checkbox" checked={checkfunc(tv)} onChange={
                              (e) => {
                                tvSelector(tv)
                              }
                            } />
                          </td>

                          <td>{tv.contact_lastname} {tv.contact_firstname}</td>
                          <td>{tv.contact_region}</td>
                          <td>{tv.contact_wilaya}</td>
                        </tr>
                      ) : null
                      //}
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter >
            <Button color="danger" onClick={addspacemodalToggle}>Close</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered>
          <ModalHeader toggle={Verticalcentermodaltoggle}>
            Add Video
          </ModalHeader>
          <ModalBody>
            <div className="mb-3">
              <Label>Product Name</Label>
              <Input type='text' placeholder='Product name' value={input.product_name} onChange={(e) => { setInput({ ...input, product_name: e.target.value }) }} />
            </div>
            <div className="mb-3">
              <Label>Product Description</Label>
              <Input type='textarea' placeholder='Product description' value={input.product_description} onChange={(e) => { setInput({ ...input, product_description: e.target.value }) }} />
            </div>
            <div className=' mb-3'>
              <Label className="form-label">Business type:  </Label>
              <Input
                name="select"
                type="select"
                value={input.business_type}
                onChange={(e) => { setInput({ ...input, business_type: e.target.value }) }}
              >
                <option value={"Pharma"}>
                  Pharma
                </option>
                <option value={"Retail"}>
                  Retail
                </option>
                <option value={"Exposition"}>
                  Exposition
                </option>
              </Input>
            </div>
            <div className='my-3 row'>
              <div className='col-6'>
                <Label>Upload Landscape Video</Label>
                <Input type="file" accept='video/*' onChange={(e) => { setLandscapeVideoFile(e.target.files[0]) }} />
              </div>
              <div className='col-6'>
                <Label>Upload Portrait Video</Label>
                <Input type="file" accept='video/*' onChange={(e) => { setPortraitVideoFile(e.target.files[0]) }} />
              </div>
            </div>
            <div className=' mb-3'>
              <Label className="form-label">Selected Screens: </Label>
              <Input className="form-control" type="number" placeholder="Discount" value={selectedtvs.length} disabled />
            </div>
            <div className=' mb-3'>
              <Button color="info" className="btn btn-block" onClick={addspacemodalToggle} >Add Screens</Button>
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="success" disabled={isLoading} onClick={uploadVideo} >{uploading}</Button>
            <Button color="danger" onClick={Verticalcentermodaltoggle}>Close</Button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col md="12">
            <Card>

              <CardBody className='p-4 pt-0'>
                <div className="table-responsive product-table">
                  <DataTable title="Broadcasting" className='table-boredered' onRowClicked={handleRowClicked} columns={diffusionColumns} data={searchedData} pagination highlightOnHover pointerOnHover
                    paginationPerPage={30}
                    subHeader
                    subHeaderComponent={<>
                      <FormGroup className='d-flex align-items-end'>
                        <Label className="form-label">Business Type: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          value={filterByBusiness}
                          onChange={(e) => setFilterByBusiness(e.target.value)}
                        >
                          <option value="all">All</option>
                          <option value="Pharma">Pharma</option>
                          <option value="Retail">Retail</option>
                          <option value="Exposition">Exposition</option>
                        </Input>
                      </FormGroup>
                      <input type="text" className='form-control w-25 mx-2' title="Search by client or status" placeholder='Search by product|video name' value={keyword} onChange={(e) => { setkeyword(e.target.value); }} />
                      <Button color='success' onClick={Verticalcentermodaltoggle}>+ Add Video</Button>
                    </>
                    }
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

        </Row>

      </Container>
    </Fragment>
  )
}

