import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, Button, Label, FormGroup, Input } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

export default function Contacts() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [filterByBusiness, setFilterByBusiness] = useState("all")
    const contactColumns = [
        {
            name: "Last Name ",
            selector: (row) => row.contact_lastname,
            sortable: true,
            center: true,
        },
        {
            name: "First Name ",
            selector: (row) => row.contact_firstname,
            sortable: true,
            center: true,
        }, {
            name: "Pharmacy",
            selector: (row) => row.contact_establishment,
            sortable: true,
            center: true,
        },
        {
            name: "Phone",
            selector: (row) => row.contact_phone,
            sortable: true,
            center: true,
        },
        {
            name: "Wilaya",
            selector: (row) => row.contact_wilaya,
            sortable: true,
            center: true,
        },
        {
            name: "City",
            selector: (row) => row.contact_city,
            sortable: true,
            center: true,
        },


        {
            name: "Start Date",
            selector: (row) => row.contract_start_date,
            sortable: true,
            center: true,
        },
        {
            name: "End Date",
            selector: (row) => row.contract_end_date,
            sortable: true,
            center: true,
        },

    ];
    const token = Cookies.get("jwt");
    const history = useHistory();

    const handleRowClicked = (row) => {

        history.push({
            pathname: `${process.env.PUBLIC_URL}/contacts/contact:${row.id_contact}`,
            state: row
        });
    };


    const fetchData = async () => {
        setIsLoading(true);
        const request = {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
                'Authorization': 'Bearer ' + token,
            }
        }
        try {
            var response = await fetch(`https://admins.adspot.pub/api/admins/contact`, request, { mode: "cors" });
            if (response.status === 200) {
                let data = await response.json();
                //map data
                setData(data);
                setIsLoading(false);
            } else {
                toast.error(await response.json());
                setIsLoading(false);
            }
        } catch (err) {
            toast.error(err.message);
            setIsLoading(false);
        }
    }

    const checkFilter = (item) => {
        let business = []

        switch (filterByBusiness) {
            case "Retail":
                business = item.business_type === "Retail"
                break;
            case "Pharma":
                business = item.business_type === "Pharma"
                break;
            case "Exposition":
                business = item.business_type === "Exposition"
                break;
            default:
                business = data;
                break;
        }
        return business
    }

    let filteredArray = data.filter(checkFilter);
    let pattern = new RegExp(search, "i");
    let searchedData = filteredArray.filter(function (contact) {
        return (
            pattern.test(contact.contact_lastname) ||
            pattern.test(contact.contact_firstname) ||
            pattern.test(contact.contact_phone)
        );
    });

    useEffect(() => {
        let unmount = false;
        if (unmount) {
            return;
        }
        fetchData();
        return () => {
            unmount = true;
            setIsLoading(false);
            window.scrollTo(0, 0);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (

        <Fragment>
            <Loader isLoading={isLoading} />
            <Breadcrumb parent="Dashboard" title="Contacts" />
            <Container fluid={true}>
                <Row>
                    <Col sm="6" lg="3" className="input--group">
                        
                    </Col>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className="table-responsive product-table">
                                    <DataTable onRowClicked={handleRowClicked} columns={contactColumns} data={searchedData} pagination highlightOnHover paginationPerPage={30} pointerOnHover
                                        actions={<div className='d-flex align-items-center'>
                                        <FormGroup className='d-flex mb-0'>
                            <Label className="form-label mb-0" style={{fontSize:"14px"}}>Business Type: </Label>
                            <Input
                                className="form-control"
                                type="select"
                                value={filterByBusiness}
                                onChange={(e) => setFilterByBusiness(e.target.value)}
                            >
                                <option value="all">All</option>
                                <option value="Pharma">Pharma</option>
                                <option value="Retail">Retail</option>
                                <option value="Exposition">Exposition</option>
                            </Input>
                        </FormGroup>
                                            <input type="text" placeholder='Search by Name or Phone Number' className=' form-control mx-2'
                                                value={search} onChange={(e) => setSearch(e.target.value)} />

                                            <Button color='success' onClick={() => {
                                                history.push({
                                                    pathname: `${process.env.PUBLIC_URL}/contacts/CreateContact`,
                                                });
                                            }}>+Add</Button>
                                        </div>} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className='my-3'>

                </Row>
            </Container>
        </Fragment>
    )
}