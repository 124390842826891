import React from 'react'
import logo from "./logo.png"
import style from "./style.css"

export const PDFInvoice = React.forwardRef((props, ref) => {
  const { client_name, client_address, client_email } = props.client;
  const invoice = props.invoice;
  const { time_slot_detail } = invoice;
  const slots = Object.keys(time_slot_detail ?? {});


  return (
    <div ref={ref} className='body px-4 my-2'>
      <header className="clearfix pt-3">
        <div id="logo">
          <img src={logo} alt="logo" />
        </div>
        <div id="company">
          <h2 className="name">SARL WISER GLOBAL COMMUNICATION</h2>
          <div>ZHUN B2 N1 EL EULMA 19600 SETIF</div>
          <div>+213 770 33 55 99</div>
          <div>web.adspot.pub</div>
        </div>
      </header>
      <main>
        <div id="details" className="clearfix">
          <div id="client">
            <h2 className="name">{client_name}</h2>
            <div className="address">{client_address}</div>
            <div className="email"><a href="mailto:john@example.com">{client_email}</a></div>
          </div>
          <div id="invoice">
            <h1>Détails Facturation</h1>
            <div className="date">Date Début: {props.dd}</div>
            <div className="date">Date Fin: {props.df}</div>
          </div>
        </div>
        {slots.map((prod, key) => (
          <div className="product-section" key={key} >
            <h4>Créneau: <span style={{ fontWeight: "bold" }}>{prod}</span></h4>
           
            <table border="0" cellSpacing="0" cellPadding="0" >
              <thead>
                <tr>
                  <th className="unit">#</th>
                  <th className="qty">Produit</th>
                  <th className="unit">Nbr Jours</th>
                  <th className="qty">Nbr Passages</th>
                  <th className="unit">Nbr Ecrans</th>
                  <th className="qty">Taille Video</th>
                  <th className="unit">Prix Créneau/Sec</th>
                  <th className="qty">Total HT</th>
                  <th className="unit">Remise</th>
                  <th className="qty">Total Après Remise</th>
                </tr>
              </thead>
              <tbody>
                {time_slot_detail[prod].map((slot, index) => (
                  <tr key={index}>
                    <td className="unit">{index + 1}</td>
                    <td className="qty">{slot.video_product_name}</td>
                    <td className="unit">{slot.days_number}</td>
                    <td className="qty">{slot.nbr_passage}</td>
                    <td className="unit">{slot.tvs_number}</td>
                    <td className="qty">{slot.space_video_length} sec</td>
                    <td className="unit">{slot.time_slot_price} DZD</td>
                    <td className="qty">{slot.totalht.toFixed(2)} DZD</td>
                    <td className="unit">{slot.space_discount} %</td>
                    <td className="qty">{slot.total_discount.toFixed(2)} DZD</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <table>
          <tfoot>
            <tr>
              <td colSpan="2"></td>
              <td colSpan="2"></td>
              <td colSpan="2">TOTAL HT</td>
              <td>{invoice.total_ht?.toFixed(2)} DZD</td>
            </tr>
            <tr>
              <td colSpan="2"></td>
              <td colSpan="2"></td>
              <td colSpan="2">TVA 19%</td>
              <td>{(+invoice.total_ttc - +invoice.total_ht).toFixed(2)} DZD</td>
            </tr>
            <tr>
              <td colSpan="2"></td>
              <td colSpan="2"></td>
              <td colSpan="2">TOTAL TTC</td>
              <td>{invoice.total_ttc?.toFixed(2)} DZD</td>
            </tr>
          </tfoot>
        </table>
      </main>
    </div>

  )
});