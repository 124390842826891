import Cookies from 'js-cookie';
import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Table, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import moment from 'moment';

import { Link, useHistory } from 'react-router-dom';
import StatusPill from '../components/StatusPill';
// import Chart from 'react-apexcharts';

export default function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ diffusions: [] });
  const [info, setInfo] = useState();
  const token = Cookies.get("jwt");
  let time = new Date();
  const history = useHistory();

  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }

    (async function () {
      setIsLoading(true);
      const request = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
          'Authorization': 'Bearer ' + token,
        }
      }
      
      try {
        var response = await fetch(`https://admins.adspot.pub/api/admins/dashboard`, request, { mode: "cors" });
        if (response.status === 200) {
          let data = await response.json();
          setData(data);
        } else {
          toast.error(await response.json());
        }
        var response1 = await fetch(`https://admins.adspot.pub/api/admins/monitoring`, request, { mode: "cors" });
        if (response1.status === 200) {
          let data = await response1.json();
          setInfo(data);
        } else {
          toast.error(await response1.json());
        }
        setIsLoading(false);
      } catch (err) {
        toast.error(err.message);
        setIsLoading(false);
      }
    })();

    return () => {
      unmount = true;
      window.scrollTo(0, 0);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Dashboard" title="Dashboard" hide={true} />
      <Container fluid="true ">
      <Row className=" second-chart-list third-news-update  " >
      <Col md="12" className="dashboard-sec box-col-12 ">
            <Card className="earning-card p-3 ">
              <CardBody className="p-0">
                <Row className="m-0">
                  
                  <Col md="12" className="p-0">
                    
                    <Row className=" m-0">
                      <Col xl="4" md="6" sm="6" className="pl-0">
                        <div className="media ">
                          <div className="media-left bg-warning"><i className="icofont icofont-network-tower"></i></div>
                          <div className="media-body">
                            <h6>Pending Broadcasts</h6>
                            <h4>{
                        data.diffusions.filter((x) => {
                          return  x.diffusion_status="Pending";
                        }).length
                      }</h4>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="6" sm="6">
                        <div className="media ">
                          <div className="media-left bg-info"><i className="icofont icofont-hard-disk"></i></div>
                          <div className="media-body">
                          <h6>Main Storage</h6>
                            <small><strong>Free Space:</strong> {info?.main_free}</small><br/>
                            <small><strong>Used Space:</strong> {info?.main_used}</small>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="12" className="pr-0">
                        <div className="media ">
                          <div className="media-left bg-secondary"><i className="icofont icofont-database"></i></div>
                          <div className="media-body">
                            <h6>Backup Storage</h6>
                            <small><strong>Free Space:</strong> {info?.backup_free}</small><br/>
                            <small><strong>Used Space:</strong> {info?.backup_used}</small>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      
        <Row>
          <Col sm="12">
          <Card>
              <CardBody className="p-0">
                <Row className="m-0 chart-main">
                
                  <Col xl="4" md="6" sm="6" className="px-4 py-1 box-col-6 " style={{ cursor: "pointer" }}>
                    <div className="media align-items-center ">
                      <div className="hospital-small-chart">
                        <div className="small-bar p-4">
                          <div className="align-self-center text-center text-primary "><i className="icofont icofont-monitor icofont-3x"></i></div>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <span>Total Screens</span><h4>{data.tvs?.length}</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xl="4" md="6" sm="6" className="px-4 py-1 box-col-6" style={{ cursor: "pointer" }}>
                    <div className="media align-items-center">
                      <div className="hospital-small-chart">
                        <div className="small-bar p-4">
                          <div className="align-self-center text-center text-success "><i className=" icofont icofont-toggle-on icofont-3x"></i></div>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <span>Screens On</span><h4>{
                      data.tvs?.filter((x) => {
                        return moment(time).diff(x.last_connection, "minutes") <= 15 
                      }).length}</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                  
                  <Col xl="4" md="6" sm="6" className=" py-1 box-col-6" style={{ cursor: "pointer" }}>
                    <div className="media align-items-center px-4">
                      <div className="hospital-small-chart">
                        <div className="small-bar p-4">
                          <div className="align-self-center text-center text-danger"><i className=" icofont icofont-toggle-off icofont-3x"></i></div>
                        </div>
                      </div>
                      <div className="media-body">
                        <div className="right-chart-content">
                          <span>Screens Off</span><h4>{
                      data.tvs?.filter((x) => {
                        return (!x.last_connection || moment(time).diff(x.last_connection, "minutes") > 15)
                      }).length}</h4>
                        </div>
                      </div>
                    </div>
                  </Col>
                  
                  
                </Row>
              </CardBody>
            </Card>
          </Col>
        
        </Row>
        
        <Row>
          <Col>
            <h4 className='mb-2'>Pending broadcasts</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <thead>
                <tr>
                  <th><strong>Client</strong></th>
                  <th><strong>Created at</strong></th>
                  <th><strong>Start Date</strong></th>
                  <th><strong>End Date</strong></th>
                  <th><strong>Broadcast Duration</strong></th>
                  <th><strong>Start Date</strong></th>
                </tr>
              </thead>
              <tbody>
                {data.diffusions?.map((item) => {
                  return (
                    <tr className='row-clickable' key={data.diffusions.indexOf(item)} onClick={() => { history.push(`${process.env.PUBLIC_URL}/broadcasting/broadcast:${item.diffusion_id}`) }}>
                      <td onClick={(event) => { event.stopPropagation() }}>
                        <Link to={`${process.env.PUBLIC_URL}/clients/client:${item.client_id}`}>{item.client_name}</Link>
                      </td>
                      <td>{moment(item.created_at).format("DD/MM/YYYY HH:mm")}</td>
                      <td>{moment(item.diffusion_start_date).format("DD/MM/YYYY HH:mm")}</td>
                      <td>{moment(item.diffusion_end_date).format("DD/MM/YYYY HH:mm")}</td>
                      <td>{moment(item.diffusion_end_date).diff(item.diffusion_start_date, "days")} days</td>
                      <td>
                        {moment(item.diffusion_end_date).isBefore(new Date()) && <span className="badge badge-danger badge-pill">EXPIRED!</span>}
                        <StatusPill status={item.diffusion_status}></StatusPill>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
