import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, CardBody, Button, Input, Table, CardFooter } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import ReactApexChart from "react-apexcharts";

export default function ClientDetails() {
    const history = useHistory();
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [filteredScreens, setFilteredScreens] = useState([]);
    const token = Cookies.get("jwt");
    const [data, setData] = useState({ contact: {}, screens: [],therapeutic_area:[{complement:0,cosmetic:0,gynecology:0,pain:0,pediatrics:0}] });
    
    
    let time = new Date();
    const timeCalculator = (lastConnection) => {
        if (!lastConnection) {
            return "not yet installed";
        }
        let diff = moment(time).diff(lastConnection, "seconds");
        if (diff < 60) {
            return diff + " s";
        }
        if (diff < 3600) {
            return (diff / 60).toFixed(0) + " min";
        }
        if (diff < 86400) {
            return (diff / 3600).toFixed(0) + " h";
        }
        return (diff / 86400).toFixed(0) + " d";
    };

    const handleChangePassword = (id) => {

        Swal.fire({
            text: "Are you sure you want to change the password ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm!'
        }).then((result) => {
            if (result.isConfirmed) {
                changePwd(id)
            } else if (result.isDenied) {
                return
            }
        })

    }

    const changePwd = async (id) => {
        setIsLoading(true);
        const request = {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({ contact_id: id }) ,
        }

        try {
            var response = await fetch(`https://admins.adspot.pub/api/admins/contact/password/reset`, request, { mode: "cors" });
            if (response.status === 200) {
                toast.success("Password changed successfully");
            } else {
                toast.error(await response.json());
            }
            setIsLoading(false);
        } catch (err) {
            toast.error(err.message);
            setIsLoading(false);
        }
    }

    const handleRowClicked = (row) => {

        history.push({
            pathname: `${process.env.PUBLIC_URL}/screens/screen:${row.tv_id}`,
            state: row
        });
    };

    const arroundColumns = [
        {
            name: "#",
            selector: (row, index) => parseInt(index.toString()) + 1,
            sortable: false,
            center: false,
            width: "50px"
        },
        {
            name: "Fullname",
            selector: (row) => row.doctor_firstname + " " + row.doctor_lastname,
            sortable: true,
            center: true,
        },
        {
            name: "Speciality",
            selector: (row) => row.doctor_speciality,
            sortable: true,
            center: true,
        },
        {
            name: "Address",
            selector: (row) => row.doctor_address,
            sortable: true,
            center: true,
        }
    ];
    const screenColumns = [
        {
            name: "#",
            selector: (row, index) => parseInt(index.toString()) + 1,
            sortable: false,
            center: false,
            width: "50px"
        },
        {
            name: "Contact",
            selector: () => data.contact.contact_firstname + " " + data.contact.contact_lastname,
            sortable: true,
            center: true,
        },
        {
            name: "First Connection",
            selector: (row) => moment(row.first_connection).format('DD/MM/YYYY HH:mm'),
            sortable: true,
            center: true,
        },
        {
            name: "Last Connection",
            selector: (row) => moment(row.last_connection).format('DD/MM/YYYY HH:mm'),
            sortable: true,
            center: true,
        },
        {
            name: "Installation",
            selector: (row) => statusPill(row.install_status),
            sortable: true,
            center: true,
        },
        {
            name: "Last Connection Interval",
            selector: (row) => (!row.last_connection) ||
                moment(time).diff(
                    row.last_connection,
                    "seconds"
                ) > 60 ? (
                <span className="badge badge-danger badge-pill">
                    {timeCalculator(row.last_connection)}
                </span>
            ) : (
                <span className="badge badge-success badge-pill">
                    {timeCalculator(row.last_connection)}
                </span>
            ),
            sortable: true,
            center: true,
            width: "100px"
        },


    ];

    const statusPill = (status) => {
        switch (status) {
            case "added":
                return <span className="badge badge-dark badge-pill">
                    {status}</span>
            case "to prepare":
                return <span className="badge badge-info badge-pill">
                    {status}</span>
            case "ready to install":
                return <span className="badge badge-primary badge-pill">
                    {status}</span>
            case "to install":
                return <span className="badge badge-warning badge-pill">
                    {status}</span>
            case "installed":
                return <span className="badge badge-success badge-pill">
                    {status}</span>
            default:

                return <span className="badge badge-danger badge-pill">
                    {status}</span>
        }
    }


    const fetchData = async () => {
        setIsLoading(true);
        const request = {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
                'Authorization': 'Bearer ' + token,
            }
        }
        try {
            var response1 = await fetch(`https://admins.adspot.pub/api/admins/contacts/contact?contact_id=${slug}`, request, { mode: "cors" });
            if (response1.status === 200) {
                let data = await response1.json();
                setData(data);
                setFilteredScreens(data.screens);

            } else {
                toast.error(await response1.json());
            }


        } catch (err) {
            toast.error(err.message);
        }
    }
    useEffect(() => {
        let unmount = false;
        if (unmount) {
            return;
        }
        fetchData();
        return () => {
            unmount = true;
            setIsLoading(false);
            window.scrollTo(0, 0)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const result = data.screens.filter(screen => {
            return screen.tv_serial_number.toLowerCase().match(search.toLowerCase())
        })
        setFilteredScreens(result)
    }, [search])
    return (
        <Fragment>
            <Loader isLoading={isLoading} />
            <Breadcrumb parent="Contacts" title="Contact Details" hide={false} />
            <Container fluid="true">
                <Row>

                    <Col md="12">
                        <Card>

                            <CardBody className='p-4'>
                                <Row>
                                    <Col md="6">
                                        <Card className='p-0'>
                                            <CardHeader className='p-2'>
                                                <h4 className='text-primary text-center'>{data.contact.contact_sexe == "man"||"Man" ? "Mr " : "Mrs "}{data.contact.contact_firstname} {data.contact.contact_lastname}</h4>
                                            </CardHeader>
                                            <CardBody>
                                                <Table >
                                                    <tbody className='table-bordered'>

                                                        <tr>
                                                            <th>Pharmacy </th>
                                                            <td><p>{data.contact.contact_establishment}</p></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone </th>
                                                            <td><p>{data.contact.contact_phone}</p></td>
                                                        </tr>
                                                        <tr>
                                                            <th>Email</th>
                                                            <td>{data.contact.contact_email}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Age</th>
                                                            <td>{data.contact.contact_age}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Referal Name</th>
                                                            <td>{data.contact.referrel_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Region </th>
                                                            <td>{data.contact.contact_region}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Wilaya, City</th>
                                                            <td>{data.contact.contact_wilaya}, {data.contact.contact_city}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Address</th>
                                                            <td>{data.contact.contact_address}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Business Type</th>
                                                            <td>{data.contact.business_type}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Contract</th>
                                                            <td>From: {moment(data.contact.contract_start_date).format("DD/MM/YYYY")} To :{moment(data.contact.contract_end_date).format("DD/MM/YYYY")}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                            <CardFooter className='p-4'>
                                                <Row>
                                                    <Col md="6" sm="12">
                                                        <Button className='btn-block ' color="danger"
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                handleChangePassword(slug)
                                                            }}
                                                        >Change Password</Button>
                                                    </Col>
                                                    <Col md="6" sm="12">
                                                        <Button className='btn-block' color='warning' onClick={()=>{
                                                            history.push({
                                                                pathname: `${process.env.PUBLIC_URL}/contacts/modify/contact:${slug}`,
                                                                state: data
                                                            });
                                                        }}>Modify</Button>
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>

                                    </Col>
                                    <Col md="6">
                                        {data.therapeutic_area.length !=0?
                                        <Card className='p-0'>
                                        <CardHeader className='p-2'>
                                            <h4 className='text-center'>Potentials</h4>
                                        </CardHeader>
                                        <CardBody className='p-1'>
                                            <ReactApexChart
                                                type="pie"
                                                height={350}
                                                series={[
                                                    parseInt(data?.therapeutic_area[0]?.pain),
                                                    parseInt(data?.therapeutic_area[0]?.complement),
                                                    parseInt(data?.therapeutic_area[0]?.cosmetic),
                                                    parseInt(data?.therapeutic_area[0]?.pediatrics),
                                                    parseInt(data?.therapeutic_area[0]?.gynecology),
                                                ]}
                                                options={{
                                                    labels: [
                                                        "pain",
                                                        "complement",
                                                        "cosmetics",
                                                        "pediatrics",
                                                        "gynecology",
                                                    ],
                                                    colors: ["#ffc107", "#c6ff00", "#198754", "#4fc3f7", "#ce93d8"],
                                                }}

                                            />



                                        </CardBody>
                                    </Card>:""
                                        }
                                        
                                        <Card className='p-0'>
                                            <CardHeader className='p-2 text-success text-center'>
                                                <h5>Schedual</h5>
                                            </CardHeader>
                                            <CardBody className='p-0'>
                                                <Table>
                                                    <tbody className='table-bordered text-center'>
                                                        <tr>
                                                            <th>Sunday </th>
                                                            <td>8:30-22:00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Monday</th>
                                                            <td>8:30-22:00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Tuesday</th>
                                                            <td>8:30-22:00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Wednesday</th>
                                                            <td>8:30-22:00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Thursday</th>
                                                            <td>8:30-22:00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Friday</th>
                                                            <td>8:30-22:00</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Saturday</th>
                                                            <td>8:30-22:00</td>
                                                        </tr>

                                                    </tbody>

                                                </Table>

                                            </CardBody>
                                           
                                        </Card>


                                    </Col>
                                </Row>



                            </CardBody>
                            
                        </Card>
                    </Col>


                </Row>
                <Row>
                    <Col md='12' className='mb-4'>

                        <DataTable  title="Assigned Screens List" columns={screenColumns} data={filteredScreens} 
                        // subHeader
                            // subHeaderComponent={
                            //     <input type="text" className='form-control w-25' placeholder='Search by SN' value={search} onChange={(e) => setSearch(e.target.value)} />
                            // }
                            pointerOnHover highlightOnHover onRowClicked={handleRowClicked}
                        />

                    </Col>
                    <Col md='12' className='mb-4'>
                        {/* <DataTable title="Assigned Screens List" columns={arroundColumns} data={doctorsArround}
                                                    highlightOnHover
                                                /> */}
                    </Col>

                </Row>
            </Container>
        </Fragment >

    )
}