import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../layout/loader";
import DataTable from 'react-data-table-component';
import { File, Plus, Search } from 'react-feather'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import Breadcrumb from "../../layout/breadcrumb";
import Cookies from "js-cookie";
import moment from "moment";
import { useHistory } from "react-router-dom";


export default function TVs() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);
  const [contacts, setContacts] = useState([])
  const [contact, setContact] = useState([])
  const [filteredData, setFilteredData] = useState("all");
  const [filteredState, setFilteredState] = useState("all");
  const [filterByBusiness, setFilterByBusiness] = useState("all")
  // const [dateFilter, setDateFilter] = useState("");

  const [inputData, setInputData] = useState({
    contact_id: "",
    tv_type: "landscape",
    tv_position: "Wall",
    tv_internet_connection: "",
    tv_serial_number: "",
    tv_modem_phone: "",
    tv_size: "32",
    tv_resolution: "HD",
    business_type: "Pharma",
    tv_name: "",
    owned_by: "ADM0000000000000000248"
  });
  const handleChange = (contactId) => {
    const c = contacts.filter((cont) => {
      return cont.id_contact == contactId
    })
    setContact(c[0])

    setInputData({
      ...inputData,
      contact_id: c[0].id_contact,
    });
  }


  const timeCalculator = (lastConnection) => {
    if (!lastConnection) {
      return "off";
    }
    let diff = moment(time).diff(lastConnection, "seconds");
    if (diff < 60) {
      return diff + " s";
    }
    if (diff < 3600) {
      return (diff / 60).toFixed(0) + " min";
    }
    if (diff < 86400) {
      return (diff / 3600).toFixed(0) + " h";
    }
    return (diff / 86400).toFixed(0) + " d";
  };
  const screenColumns = [
    {
      name: "#",
      selector: (row, index) => parseInt(index.toString()) + 1,
      sortable: false,
      center: false,
      width: "50px"
    },
    {
      name: "Contact",
      selector: (row) => row.contact_lastname + " " + row.contact_firstname,
      sortable: true,
      center: true,
    },
    {
      name: "Screen Name",
      selector: (row) => row.tv_name && row.tv_name,
      sortable: true,
      center: true,
    },
    {
      name: "First Connection",
      selector: (row) => moment(row.first_connection).format('DD/MM/YYYY HH:mm'),
      sortable: true,
      center: true,
    },
    {
      name: "Last Connection",
      selector: (row) => moment(row.last_connection).format('DD/MM/YYYY HH:mm'),
      sortable: true,
      center: true,
    },
    {
      name: "Installation",
      selector: (row) => statusPill(row.install_status),
      sortable: true,
      center: true,
    },
    {
      name: "Last Connection Interval",
      selector: (row) => (!row.last_connection) ||
        moment(time).diff(
          row.last_connection,
          "minutes"
        ) > 15 ? (
        <span className="badge badge-danger badge-pill">
          {timeCalculator(row.last_connection)}
        </span>
      ) : (
        <span className="badge badge-success badge-pill">
          {timeCalculator(row.last_connection)}
        </span>
      ),
      sortable: true,
      center: true,
      width: "100px"
    },
    {
      name: "Off Duration",
      selector: (row) => getOffDuration(row.off_duration),
      // off_duration
      sortable: true,
      center: true,
    },
  ];

  const statusPill = (status) => {
    switch (status) {
      case "added":
        return <span className="badge badge-dark badge-pill">
          {status}</span>
      case "to prepare":
        return <span className="badge badge-info badge-pill">
          {status}</span>
      case "ready to install":
        return <span className="badge badge-primary badge-pill">
          {status}</span>
      case "to install":
        return <span className="badge badge-warning badge-pill">
          {status}</span>
      case "installed":
        return <span className="badge badge-success badge-pill">
          {status}</span>
      default:

        return <span className="badge badge-danger badge-pill">
          {status}</span>
    }
  }


  const getOffDuration = (inteval) => {
    if (!inteval) {
      return "00:00"
    }
    const hours = inteval.hours != null ? inteval.hours : "00"
    const minutes = inteval.minutes != null ? inteval.minutes : "00"
    return hours + ":" + minutes
  }
  const getContacts = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/contact`,
        request,
        { mode: "cors" }
      );
      let data = await response.json();
      if (response.status === 200) {
        setContacts(data.sort((a, b) => a.contact_lastname.localeCompare(b.contact_lastname)));
      } else {
        toast.error(data);
      }
      setIsLoading(false);

    } catch (err) {
      toast.error(err.message);
    }
  };



  const handleRowClicked = (row) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/screens/screen:${row.tv_id}`,
      state: row
    });
  };
  const addTv = async () => {
    if (inputData.contact_id == "" || inputData.tv_serial_number == "") {
      toast.error("You must enter the SN and Contact !")
      return
    }
    setIsLoading(true);
    const body = {
      ...inputData,
    };
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ ...body }),
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/tvs/new`,
        request,
        { mode: "cors" }
      );

      if (response.status === 200) {
        toast.success("Screen added successfully");
        setIsLoading(false);
      } else {
        toast.error("error !");
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    fetchData();
    Verticalcentermodaltoggle();
  };

  //fetch data on first render
  const [data, setData] = useState([]);


  let time = new Date();
  const token = Cookies.get("jwt");

  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/tvs/status`,
        request,
        { mode: "cors" }
      );
      if (response.status === 200) {
        let data = await response.json();
        setData(data);
        time.setFullYear(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );
        time.setHours(
          new Date().getHours(),
          new Date().getMinutes(),
          new Date().getSeconds()
        );
      } else {
        toast.error(await response.json());
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    fetchData();
    getContacts();
    const intervalId = setInterval(() => {
      fetchData();

    }, 10000);
    return () => {
      setIsLoading(false);
      window.scrollTo(0, 0);
      clearInterval(intervalId);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps



  const [keyword, setkeyword] = useState("");
  let pattern = new RegExp(keyword, "i");

  const checkFilter = (item) => {
    let status = [], state = [], business = []
    switch (filteredData) {
      case "added":
        status = item.install_status == "added"
        break;
      case "to prepare":
        status = item.install_status == "to prepare"
        break;
      case "ready to install":
        status = item.install_status == "ready to install"
        break;
      case "not installed":
        status = item.install_status == "not installed"
        break;
      case "to install":
        status = item.install_status == "to install"
        break;
      case "installed":
        status = item.install_status == "installed"
        break;
      default:
        status = data;
        break;
    }
    switch (filteredState) {
      case "on":
        state = moment(time).diff(item.last_connection, "minutes") <= 15
        break;
      case "off":
        state = (!item.last_connection || moment(time).diff(item.last_connection, "minutes") > 15)
        break;
      default:
        state = data;
        break;
    }

    switch (filterByBusiness) {
      case "Retail":
        business = item.business_type === "Retail"
        break;
      case "Pharma":
        business = item.business_type === "Pharma"
        break;
      case "Exposition":
        business = item.business_type === "Exposition"
        break;
      default:
        business = data;
        break;
    }
    return status && state && business
  }

  let filteredArray = data.filter(checkFilter);
  let searchedArray = filteredArray.filter(function (tv) {
    return (
      pattern.test(tv.contact_lastname) ||
      pattern.test(tv.contact_firstname) ||
      pattern.test(tv.tv_serial_number) ||
      pattern.test(tv.contact_wilaya)
    );
  });
  const businessFilter = (item) => {
    let business = []
    switch (filterByBusiness) {
      case "Retail":
        business = item.business_type === "Retail"
        break;
      case "Pharma":
        business = item.business_type === "Pharma"
        break;
      case "Exposition":
        business = item.business_type === "Exposition"
        break;
      default:
        business = data;
        break;
    }
    return business
  }
  let businessArray = data.filter(businessFilter);


  const MyExpander = ({ data }) => <pre>
    <table className="table table-bordered table-striped-columns">
      <thead>

        <tr className="table-active">
          <th>SN°</th>
          <th>Fullname</th>
          <th>Phone</th>
          <th>City, Wilaya</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{data.tv_serial_number}</td>
          <td>{data.contact_firstname + " " + data.contact_lastname}</td>
          <td>{data.contact_phone}</td>
          <td>{data.contact_city + ", " + data.contact_wilaya}</td>
        </tr>
      </tbody>
    </table></pre>;
  const convertArrayOfObjectsToCSV = (array) => {
    let result;
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(expotedArray[0]);
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  const expotedArray = searchedArray.map((elem) => {
    const container = {}
    container.serial_number = elem.tv_serial_number
    container.tv_login_id = elem.tv_login_id
    container.contact_lastname = elem.contact_lastname
    container.contact_firstname = elem.contact_firstname
    container.contact_phone = elem.contact_phone
    container.contact_email = elem.contact_email
    container.contact_region = elem.contact_region
    container.contact_city = elem.contact_city
    container.contact_wilaya = elem.contact_wilaya
    container.first_connection = moment(elem.first_connection).format('DD/MM/YYYY HH:mm')
    container.last_connection = moment(elem.last_connection).format('DD/MM/YYYY HH:mm')

    return container
  })

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }
  const Export = ({ onExport }) => <Button className="px-2 py-auto" onClick={e => onExport(e.target.value)}><File size="15px" /> Export</Button>;
  const actionsMemo = <Export onExport={() => downloadCSV(expotedArray)} />

  console.log(data);
  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="TVs" title="" hide={true} />
      <Container fluid="true">
 <Row>
          <Col col="12">
            <Card>
              <CardBody>

                <Row className="align-items-end">
                  <Col sm="6" lg="3">
                    <FormGroup>
                      <Label className="form-label">
                        Search for Screen By SN°, Contact Name Or Wilaya:
                      </Label>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text"><Search /></div>
                        </div>
                        <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Search" value={keyword} onChange={(e) => { setkeyword(e.target.value); }} />
                      </div>
                    </FormGroup>
                  </Col>


                  <Col sm="6" lg="3" className="input--group">
                    <FormGroup>
                      <Label className="form-label">Filter By State: </Label>
                      <Input
                        className="form-control"
                        type="select"
                        value={filteredState}
                        onChange={(e) => setFilteredState(e.target.value)}
                      >
                        <option value="all">All</option>
                        <option value="on">On</option>
                        <option value="off">Off</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="6" lg="3" className="input--group">
                    <FormGroup>
                      <Label className="form-label">Filter By Status: </Label>
                      <Input
                        className="form-control"
                        type="select"
                        value={filteredData}
                        onChange={(e) => setFilteredData(e.target.value)}
                      >
                        <option value="all">All</option>
                        <option value="added">Added</option>
                        <option value="to prepare">To Prepare</option>
                        <option value="ready to install">Ready to Install</option>
                        <option value="not installed">Not Installed</option>
                        <option value="to install">To Install</option>
                        <option value="installed">Installed</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="6" lg="3" className="input--group">
                    <FormGroup>
                      <Label className="form-label">Business Type: </Label>
                      <Input
                        className="form-control"
                        type="select"
                        value={filterByBusiness}
                        onChange={(e) => setFilterByBusiness(e.target.value)}
                      >
                        <option value="all">All</option>
                        <option value="Pharma">Pharma</option>
                        <option value="Retail">Retail</option>
                        <option value="Exposition">Exposition</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className=" second-chart-list third-news-update  " >
          <Col md="12" className="dashboard-sec box-col-12 ">
            <Card className="earning-card p-3 ">
              <CardBody className="p-0">
                <Row>
                  <Col sm="12">
                    <Card className="p-0">
                      <CardBody className="p-0">

                        <Row className="row row-cols-3 row-cols-md-3 g-3 ">

                          <Col className=" border-right" >
                            <div className="media align-items-center">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-dark "><i className="icofont icofont-monitor icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>Total</span><h4>{businessArray.length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>


                          <Col className=" border-right">
                            <div className="media align-items-center">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-success "><i className=" icofont icofont-toggle-on icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>On</span><h4>{
                                    businessArray.filter((x) => {
                                      return moment(time).diff(x.last_connection, "minutes") <= 15
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col>
                            <div className="media align-items-center ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-danger"><i className=" icofont icofont-toggle-off icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>Off</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "installed" && (!x.last_connection || moment(time).diff(x.last_connection, "minutes") > 15)
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>

                        </Row>




                      </CardBody>
                    </Card>

                    <Card className="p-0">
                      <CardBody className="p-0">
                        <Row className="row row-cols-1 row-cols-md-3 g-3 ">



                          <Col className=" border-right" >
                            <div className="media align-items-center ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-dark "><i className="icofont icofont-ui-add icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>Added</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "added"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className=" border-right">
                            <div className="media align-items-center">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-info"><i className=" icofont icofont-spinner-alt-2 icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>To Prepare</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "to prepare"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className=" border-right" >
                            <div className="media align-items-center  ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-primary "><i className=" icofont icofont-gears icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>Ready To Install</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "ready to install"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>

                        </Row>
                      </CardBody>
                    </Card>

                    <Card className="p-0">
                      <CardBody className="p-0">
                        <Row className="row row-cols-1 row-cols-md-3 g-3 ">

                          <Col className=" border-right">
                            <div className="media align-items-center ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-dark"><i className=" icofont icofont-broken icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>Not Installed</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "not installed"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className=" border-right">
                            <div className="media align-items-center  ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-success "><i className=" icofont icofont-badge  icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>Installed</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "installed"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className=" border-right">
                            <div className="media align-items-center ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-warning"><i className=" icofont icofont-network icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>To Install</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "to install"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>

                        </Row>
                      </CardBody>
                    </Card>

                    <Card className="p-0">
                      <CardBody className="p-0">
                        <Row className="row row-cols-1  row-cols-md-4 g-3 ">
                          <Col className=" border-right" >
                            <div className="media align-items-center  ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-danger "><i className=" icofont icofont-warning icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>Down</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "down"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className=" border-right">
                            <div className="media align-items-center ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-info"><i className=" icofont icofont-under-construction icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>To Repair</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "to repair"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className="border-right">
                            <div className="media align-items-center ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-success"><i className=" icofont icofont-check-circled icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>Repaired</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "repaired"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div className="media align-items-center ">
                              <div className="hospital-small-chart">
                                <div className="small-bar p-4">
                                  <div className="align-self-center text-center text-dark"><i className=" icofont icofont-recycle icofont-3x"></i></div>
                                </div>
                              </div>
                              <div className="media-body">
                                <div className="right-chart-content">
                                  <span>Damaged</span><h4>{
                                    businessArray.filter((x) => {
                                      return x.install_status == "damaged"
                                    }).length}</h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>


                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal
          isOpen={Verticalcenter}
          toggle={Verticalcentermodaltoggle}
          centered
          size="lg"
        >
          <ModalHeader toggle={Verticalcentermodaltoggle}>
            Add Screen
          </ModalHeader>
          <ModalBody>
            <form action="">
              <Row>


                <Col md="6" className='input--group'>
                  <FormGroup>
                    <Label className="form-label" >Pharmacy name: </Label>
                    <Input type="select" className="form-control" required placeholder="Type" value={contact.id_contact || ""} onChange={(e) => { handleChange(e.target.value) }}>
                      <option value={""}>{"Select Pharmacy"}</option>
                      {contacts.map((cont) => {
                        return <option key={cont.id_contact} value={cont.id_contact}>{cont.contact_lastname} {" "}{cont.contact_firstname}</option>
                      })}

                    </Input>

                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Pharmacy address: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Address of the pharmacy"
                      value={((typeof contact.contact_address != "undefined") ? contact.contact_address + ", " : "") + ((typeof contact.contact_city != "undefined") ? contact.contact_city + ", " : "")
                        + ((typeof contact.contact_wilaya != "undefined") ? contact.contact_wilaya : "") || ""}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md="6" className='input--group'>
                  <FormGroup>
                    <Label className="form-label" >TV name: </Label>
                    <Input type="text" className="form-control" required placeholder="Tv Name" value={inputData.tv_name || ""} onChange={(e) => { setInputData({ ...inputData, tv_name: e.target.value, }); }}>

                    </Input>

                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen serial N°: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Serial Number"
                      value={inputData.tv_serial_number}
                      required
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          tv_serial_number: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen type: </Label>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="Screen Type"
                      value={inputData.tv_type}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          tv_type: e.target.value,
                        });
                      }}
                    >
                      <option value="landscape">Landscape</option>
                      <option value="portrait">Portrait</option>
                    </Input>

                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen position: </Label>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="TV position"

                      value={inputData.tv_position}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          tv_position: e.target.value,
                        });
                      }}
                    >
                      <option value="wall">Wall</option>
                      <option value="roof">Roof</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">
                      internet connection:{" "}
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="TV internet connection"
                      value={inputData.tv_internet_connection}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          tv_internet_connection: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">
                      Modem Phone:{" "}
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Modem Phone"
                      value={inputData.tv_modem_phone}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          tv_modem_phone: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen Size: </Label>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="Screen Size"

                      value={inputData.tv_size}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          tv_size: e.target.value,
                        });
                      }}
                    >
                      <option value="32">32"</option>
                      <option value="40">40"</option>
                      <option value="43">43"</option>
                      <option value="50">50"</option>
                      <option value="55">55"</option>
                      <option value="60">60"</option>
                      <option value="65">65"</option>
                      <option value="70">70"</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen Resolution: </Label>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="Screen Resolution"

                      value={inputData.tv_resolution}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          tv_resolution: e.target.value,
                        });
                      }}
                    >
                      <option value="HD">HD</option>
                      <option value="FHD">FHD"</option>
                      <option value="4K">4K</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className='input--group'>
                  <FormGroup>
                    <Label className="form-label">Business type:  </Label>
                    <Input
                      name="select"
                      type="select"
                      onChange={(e) => { setInputData({ ...inputData, business_type: e.target.value }) }}
                    >
                      <option value={"Pharma"}>
                        Pharma
                      </option>
                      <option value={"Retail"}>
                        Retail
                      </option>
                      <option value={"Exposition"}>
                        Exposition
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>



            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="success" disabled={isLoading} onClick={addTv}>
              Add
            </Button>
            <Button color="danger" onClick={Verticalcentermodaltoggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>


        <Row>
          <Col col="12" className=" mb-4">

            <Card>
              <CardBody className="p-2">
                <DataTable striped columns={screenColumns} data={searchedArray}
                  noHeader
                  subHeader
                  subHeaderComponent={
                    <>
                      <Button className="px-2 mr-2" color="success" onClick={Verticalcentermodaltoggle}><Plus size="15px" /> Add Screen</Button>
                      {actionsMemo}
                    </>
                  }
                  pagination paginationPerPage={30} expandableRows
                  expandableRowsComponent={<MyExpander />}
                  highlightOnHover pointerOnHover onRowClicked={handleRowClicked}

                />
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}