import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, TabContent, TabPane } from 'reactstrap'
import { Password, EmailAddress } from '../../constant';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const LoginJwt = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const loginWithJwt = async () => {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ username: email, password: password })
    }
    try {
      var response = await fetch("https://admins.adspot.pub/api/admins/login", request, { mode: 'cors' });
      if (response.status === 200) {
        let data = await response.json();
        // document.cookie = ("jwt=" + data.accesstoken);
        Cookies.set("jwt", data.accesstoken, { expires: 15 });
        window.location.href = `${process.env.PUBLIC_URL}/`
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Container fluid={true}>
      <Row>
        <Col xl="7" className="b-center bg-size" style={{ backgroundImage: `url(${require("../../assets/images/login/2.jpg")})`, backgroundSize: "cover", backgroundPosition: "center", display: "block" }}>
          <img className="bg-img-cover bg-center" style={{ display: "none" }} src={require("../../assets/images/login/2.jpg")} alt="looginpage" />
        </Col>
        <Col xl="5" className="p-0">
          <div className="login-card">
            <div>
              <div>
                <a className="logo text-left" href="#javascript">
                  <img className="img-fluid for-light" src={require("../../assets/images/logo/adspotlogo.png")} alt="looginpage" />
                  <img className="img-fluid for-dark" src={require("../../assets/images/logo/adspotlogo.png")} alt="looginpage" />
                </a>
              </div>
              <div className="login-main login-tab">
                <TabContent className="content-login">
                  <TabPane className="fade show">
                    <Form className="theme-form">
                      <h4>{"Login"}</h4>
                      <p>{"Enter your email & password to login"}</p>
                      <FormGroup>
                        <Label className="col-form-label">{EmailAddress}</Label>
                        <Input className="form-control" type="email" required="" placeholder="Test@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">{Password}</Label>
                        <Input className="form-control" type={"password"} name="login[password]" value={password} onChange={(e) => setPassword(e.target.value)} required="" placeholder="*********" />
                      </FormGroup>
                      <FormGroup className="mb-0">
                        <Button color="primary" className="btn-block my-4" onClick={loginWithJwt}>Login</Button>
                      </FormGroup>
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginJwt;