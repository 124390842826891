import React from 'react'

export default function StatusPill(props) {
  const status = props.status;

  switch (status) {
    case "Accepted":
      return <span className="badge badge-success badge-pill">{status}</span>
    case "Pending":
      return <span className="badge badge-warning badge-pill">{status}</span>
    case "Rejected":
      return <span className="badge badge-secondary badge-pill">{status}</span>
    default:
      return <span className="badge badge_light badge-pill">Unknown status: {status}</span>
  }
}