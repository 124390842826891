import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Button, CardHeader } from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import Cookies from "js-cookie";
import { useHistory, useLocation, useParams,Link } from "react-router-dom";
import { Edit } from 'react-feather'
export default function Contract() {
  const location = useLocation();
  const data = location.state;
  const {
    contract_name,
    video_length,
    max_budjet_ttc,
    total_ttc,
    contract_start_date,
    contract_end_date,
    contract_discount,
    client_name,
    contract_id
  } = data || {};

  const token = Cookies.get("jwt");

console.log(data);
  return (
    <Fragment>
      <Breadcrumb parent="Contracts" hide={true} />
      <Container fluid="true">
        <Row className="justify-content-center mt-4">
          <Col sm="8">
            <Card>
              <CardHeader className="d-flex justify-content-between ">
                <h5>Contract of <span style={{ color: "darkorange" }}> {client_name}</span></h5>
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/contracts/update/contract:${contract_id}`,
                    state: {data },
                  }}
                >
                  <Edit />
                </Link>
              </CardHeader>
              <CardBody className="p-8">
                <p className="mb-2 f-16">
                  <span className="h6">Contract name: </span>
                  <span className="">{contract_name}</span>
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">Start date: </span>
                  {contract_start_date?.slice(0, 10)}
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">End date: </span>
                  {contract_end_date?.slice(0, 10)}
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">Max budjet ttc: </span>
                  {max_budjet_ttc}
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">Total ttc: </span>
                  {total_ttc}
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">Discount: </span>
                  {contract_discount}%
                </p>
                <p className="mb-2 f-16">
                  <span className="h6">Video length: </span>
                  {video_length}
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
