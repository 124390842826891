import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Input, FormGroup, Label, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';

const optionsTimeSlot = [
  { value: '08-10', label: '08-10' },
  { value: '10-12', label: '10-12' },
  { value: '12-14', label: '12-14' },
  { value: '14-16', label: '14-16' },
  { value: '16-18', label: '16-18' },
  { value: '18-20', label: '18-20' },
];
const radioStyle = {
  display: "inline-block",
  position: "static",
  margin: "0 5px 0 0",
  opacity: "initial"
};

export default function AddSpace() {
  const history = useHistory();
  const location = useLocation();
  const data = location.state;
  const {
    client_name,
    client_id,
    contract_id,

  } = data || {};
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("jwt");

  const [addspacemodal, setaddspacemodal] = useState(false);
  const addspacemodalToggle = () => setaddspacemodal(!addspacemodal);
  const [checkmodal, setcheckmodal] = useState(false);
  const checkmodalToggle = () => setcheckmodal(!checkmodal);
  const [filterByBusiness, setFilterByBusiness] = useState("all")

  const handlePackChange = (pack) => {
    setSpaceInputData({ ...spaceinputData, space_pack: pack })
    if (pack === "integral") {
      selectAllfunc(true,null)
      return
    }
    setselectedtvs([])
  }
  const [spaceinputData, setSpaceInputData] = useState({
    space_pack: "",
    space_start_date: "",
    space_end_date: "",
    space_time_slots: [],
    space_video_length: 0,
    space_discount: 0,
  });

  const [tvs, setTvs] = useState([]);
  const [selectedtvs, setselectedtvs] = useState([]);
  const [keyword, setkeyword] = useState("");
  const pattern = new RegExp(keyword, "i");

  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response2 = await fetch(`https://admins.adspot.pub/api/admins/tvs/status`, request, { mode: "cors" });
      if (response2.status === 200) {
        let tvs = await response2.json();
        tvs = tvs.filter((x) => {
          return x.install_status == "installed"
        })
        for (let tv in tvs) {
          tvs[tv].selected = false;
        }
        setTvs(tvs);
      } else {
        toast.error(await response2.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }
  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    fetchData();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log(selectedtvs);
  }, [selectedtvs])

  const tvSelector = (tv) => {
    let array = [...selectedtvs];
    if ((spaceinputData.space_pack == "launch" && array.length == 50) || (spaceinputData.space_pack == "advanced" && array.length == 100)) {
      toast.error("You can't select more! You've reached the maximum.")
      return
    }
    let tvexists = array.filter(obj => obj.tv_id === tv.tv_id)[0] ? true : false;
    if (tvexists) {
      let index = array.indexOf(array.filter(obj => obj.tv_id === tv.tv_id)[0]);
      array.splice(index, 1);
      setselectedtvs(array);
    } else {
      array.push(tv);
      setselectedtvs(array);
    }
  }

  const checkfunc = (tv) => {
    let array = [...selectedtvs];
    return (array.filter(obj => obj.tv_id === tv.tv_id)[0] ? true : false);
  }

  const selectAllfunc = (checked,business) => {

    let array = [...selectedtvs];
    if (spaceinputData.space_pack !=="integral") {   
      if (!checked){
        
        for (let i = 0; i < renderedArray.length; i++) {
          let tv = renderedArray[i];
          let index = array.indexOf(array.filter(obj => obj.tv_id === tv.tv_id)[0]);
          array.splice(index, 1);
        }
        return setselectedtvs(array);
      }
    }else{
      array=[]
      let filteredTvs =tvs.filter((tv)=>{
        return tv.business_type===business
      })
      for (let i = 0; i < filteredTvs.length; i++) {
        let tv = filteredTvs[i];
        array.push(tv);
      }
      return setselectedtvs(array);
    }
   

    for (let i = 0; i < renderedArray.length; i++) {
      let tv = renderedArray[i];
      let tvExists = array.filter(obj => obj.tv_id === tv.tv_id)[0] ? true : false;
      if (!tvExists) {
        array.push(tv);
      }
    }
    setselectedtvs(array);
  }

  const selectedTvsIds = selectedtvs.map((tv) => tv.tv_id);

  const addNewSpace = async () => {
    setIsLoading(true);
    if (spaceinputData.space_start_date == "" || spaceinputData.space_end_date == "" || selectedTvsIds.length == 0) {
      toast.error("You have to fill in all the information !")
      return
    }
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        client_id: client_id,
        space_pack: spaceinputData.space_pack,
        space_start_date: spaceinputData.space_start_date,
        space_end_date: spaceinputData.space_end_date,
        space_time_slots: spaceinputData.space_time_slots,
        space_video_length: spaceinputData.space_video_length,
        space_discount: spaceinputData.space_discount,
        space_tvs_ids: selectedTvsIds,
        contract_id: contract_id
      })
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/space/new`, request, { mode: "cors" });
      if (response.status === 200) {
        toast.success("Space added successfully");
        history.push({
          pathname: `${process.env.PUBLIC_URL}/clients/client:${client_id}`,
          state: { LeftLineTab: "3",
            contract_id
           }
        })

      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  const [availabilitymsg, setAvailabilitymsg] = useState([]);
  const checkAvailability = async () => {

    if ((spaceinputData.space_start_date == "") || (spaceinputData.space_end_date == "") || (spaceinputData.space_time_slots == []) || (spaceinputData.space_video_length == "")) {
      toast.error("Make sure you entred all the information")
      return
    }
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        start_date: spaceinputData.space_start_date,
        end_date: spaceinputData.space_end_date,
        time_slots: spaceinputData.space_time_slots,
        video_length: spaceinputData.space_video_length,
      })
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/availability`, request, { mode: "cors" });
      if (response.status === 200) {
        let message = await response.json();
        setAvailabilitymsg(message);
        checkmodalToggle()
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  const checkFilter = (item) => {
    let business = []

    switch (filterByBusiness) {
      case "Retail":
        business = item.business_type === "Retail"
        break;
      case "Pharma":
        business = item.business_type === "Pharma"
        break;
      case "Exposition":
        business = item.business_type === "Exposition"
        break;
      default:
        business = tvs;
        break;
    }
    return business
  }

  let renderedArray = tvs.filter(checkFilter);

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb hide="true" title="" />
      <Container fluid={true}>
        <Row className='justify-content-center'>
          <Col sm="12" md="10" lg="8">
            <Card>
              <CardHeader className='text-center'>
                <h5 >Add Space For {client_name}</h5>
              </CardHeader>
              <CardBody>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Pack: </Label>
                    <Input type="select" className="form-control" required placeholder="Pack" value={spaceinputData.space_pack} onChange={(e) => { handlePackChange(e.target.value); }}>
                      <option value="" disabled>{"Pack"}</option>
                      <option value={"launch"}>{"Launch"}</option>
                      <option value={"advenced"}>{"Advanced"}</option>
                      <option value={"integral"}>{"Integral"}</option>
                      <option value={"custom"}>{"Custom"}</option>
                    </Input>
                  </FormGroup>
                </Col>
                {spaceinputData.space_pack == "custom" ? <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Time Slots: </Label>
                    <Select
                      isMulti
                      options={optionsTimeSlot}
                      placeholder="Time slot"
                      onChange={(e) => {
                        setSpaceInputData({
                          ...spaceinputData,
                          space_time_slots: (e.map(obj => { return obj.value }))
                        })
                      }}
                    />
                  </FormGroup>
                </Col> : " "
                }
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Space length: </Label>
                    <Input className="form-control" type="number" placeholder="In seconds" value={spaceinputData.space_video_length} onChange={(e) => { setSpaceInputData({ ...spaceinputData, space_video_length: e.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Start Date: </Label>
                    <Input className="form-control" type="date" required placeholder="Start Date" value={spaceinputData.space_start_date} onChange={(e) => { setSpaceInputData({ ...spaceinputData, space_start_date: e.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">End Date: </Label>
                    <Input className="form-control" type="date" required placeholder="End Date" value={spaceinputData.space_end_date} onChange={(e) => { setSpaceInputData({ ...spaceinputData, space_end_date: e.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Discount: </Label>
                    <Input className="form-control" type="number" placeholder="Discount" value={spaceinputData.space_discount} onChange={(e) => { setSpaceInputData({ ...spaceinputData, space_discount: e.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Selected Screens: </Label>
                    <Input className="form-control" type="number" placeholder="Discount" value={selectedtvs.length} disabled />
                  </FormGroup>
                </Col>
                {
                  spaceinputData.space_pack != "" ? spaceinputData.space_pack == 'integral' ? <Col col="12">
                    <Button color="primary" className="btn btn-block" onClick={addspacemodalToggle} >Show Screens</Button>
                  </Col> : <>
                    <Col col="12">
                      <Button color="primary" className="btn btn-block" onClick={addspacemodalToggle} >Add Screens</Button>
                    </Col>
                    <Col col="12" className='mt-4' >
                      <Button color="danger" form='addContactForm' className='btn btn-block' onClick={checkAvailability}>Check Availability</Button>
                    </Col>
                  </> : ""
                }

              </CardBody>
              <CardFooter>
                <Button className='btn btn-block' color='success' form='addContactForm' onClick={addNewSpace} >Add Space</Button>
              </CardFooter>
            </Card>
          </Col>

        </Row>
        <Row>



          <Modal modalClassName='modal modal-dialog-scrollable' isOpen={addspacemodal} toggle={addspacemodalToggle} centered size='lg'>
            <ModalHeader toggle={addspacemodalToggle}>
              Add Screens
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <FormGroup>
                    <Input type="text" placeholder='Search for Screens by Name, Region or Wilaya' value={keyword} onChange={(e) => { setkeyword(e.target.value) }} />
                    <Label className="form-label">Selected Screens: {selectedtvs.length}</Label>
                  </FormGroup>
                </Col>
                <Col sm="6" lg="3" className="input--group">
                        <FormGroup>
                            <Input
                                className="form-control"
                                type="select"
                                value={filterByBusiness}
                                onChange={(e) => {setFilterByBusiness(e.target.value);selectAllfunc(false,e.target.value)}}
                            >
                                <option value="all">All</option>
                                <option value="Pharma">Pharma</option>
                                <option value="Retail">Retail</option>
                                <option value="Exposition">Exposition</option>
                            </Input>
                            <Label className="form-label">Business Type </Label>
                        </FormGroup>
                    </Col>
                <Col md="12">
                  <Table>
                    <thead>
                      <tr>
                        <th>
                          {spaceinputData.space_pack == "custom" ?
                            <>
                              <input
                                style={radioStyle}
                                type="checkbox"
                                onChange={(e) => {
                                  selectAllfunc(e.target.checked,null);
                                }}

                              />
                              select all
                            </>
                            : ""}

                        </th>
                        <th>Contact</th>
                        <th>Region</th>
                        <th>Wilaya</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderedArray.map((tv) => {
                        let shouldRender = pattern.test(tv.contact_region) || pattern.test(tv.contact_lastname) || pattern.test(tv.contact_firstname) || pattern.test(tv.contact_wilaya);
                        // if (shouldRender) {
                        return (shouldRender) ? (
                          <tr key={tvs.indexOf(tv)} >
                            {
                              spaceinputData.space_pack == 'integral' ? <td>
                                <input type="checkbox" checked={checkfunc(tv)} disabled />
                              </td> : <td>
                                <input type="checkbox" checked={checkfunc(tv)} onChange={
                                  (e) => {
                                    tvSelector(tv)
                                  }
                                } />
                              </td>
                            }
                            <td>{tv.contact_lastname} {tv.contact_firstname}</td>
                            <td>{tv.contact_region}</td>
                            <td>{tv.contact_wilaya}</td>
                          </tr>
                        ) : null
                        //}
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={addspacemodalToggle}>Close</Button>
            </ModalFooter>
          </Modal>

        </Row>
        <Row>
          <Col sm={12} md={6}>

            <Modal isOpen={checkmodal} toggle={checkmodalToggle} centered size='lg'>
              <ModalHeader toggle={checkmodalToggle}>
                Available time slots
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col>
                    {availabilitymsg.map((msg) => {
                      return (
                        <p>Time slot: {msg.time_slot} &nbsp;
                          {msg.availabel ? <span className='badge badge-success badge-pill'>available
                          </span>
                            : <><span className='badge badge-danger badge-pill' >Not available</span> <span>Next Available Date: {msg.nearestStartDate}</span></>}
                        </p>
                      )
                    })}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="info" onClick={checkmodalToggle}>Ok</Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
