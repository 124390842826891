
// web pages
import Dashboard from '../web/dashboard/dashboard'
import BroadcastingMain from '../web/broadcasting/BroadcastingMain'
import Broadcast from '../web/broadcasting/Broadcast'
import TVs from '../web/TVs/TVs'
import TV from '../web/TVs/TV'
import Videos from '../web/videos/Videos'
import Invoices from '../web/invoices/Invoices'
import AddInvoice from '../web/invoices/AddInvoice'
import Statistics from "../web/statistics/Statistics";
import ClientDetails from '../web/clients/ClientDetails'
import Clients from '../web/clients/Clients'
import ContactDetails from '../web/contacts/ContactDetails'
import Contacts from '../web/contacts/Contacts'
import CreateContact from '../web/contacts/CreateContact'
import ModifyContact from '../web/contacts/ModifyContact'
import Admins from '../web/admins/Admins'
import BroadcastsPharmacy from '../web/broadcasting/BroadcastsPharmacy'
import BroadcastPharmacy from '../web/broadcasting/BroadcastPharmacy'
import Space from '../web/spaces/Space'
import TextSpace from '../web/textSpaces/TextSpace'
import AddSpace from '../web/spaces/AddSpace'
import AddTextSpace from '../web/textSpaces/AddTextSpace'
import ModifySpace from '../web/spaces/ModifySpace'
import ModifyTextSpace from '../web/textSpaces/ModifyTextSpace'
import BroadcastingDefault from '../web/broadcasting/DefaultBrodcasting'
import DefaultBroadcast from '../web/broadcasting/DefaultBroadcast'
import AddContract from '../web/contracts/addContract'
import ModifyContract from '../web/contracts/modifyContract'
import Contract from '../web/contracts/contract'
import TextBroadcastingMain from '../web/text/ClientsBroadcasts'
import TextBroadcast from '../web/text/ClientBroadcast'
import TextDefaultBroadcasting from '../web/text/DefaultBroadcasts'
import TextDefaultBroadcast from '../web/text/DefaultBroadcast'


export const routes = [
  { path: "/dashboard", Component: Dashboard },
  { path: "/broadcasting/main", Component: BroadcastingMain },
  { path: "/broadcasting/broadcast::slug", Component: Broadcast },
  { path: "/broadcasting/pharmacy", Component: BroadcastsPharmacy },
  { path: "/broadcasting/pharmacy::slug", Component: BroadcastPharmacy },
  { path: "/broadcasting/default", Component: BroadcastingDefault },
  { path: "/broadcasting/default/broadcast::slug", Component: DefaultBroadcast },
  { path: "/broadcasting/text/main", Component: TextBroadcastingMain },
  { path: "/broadcasting/text/broadcast::slug", Component: TextBroadcast },
  // { path: "/text/broadcasting/default", Component: TextDefaultBroadcasting },
  // { path: "/text/broadcasting/default/broadcast::slug", Component: TextDefaultBroadcast },
  { path: "/clients/all", Component: Clients },
  { path: "/clients/client::slug", Component: ClientDetails },
  { path: "/contacts/CreateContact", Component: CreateContact },
  { path: "/contacts/all", Component: Contacts },
  { path: "/contacts/contact::slug", Component: ContactDetails },
  { path: "/contacts/modify/contact::slug", Component: ModifyContact },
  { path: "/spaces/space::slug", Component: Space },
  { path: "/spaces/Add", Component: AddSpace },
  { path: "/spaces/modify::slug", Component: ModifySpace },
  { path: "/textspaces/Add", Component: AddTextSpace },
  { path: "/textspaces/space::slug", Component: TextSpace },
  { path: "/textspaces/modify::slug", Component: ModifyTextSpace },
  { path: "/screens/main", Component: TVs },
  { path: "/screens/screen::slug", Component: TV },
  { path: "/videos/all", Component: Videos },
  { path: "/invoices/main", Component: Invoices },
  { path: "/invoices/add", Component: AddInvoice },
  {path: "/statistics", Component:Statistics},
  { path: "/admins", Component: Admins },
  { path: "/contracts/add", Component: AddContract },
  { path: "/contracts/contract::slug", Component: Contract },
  { path: "/contracts/update/contract::slug", Component: ModifyContract },
]