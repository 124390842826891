import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Input, FormGroup, Label, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useLocation, useParams } from 'react-router-dom';
import Select from 'react-select';

const optionsTimeSlot = [
  { value: '08-10', label: '08-10' },
  { value: '10-12', label: '10-12' },
  { value: '12-14', label: '12-14' },
  { value: '14-16', label: '14-16' },
  { value: '16-18', label: '16-18' },
  { value: '18-20', label: '18-20' },
];
const radioStyle = {
  display: "inline-block",
  position: "static",
  margin: "0 5px 0 0",
  opacity: "initial"
};

export default function ModifySpace() {
  const location = useLocation();
  const { client_id, space_id, client_name, space_time_slots, space_discount, space_start_date, space_end_date, space_video_length, space_tvs_ids } = location.state || {};
  const defaultOptions = space_time_slots?.map((item) => ({ value: item, label: item }));
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("jwt");

  const [addspacemodal, setaddspacemodal] = useState(false);
  const addspacemodalToggle = () => setaddspacemodal(!addspacemodal);
  const [checkmodal, setcheckmodal] = useState(false);
  const checkmodalToggle = () => setcheckmodal(!checkmodal);


  const [spaceinputData, setSpaceInputData] = useState({
    space_start_date: space_start_date,
    space_end_date: space_end_date,
    space_time_slots: space_time_slots,
    space_video_length: space_video_length,
    space_discount: space_discount,
  });

  const [tvs, setTvs] = useState([]);
  const [selectedtvs, setselectedtvs] = useState(Array.isArray(space_tvs_ids) ? space_tvs_ids : []);
  const [keyword, setkeyword] = useState("");
  const pattern = new RegExp(keyword, "i");

  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response2 = await fetch(`https://admins.adspot.pub/api/admins/tvs/status`, request, { mode: "cors" });
      if (response2.status === 200) {
        let tvs = await response2.json();
        tvs= tvs.filter((x) => {
          return x.install_status == "installed"
        })
        for (let tv in tvs) {
          tvs[tv].selected = false;
        }
        setTvs(tvs);
      } else {
        toast.error(await response2.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }
  useEffect(() => {

    let unmount = false;
    if (unmount) {
      return;
    }
    fetchData();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const tvSelector = (tv) => {
    let array = [...selectedtvs];
    let tvexists = array.filter(str => (str === tv.tv_id))[0] ? true : false;
    if (tvexists) {
      let index = array.indexOf(array.filter(obj => obj === tv.tv_id)[0]);
      array.splice(index, 1);
      setselectedtvs(array);
    } else {
      array.push(tv.tv_id);
      setselectedtvs(array);
    }
  }

  const checkfunc = (tv) => {
    let array = [...selectedtvs];
    return (array.filter(obj => obj === tv.tv_id)[0] ? true : false);
  }

  const selectAllfunc = (checked) => {
    let pattern = new RegExp(keyword, "i");
    let filteredArray = tvs.filter(function (tv) {
      return pattern.test(tv.contact_region) || pattern.test(tv.contact_lastname) || pattern.test(tv.contact_firstname) || pattern.test(tv.contact_wilaya);
    });
    let array = [...selectedtvs];

    if (!checked) {
      for (let i = 0; i < filteredArray.length; i++) {
        let tv = filteredArray[i];
        let index = array.indexOf(array.filter(obj => obj === tv.tv_id)[0]);
        array.splice(index, 1);
      }
      return setselectedtvs(array);
    }

    for (let i = 0; i < filteredArray.length; i++) {
      let tv = filteredArray[i];
      let tvExists = array.filter(obj => obj === tv.tv_id)[0] ? true : false;
      if (!tvExists) {
        array.push(tv.tv_id);
      }
    }
    setselectedtvs(array);
  }



  const modifySpace = async () => {
    setIsLoading(true);
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        client_id: client_id,
        space_id: slug,
        space_start_date: spaceinputData.space_start_date,
        space_end_date: spaceinputData.space_end_date,
        space_time_slots: spaceinputData.space_time_slots,
        space_video_length: spaceinputData.space_video_length,
        space_discount: spaceinputData.space_discount,
        space_tvs_ids: selectedtvs
      })
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/space/update`, request, { mode: "cors" });
      if (response.status === 200) {
        toast.success("Space modified successfully");
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  const [availabilitymsg, setAvailabilitymsg] = useState([]);
  const checkAvailability = async () => {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        start_date: spaceinputData.space_start_date,
        end_date: spaceinputData.space_end_date,
        time_slots: spaceinputData.space_time_slots,
        video_length: spaceinputData.space_video_length,
      })
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/availability`, request, { mode: "cors" });
      if (response.status === 200) {
        let message = await response.json();
        setAvailabilitymsg(message);
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb hide="true" title={"Modify space for : " + client_name} />
      <Container fluid={true}>
        {/* <Row>
          <Col>
            <h5 style={{ color: "darkorange" }} className={"mb-3"}>{client_name}</h5>
          </Col>
        </Row> */}
        <Row>
          <Col md="12"  className='input--group'>
            <FormGroup>
              <Label className="form-label">Time Slots: </Label>
              <Select
                defaultValue={defaultOptions}
                isMulti
                options={optionsTimeSlot}
                placeholder="Time slot"
                onChange={(e) => {
                  setSpaceInputData({
                    ...spaceinputData,
                    space_time_slots: (e.map(obj => { return obj.value }))
                  })
                }}
              />
            </FormGroup>
          </Col>
         
          <Col md="4" lg="6" className='input--group'>
            <FormGroup>
              <Label className="form-label">Start Date: </Label>
              <Input className="form-control" type="date" placeholder="Start Date" value={space_start_date?.slice(0, 10)} onChange={(e) => { setSpaceInputData({ ...spaceinputData, space_start_date: e.target.value }) }} />
            </FormGroup>
          </Col>
          <Col md="4" lg="6" className='input--group'>
            <FormGroup>
              <Label className="form-label">End Date: </Label>
              <Input className="form-control" type="date" placeholder="End Date" value={space_end_date?.slice(0, 10)} onChange={(e) => { setSpaceInputData({ ...spaceinputData, space_end_date: e.target.value }) }} />
            </FormGroup>
          </Col>
          <Col md="4" lg="6" className='input--group'>
            <FormGroup>
              <Label className="form-label">Space length: </Label>
              <Input className="form-control" type="number" placeholder="In seconds" value={spaceinputData.space_video_length} onChange={(e) => { setSpaceInputData({ ...spaceinputData, space_video_length: e.target.value }) }} />
            </FormGroup>
          </Col>
          <Col md="4" lg="6" className='input--group'>
            <FormGroup>
              <Label className="form-label">Discount: </Label>
              <Input className="form-control" type="number" placeholder="Discount" value={space_discount} onChange={(e) => { setSpaceInputData({ ...spaceinputData, space_discount: e.target.value }) }} />
            </FormGroup>
          </Col>
          <Col md="12" lg="12" className='input--group'>
            <Button color="warning" onClick={addspacemodalToggle}>Add Screens</Button>
            <p>Selected Screens: {selectedtvs && selectedtvs.length}</p>
            <Modal isOpen={addspacemodal} toggle={addspacemodalToggle} centered size='lg'>
              <ModalHeader toggle={addspacemodalToggle}>
                Add Screens
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Input type="text" placeholder='Search for screens by Name, Region or Wilaya' value={keyword} onChange={(e) => { setkeyword(e.target.value) }} />
                      <Label className="form-label">Selected Screens: {selectedtvs && selectedtvs.length}</Label>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            <input
                              style={radioStyle}
                              type="checkbox"
                              onChange={(e) => {
                                selectAllfunc(e.target.checked);
                              }}
                            />
                            select all
                            {/* <Button onClick={selectAllfunc}>
                      Select All
                    </Button> */}
                          </th>
                          <th>Contact</th>
                          <th>Region</th>
                          <th>Wilaya</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tvs.map((tv) => {
                          let shouldRender = pattern.test(tv.contact_region) || pattern.test(tv.contact_lastname) || pattern.test(tv.contact_firstname) || pattern.test(tv.contact_wilaya);
                          // if (shouldRender) {
                          return (shouldRender) ? (
                            <tr key={tvs.indexOf(tv)} >
                              <td>
                                <input type="checkbox" checked={checkfunc(tv)} onChange={
                                  (e) => {
                                    tvSelector(tv)
                                  }
                                } />
                              </td>
                              <td>{tv.contact_lastname} {tv.contact_firstname}</td>
                              <td>{tv.contact_region}</td>
                              <td>{tv.contact_wilaya}</td>
                            </tr>
                          ) : null
                          //}
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={addspacemodalToggle}>Close</Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='justify-content-center my-3' style={{ display: "flex" }}>
            <Button color='success' form='addContactForm' onClick={modifySpace} >Modify space</Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
