import React, { Fragment, useState, useEffect } from "react";
import { Settings, Flag, Edit, MoreVertical } from 'react-feather'
import Loader from "../../layout/loader";
import { useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  FormGroup,
  CardHeader,
  Nav,
  NavItem, NavLink,
  Table, TabContent, TabPane,
  Dropdown, DropdownMenu, DropdownItem,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import moment from "moment";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export default function TV() {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [fitters, setFitters] = useState([]);
  const [contacts, setContacts] = useState([])
  const token = Cookies.get("jwt");
  const [activeTab2, setActiveTab2] = useState("1");
  const [data, setData] = useState({});
  // const [activeTab2, setActiveTab2] = useState((typeof location.state.activeTab2 != "undefined") ? location.state.activeTab2 : "2");
  const {
    contact_id,
    contact_sexe,
    contact_lastname,
    contact_firstname,
    contact_establishment,
    contact_age,
    contact_phone,
    contact_email,
    contact_region,
    contact_wilaya,
    contact_city,
    contact_address,
    contract_start_date,
    contract_end_date,
    install_id,
    tv_id,
    install_status,
    status_date,
    fitter_id,
    tv_comments,
    tv_serial_number,
    tv_type,
    tv_position,
    tv_internet_connection,
    tv_login_id,
    tv_size,
    tv_resolution,
    tv_modem_phone,
    business_type,
    tv_name
  } = data || {};



  const [installation_status, setInstallationStatus] = useState([])
  const lastStatus = installation_status.length != 0 ? installation_status[0] : {}

  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/tvs/tv?tv_id=${slug}`,
        request,
        { mode: "cors" }
      );
      if (response.status === 200) {
        let data = await response.json();
        setData(data);
        setInstallationStatus(data.installation_status)
        setInputData({
          ...inputData,
          tv_serial_number: data.tv_serial_number,
          tv_type: data.tv_type,
          tv_position: data.tv_position,
          tv_internet_connection: data.tv_internet_connection,
          tv_modem_phone: data.tv_modem_phone,
          tv_size: data.tv_size,
          tv_resolution: data.tv_resolution,
          tv_id: data.tv_id,
          contact_id: data.contact_id,
          business_type: data.business_type
        });
      } else {
        toast.error(await response.json());
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getFitters = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/fitter`,
        request,
        { mode: "cors" }
      );
      let data = await response.json();
      if (response.status === 200) {

        setFitters(data.data);

      } else {
        toast.error(data.success);
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [modifyModal, setModifyModal] = useState(false);
  const modifyModalToggle = () => setModifyModal(!modifyModal);

  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const changeStatusModalToggle = () => setChangeStatusModal(!changeStatusModal);

  const [assignFitterModal, setAssignFitterModal] = useState(false);
  const assignFitterModalToggle = () => setAssignFitterModal(!assignFitterModal);

  const [inputData, setInputData] = useState({
    tv_serial_number: tv_serial_number,
    tv_type: tv_type,
    tv_position: tv_position,
    tv_internet_connection: tv_internet_connection,
    tv_modem_phone: tv_modem_phone,
    tv_size: tv_size,
    tv_resolution: tv_resolution,
    tv_id: tv_id,
    contact_id: contact_id,
    business_type: business_type
  });


  const [changeData, setChangeData] = useState({
    tvId: slug,
    status: install_status ?? "",
    fitter_id: fitter_id ?? "",
    status_date: moment(),
    comments: tv_comments ?? "",
  });

  const [assignData, setAssignData] = useState({
    installId: install_id,
    fitter_id: fitter_id,
  });

  useEffect(() => {
    fetchData()
    getFitters();
    getContacts();
    return () => {
      setIsLoading(false);
      window.scrollTo(0, 0);
    };
  }, []);

  const modifyTv = async () => {
    setIsLoading(true);
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ contact_id, ...inputData }),
    };

    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/tvs/update`,
        request,
        { mode: "cors" }
      );
      if (response.status === 200) {
        toast.success("Screen updated Successfully");
        fetchData()
        setIsLoading(false);
      } else {
        toast.error("Error !");
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    modifyModalToggle();
  };

  const statusPill = (status) => {
    switch (status) {
      case "added":
        return <span className="badge badge-dark badge-pill">
          {status}</span>
      case "to prepare":
        return <span className="badge badge-info badge-pill">
          {status}</span>
      case "ready to install":
        return <span className="badge badge-primary badge-pill">
          {status}</span>
      case "to install":
        return <span className="badge badge-warning badge-pill">
          {status}</span>
      case "installed":
        return <span className="badge badge-success badge-pill">
          {status}</span>
      default:

        return <span className="badge badge-danger badge-pill">
          {status}</span>
    }
  }

  const changeStatus = async () => {
    setIsLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ ...changeData }),
    };


    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/tvs/changestatus`,
        request,
        { mode: "cors" }
      );
      let data = await response.json()
      if (response.status === 200) {
        toast.success(data.success);
        fetchData()
        // window.location.reload();
        setIsLoading(false);
      } else {
        toast.error(data);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    changeStatusModalToggle();
  };

  const assignFitter = async () => {
    setIsLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ ...assignData }),
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/tvs/asign`,
        request,
        { mode: "cors" }
      );
      let data = await response.json()
      if (response.status === 200) {
        toast.success(data.success);
        setIsLoading(false);
      } else {
        toast.error(data.message);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    assignFitterModalToggle();
  };

  const getContacts = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/contact`,
        request,
        { mode: "cors" }
      );
      let data = await response.json();
      if (response.status === 200) {
        setContacts(data.sort((a, b) => a.contact_lastname.localeCompare(b.contact_lastname)));
      } else {
        toast.error(data);
      }
      setIsLoading(false);

    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleChange = (contactId) => {
    const c = contacts.filter((cont) => {
      return cont.id_contact == contactId
    })
    // setContact(c[0])

    setInputData({
      ...inputData,
      contact_id: c[0].id_contact,
    });
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Screens" title="Screen Details" hide={true} />
      <Container fluid="true">
        <Row>
          <Col>
            <Modal
              isOpen={modifyModal}
              toggle={modifyModalToggle}
              centered
              size="lg"
            >
              <ModalHeader toggle={modifyModalToggle}>Modify Screen</ModalHeader>
              <ModalBody>
                <form action="">
                  <Row>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Screen Serial N°:{" "}</Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Serial Number"
                          value={inputData.tv_serial_number}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              tv_serial_number: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12" className='input--group'>
                      <FormGroup>
                        <Label className="form-label" >Pharmacy name: </Label>
                        <Input type="select" className="form-control" placeholder="Type" value={inputData.contact_id || " "}
                          onChange={(e) => { handleChange(e.target.value) }}>
                          <option value={""} disabled>{"Select Pharmacy"}</option>
                          {contacts.map((cont) => {
                            return <option key={cont.id_contact} value={cont.id_contact}>{cont.contact_lastname} {" "}{cont.contact_firstname}</option>
                          })}

                        </Input>

                      </FormGroup>
                    </Col>

                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Screen type: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          placeholder="Screen Type"
                          value={inputData.tv_type}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              tv_type: e.target.value,
                            });
                          }}
                        >
                          <option value="landscape">Landscape</option>
                          <option value="portrait">Portrait</option>
                        </Input>

                      </FormGroup>
                    </Col>

                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Screen position: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          placeholder="TV position"

                          value={inputData.tv_position}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              tv_position: e.target.value,
                            });
                          }}
                        >
                          <option value="wall">Wall</option>
                          <option value="roof">Roof</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">
                          internet connection:{" "}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="TV internet connection"
                          value={inputData.tv_internet_connection}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              tv_internet_connection: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">
                          Modem Phone:{" "}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Modem Phone"
                          value={inputData.tv_modem_phone}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              tv_modem_phone: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Screen Size: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          placeholder="Screen Size"

                          value={inputData.tv_size}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              tv_size: e.target.value,
                            });
                          }}
                        >
                          <option value="" disabled>Size</option>
                          <option value="32">32"</option>
                          <option value="40">40"</option>
                          <option value="43">43"</option>
                          <option value="50">50"</option>
                          <option value="55">55"</option>
                          <option value="60">60"</option>
                          <option value="65">65"</option>
                          <option value="70">70"</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    
                    <Col md="12" className="input--group">
                    <FormGroup>
                    <Label className="form-label">Screen Resolution: </Label>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="Screen Resolution"

                      value={inputData.tv_resolution}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          tv_resolution: e.target.value,
                        });
                      }}
                    >
                      <option value="HD">HD</option>
                      <option value="FHD">FHD"</option>
                      <option value="4K">4K</option>
                    </Input>
                  </FormGroup>
                    </Col>
                    <Col md="12" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Business type:  </Label>
                        <Input
                          name="select"
                          type="select"
                          onChange={(e) => { setInputData({ ...inputData, business_type: e.target.value }) }}
                        >
                          <option value={"Pharma"}>
                            Pharma
                          </option>
                          <option value={"Retail"}>
                            Retail
                          </option>
                          <option value={"Exposition"}>
                            Exposition
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="success" disabled={isLoading} onClick={modifyTv}>
                  Confirm
                </Button>
                <Button color="danger" onClick={modifyModalToggle}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={changeStatusModal}
              toggle={changeStatusModalToggle}
              centered
              size="lg"
            >
              <ModalHeader toggle={changeStatusModalToggle}>Change Status TV</ModalHeader>
              <ModalBody>
                <form action="">
                  <Row>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">
                          Date:
                        </Label>
                        <Input
                          className="form-control"
                          type="date"
                          placeholder="Predicted Date"
                          value={changeData.status_date}
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              status_date: e.target.value,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Status: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          placeholder="Status"
                          value={changeData.status}
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              status: e.target.value,
                            });
                          }}
                        >
                          <option value=" " disabled>
                            Status
                          </option>
                          <option value="added">Added</option>
                          <option value="to prepare">To prepare</option>
                          <option value="ready to install">Ready To Install</option>
                          <option value="to install">To install</option>
                          <option value="installed">Installed</option>
                          <option value="not installed">Not Installed</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Fitter: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          value={changeData.fitter_id}
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              fitter_id: e.target.value,
                            });
                          }}
                        >
                          <option value=" " disabled>
                            Fitter
                          </option>
                          {fitters?.map((fit) => {
                            return <option key={fit.fitter_id} value={fit.fitter_id}>{fit.admin_last_name} {fit.admin_first_name}</option>
                          })}

                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">
                          Comments:
                        </Label>
                        <textarea className="form-control" rows="5" cols="5" value={changeData.comments} style={{ resize: "none" }}
                          onChange={(e) => {
                            setChangeData({
                              ...changeData,
                              comments: e.target.value,
                            });
                          }} placeholder="Comments"></textarea>
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="success" disabled={isLoading} onClick={changeStatus} >
                  Confirm
                </Button>
                <Button color="danger" onClick={changeStatusModalToggle}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={assignFitterModal}
              toggle={assignFitterModalToggle}
              centered
              size="md"
            >
              <ModalHeader toggle={assignFitterModalToggle}>Assign Screen To Fitter</ModalHeader>
              <ModalBody>
                <form action="">
                  <Row>
                    <Col md="12" className="input--group">
                      <FormGroup>
                        <Label className="form-label">Fitter: </Label>
                        <Input
                          className="form-control"
                          type="select"
                          value={assignData.fitter_id}
                          onChange={(e) => {
                            setAssignData({
                              ...assignData,
                              fitter_id: e.target.value,
                            });
                          }}
                        >
                          <option value=" " disabled>
                            Fitter
                          </option>
                          {fitters?.map((fit) => {
                            return <option key={fit.fitter_id} value={fit.fitter_id}>{fit.fitter_lastname} {fit.fitter_firstname}</option>
                          })}

                        </Input>
                      </FormGroup>
                    </Col>

                  </Row>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="success" disabled={isLoading} onClick={assignFitter} >
                  Confirm
                </Button>
                <Button color="danger" onClick={assignFitterModalToggle}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>

          </Col>
        </Row>
        <Row>
          <Col md="12 box-col-12" >
            <Card>
              <CardHeader>
                <h5>{tv_name ?  "Screen Name:"+tv_name : "SN°: "+tv_serial_number}</h5>
              </CardHeader>
              <CardBody className="tabbed-card">
                <Nav tabs className="border-tab">
                  <NavItem>
                    <NavLink className={activeTab2 === '1' ? 'active' : ''} onClick={() => setActiveTab2('1')}>
                      Screen
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab2 === '2' ? 'active' : ''} onClick={() => setActiveTab2('2')}>
                      Contact
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={activeTab2 === '3' ? 'active' : ''} onClick={() => setActiveTab2('3')}>
                      Timeline
                    </NavLink>
                  </NavItem>
                  <div className="dropdown-basic">
                    <Dropdown className="dropdown">
                      <span className="text-dark"><MoreVertical className="dropbtnprimary color-dark" /></span>
                      <DropdownMenu className="dropdown-content" right>
                        {install_status == "ready to install" ? <DropdownItem href="#" onClick={assignFitterModalToggle}><Settings size="14" /> Assign Fitter</DropdownItem> : ""}
                        <DropdownItem href="#" onClick={changeStatusModalToggle}><Flag size="14" /> Change Status</DropdownItem>
                        <DropdownItem href="#" onClick={modifyModalToggle}><Edit size="14" /> Modify Info</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Nav>

                <TabContent activeTab={activeTab2}>
                  <TabPane tabId="1">
                    <Col md={12}>
                      <Table className="table-borderless ">

                        <tbody>

                          <tr>
                            <th>Serial Number</th>
                            <td>{tv_serial_number}</td>
                          </tr>
                          <tr>
                            <th>Login ID</th>
                            <td>{tv_login_id}</td>
                          </tr>
                          <tr>
                            <th>Screen Type</th>
                            <td>{tv_type}</td>
                          </tr>
                          <tr>
                            <th>Status</th>
                            <td>{statusPill(lastStatus.install_status)}</td>
                          </tr>
                          <tr>
                            <th>Status Date</th>
                            <td>{moment(status_date).format("DD-MM-YYYY")}</td>
                          </tr>
                          <tr>
                            <th>Comments</th>
                            <td>{lastStatus.tv_comments ? lastStatus.tv_comments : "No Comment"}</td>
                          </tr>
                          <tr>
                            <th>Fitter</th>
                            <td>{lastStatus.admin_last_name ? lastStatus?.admin_last_name + " " + lastStatus?.admin_first_name : "No Fitter"} </td>
                          </tr>
                          <tr>
                            <th>Screen Position</th>
                            <td>{tv_position}</td>
                          </tr>
                          <tr>
                            <th>Internet Connection</th>
                            <td>{tv_internet_connection}</td>
                          </tr>
                          <tr>
                            <th>Screen Size</th>
                            <td>{tv_size}</td>
                          </tr>
                          <tr>
                            <th>Screen Resolution</th>
                            <td>{tv_resolution}</td>
                          </tr>
                          <tr>
                            <th>Modem Phone Number</th>
                            <td>{tv_modem_phone}</td>
                          </tr>
                          <tr>
                            <th>Business Type</th>
                            <td>{business_type}</td>
                          </tr>
                        </tbody>

                      </Table>

                    </Col>
                  </TabPane>
                  <TabPane tabId="2">
                    <Col md={12}>
                      <Table className="table-borderless ">
                        <tbody>
                          <tr>
                            <th>Full Name</th>
                            <td>{contact_sexe === "male" ? "Mr " : "Mrs "}{contact_lastname} {contact_firstname}</td>
                          </tr>
                          <tr>
                            <th>Pharmacy Name</th>
                            <td>{contact_establishment}</td>
                          </tr>
                          <tr>
                            <th>Age</th>
                            <td>{contact_age}</td>
                          </tr>

                          <tr>
                            <th>Phone</th>
                            <td>{contact_phone}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{contact_email}</td>
                          </tr>
                          <tr>
                            <th>Region, Wilaya</th>
                            <td>{contact_region}, {contact_wilaya}</td>
                          </tr>
                          <tr>
                            <th>City, Address</th>
                            <td>{contact_city}, {contact_address}</td>
                          </tr>

                          <tr>
                            <th>Contract Starting Date</th>
                            <td>{moment(contract_start_date).format("DD-MM-YYYY")}</td>
                          </tr>
                          <tr>
                            <th>Contract Ending Date</th>
                            <td>{moment(contract_end_date).format("DD-MM-YYYY")}</td>
                          </tr>

                        </tbody>

                      </Table>
                    </Col>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row className='justify-content-center' >
                      <div className="box-col-12 col-lg-10 col-md-6">
                        <div className="card">
                          <div className="card-no-border card-header">
                            <h5>Installation's Timeline</h5>
                          </div>
                          <div className="new-update card-body">
                            <div className="activity-timeline ">
                              {installation_status.map((status) => {
                                return <div className="media " style={{ paddingBottom: "100px" }} key={status.install_id}>
                                  <div className="activity-line "></div>
                                  <div className="activity-dot-primary"></div>
                                  <div className="media-body">
                                    <span>{status.install_status}</span>
                                    <p className="font-roboto">{status.status_date}</p>
                                    <p className="font-roboto">{status.tv_comments}</p>
                                  </div>
                                </div>
                              })}
                            </div>
                          </div>
                        </div>
                      </div >

                    </Row >

                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>


        </Row >

      </Container >
    </Fragment >
  );
}
