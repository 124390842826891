import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import moment from 'moment';
import StatusPill from "../components/StatusPill"

export default function Broadcast() {
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);
  const [validationParams, setValidationParams] = useState(false);
  const [status, setStatus] = useState("");
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ diffusion_range: [], diffusion_regions: [], passage: [] });
  const [videoLink, setVideoLink] = useState("");
  const token = Cookies.get("jwt");
  const history = useHistory();
  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/diffusion/pharmacy?diffusion_id=${slug}`, request, { mode: "cors" });
      if (response.status === 200) {
        let data = await response.json();
        setData(data);
        setVideoLink(data.video_link);
        setIsLoading(false);
      } else {
        toast.error(await response.json());
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    fetchData();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const validateVideo = async () => {
    if(validationParams ===false) {
      toast.error("you need to check verification first !")
      return
    }
    setIsLoading(true);
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        diffusion_id: slug,
        status: status,
      }),
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/diffusion/pharmacy/validate`, request, { mode: "cors" });
      if (response.status === 200) {
        // window.location.reload();
        history.go(0)
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Broadcast" title="Pharmacy Broadcast" hide={true} />
      <Container fluid="true">
        <Row>
          <Col md={4}>
            <Card>
              <CardBody>
                <h4 className='mb-3'><span className='client--name__header'>{data.contact_lastname} {data.contact_firstname}</span></h4>
                <hr />
                <p className='mb-2 f-16'>Phone: <span className='f-right client--phone'>{data.contact_phone}</span></p>
                <p className='mb-2 f-16'>Email: <span className='f-right client--email'>{data.contact_email}</span></p>
                <hr />
                <h5 className='mb-3'>Broadcast:</h5>
                <p className='mb-1 f-16'>Created at: <span className='f-right'>{moment(data.created_at, moment.ISO_8601).format("DD/MM/YYYY HH:mm")}</span></p>
                <p className='mb-1 f-16'>Start Date: <span className='f-right'>{moment(data.diffusion_start_date).format("DD/MM/YYYY HH:mm")}</span></p>
                <p className='mb-1 f-16'>End Date: <span className='f-right'>{moment(data.diffusion_end_date).format("DD/MM/YYYY HH:mm")}</span></p>
                <p className='mb-1 f-16'>Broadcast Duration: {moment(data.diffusion_end_date).diff(data.diffusion_start_date, "days")} days</p>
                <p className='mb-1 f-16'>Range: <span className='f-right'>{
                  data.diffusion_range.map((range) => {
                    return (
                      <span key={data.diffusion_range.indexOf(range)} className="badge badge-light">{range}</span>
                    )
                  })}</span>
                </p>
                <p className='mb-1 f-16'>Number of Screens: <span className="f-right">{data.tvs_count}</span></p>
                <p className='mb-1 f-16'>Status: <span className="f-right">
                  {moment(data.diffusion_end_date).isBefore(new Date()) && <span className="badge badge-danger badge-pill">EXPIRED!</span>}
                  <StatusPill status={data.diffusion_status} />
                </span></p>
                <hr />
                <h4>Total Broadcasts: <span className="f-right">{data.passage.reduce((prev, obj) => prev + (+obj.total), 0)}</span></h4>
                {data.passage.map((p) => {
                  return (
                    <p key={data.passage.indexOf(p)} className="f-16 mb-1">
                      Diffusion range <span className="badge badge-light">{p.diffusion_range}</span> : {p.total}
                    </p>
                  )
                })}
              </CardBody>
            </Card>
          </Col>
          <Col md={8}>
            <Card>
              <CardBody>
                <h4>Video</h4>
                <hr />
                <p className="f-16">Video name: <span className='f-right'>"{data.video_name}"</span></p>
                <p className="f-16">Product name: <span className='f-right'>{data.video_product_name}</span></p>
                <p className="f-16">Product description:</p>
                <p><i>{data.video_product_description}</i></p>
                {videoLink && <video id={data.video_name} controls width="100%">
                  <source src={videoLink} type="video/mp4" />
                  Your browser does not support the video tag. I suggest you upgrade your browser.
                </video>}
                <div className="d-flex justify-content-center">
                {data.diffusion_status === "Pending" ?? <>
                    <Button color='success' className='mx-3 mt-3' onClick={() => { Verticalcentermodaltoggle(); setStatus("Accepted") }}>Accept</Button>
                    <Button color='danger' className='mx-3 mt-3' onClick={() => { Verticalcentermodaltoggle(); setStatus("Rejected") }}>Reject</Button>
                  </>}
                  
                  <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered>
                    <ModalHeader toggle={Verticalcentermodaltoggle}>
                      Validation
                    </ModalHeader>
                    <ModalBody className='pb-0 mb-0'>
                      <ul style={{listStyleType:"disc", margin:"20px"}}>
                        <li>
                        this video doesn't contain <u>sexual</u> scenes
                        </li>
                        <li>
                        this video doesn't contain scenes with <u>alcohol</u>
                        </li>
                        <li>
                        this video doesn't contain <u>violence</u> scenes
                        </li>
                      </ul>
                      <div >
                        <input className='m-1' type="checkbox" name='valid' id="valid" checked={validationParams} onChange={() => {setValidationParams( !validationParams ) }} />
                        <label htmlFor="valid">this video is verified</label>
                      </div>
                     
                    </ModalBody>
                    <ModalFooter>
                      <Button color="success" onClick={() => { Verticalcentermodaltoggle(); validateVideo() }}>Validate</Button>
                      <Button color="danger" onClick={Verticalcentermodaltoggle}>Close</Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
