import { Home, Users,List, Monitor, Radio, File, Settings, Edit,BarChart2,Video } from 'react-feather'
export const MENUITEMS = [
  {
    menutitle: "",
    menucontent: "",
    Items: [
      { path: `${process.env.PUBLIC_URL}/dashboard`, icon: Home, title: 'Dashboard', type: 'link' },
      {
        title: 'Video Broadcasting', icon: Radio, type: 'sub', active: false, children: [
          { path: `${process.env.PUBLIC_URL}/broadcasting/main`, title: 'Client Broadcasts', type: 'link' },
          // { path: `${process.env.PUBLIC_URL}/broadcasting/pharmacy`, title: 'Pharmacy Broadcasts', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/broadcasting/default`, title: 'Default Broadcasts', type: 'link' },
        ]
      },
      {
        title: 'Text Broadcasting', icon: Edit, type: 'sub', active: false, children: [
          { path: `${process.env.PUBLIC_URL}/broadcasting/text/main`, title: 'Client Text Broadcasts', type: 'link' },
          // { path: `${process.env.PUBLIC_URL}/broadcasting/text/default`, title: 'Default Text Broadcasts', type: 'link' },
        ]
      },
      { path: `${process.env.PUBLIC_URL}/screens/main`, icon: Monitor, title: 'Screens', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/clients/all`, icon: Users, title: 'Clients', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/contacts/all`, icon: List, title: 'Contacts', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/videos/all`, icon: Video, title: 'Videos', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/invoices/main`, icon: File, title: 'Invoices', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/statistics`, icon: BarChart2, title: 'Statistics', type: 'link' },
      { path: `${process.env.PUBLIC_URL}/admins`, icon: Settings, title: 'Users', type: 'link' },
    ]
  },
]