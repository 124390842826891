import React, { Fragment, useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store'
import App from './components/app'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { routes } from './route';

import Error404 from "./pages/errors/error404"

import Cookies from 'js-cookie';
import LoginJwt from './web/login/LoginJwt'


const Root = () => {
  const [authorized, setAuthorized] = useState(true);

  const token = Cookies.get("jwt");
  const verifyUser = async () => {
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token
      }
    }
    try {
      let response = await fetch("https://admins.adspot.pub/api/admins/verify", request, { mode: "cors" });
      if (response.status === 200) {
        const data = await response.json()
        setAuthorized(true);
      }
      else if (response.status === 401) {
        setAuthorized(false)
      return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
      } 
      else {
        setAuthorized(false);
      }
    } catch (err) {
      console.error(err.message);
    }
  }
  useEffect(() => {
    verifyUser();
    return () => {
      window.scrollTo(0, 0);
    }
  }, [token]);

  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter basename={`/`}>
          <Switch>
            <Route path={`${process.env.PUBLIC_URL}/login`} component={LoginJwt} />

            {authorized ?

              <App>
                <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                  return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
                }} />
                <TransitionGroup>
                  {routes.map(({ path, Component }) => (
                    <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                      {({ match }) => (
                        <CSSTransition
                          in={match != null}
                          timeout={100}
                          classNames={""}
                          unmountOnExit>
                          <div><Component /></div>
                        </CSSTransition>
                      )}
                    </Route>
                  ))}
                </TransitionGroup>
              </App>
              :
              <Redirect to={`${process.env.PUBLIC_URL}/login`} />
            }
            <Route component={Error404}></Route>

          </Switch>
        </BrowserRouter>
      </Provider>
    </Fragment>
  )
}

ReactDOM.render(<Root />,
  document.getElementById('root')
);
serviceWorker.unregister();
