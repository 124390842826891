import React, { Fragment, useEffect, useState, useRef } from 'react'
import Loader from '../../layout/loader';
import { Container, Row, Col, Input, FormGroup, Label, Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../layout/breadcrumb';
import Cookies from 'js-cookie';

// import { PDFViewer } from '@react-pdf/renderer';
import { PDFInvoice } from '../assets/PDFInvoice';
import { useReactToPrint } from 'react-to-print';


export default function Invoices() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [isLoading, setIsLoading] = useState(false);
  //fetch data on first render
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});

  const token = Cookies.get("jwt");

  const [invData, setInvData] = useState({
    client_name: "",
    start_date: "",
    end_date: "",
  })
  const [client, setClient] = useState("")

  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    const fetchData = async () => {
      setIsLoading(true);
      const token = Cookies.get("jwt");
      const request = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
          'Authorization': 'Bearer ' + token,
        }
      }
      try {
        var response = await fetch(`https://admins.adspot.pub/api/admins/clients/all`, request, { mode: "cors" });
        if (response.status === 200) {
          let data = await response.json();
          setData(data);
        } else {
          toast.error(await response.json());
        }
      } catch (err) {
        toast.error(err.message);
      }
    }

    fetchData();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  }, [])

  const getInvioce = async () => {
    if(client.client_id === "" || typeof(client.client_id) == "undefined"){
      toast.error("must select a client");
      return;
    }
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/invoice?start_date=${invData.start_date}&end_date=${invData.end_date}&client_id=${client.client_id}`, request, { mode: "cors" });
      if (response.status === 200) {
        let data = await response.json();
        setItem(data);
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Broadcast" title="Invoices" hide={true} />
      <Container fluid="true" className='mb-2'>
        <Row className='justify-content-center'>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row>
                <Col sm={12}>
                  <FormGroup>
                    <Label htmlFor="users">Client:</Label>

                    <Input
                      className="form-control"
                      type="select"
                      value={invData.client_name}
                      onChange={(e) => {
                        setClient(data.filter(c => c.client_name === e.target.value)[0]); setInvData({ ...invData, client_name: e.target.value });
                      }}
                    >
                      <option value="" disabled>select client</option>
                      {data.map((client) => {
                        return (
                          <option key={data.indexOf(client)} value={client.client_name}>{client.client_name}</option>
                        );
                      })}
                    </Input>

                  </FormGroup>
                </Col>
                <Col sm="6" className='input--group'>
                  <FormGroup>
                    <Label className="form-label">Start Date: </Label>
                    <Input className="form-control" type="date" placeholder="Start Date" value={invData.start_date} onChange={(e) => { setInvData({ ...invData, start_date: e.target.value }) }} />
                  </FormGroup>
                </Col>
                <Col sm="6" className='input--group'>
                  <FormGroup>
                    <Label className="form-label">End Date: </Label>
                    <Input className="form-control" type="date" placeholder="End Date" value={invData.end_date} onChange={(e) => { setInvData({ ...invData, end_date: e.target.value }) }} />
                  </FormGroup>
                </Col>
                </Row>
               
              </CardBody>
              <CardFooter className='p-4'>
                <Button className="btn-block " color='success' onClick={getInvioce}>Generate Invoice</Button>

              </CardFooter>
            </Card>
          </Col>

        </Row>
        {
          Object.keys(item).length !== 0 && <Row className='justify-content-center'>
          <Col sm="12" >
            <Card>
              
              <CardBody >
                <PDFInvoice  ref={componentRef} invoice={item} client={client} dd={invData.start_date} df={invData.end_date} />

              </CardBody>
              <CardFooter>
                 <Button className="btn-block mb-3 ml-2" color='info' onClick={handlePrint}>Save Invoice</Button>

              </CardFooter>
            </Card>
          </Col>

        </Row>
        }
        


      </Container>
    </Fragment>
  )
}