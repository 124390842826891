import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../layout/loader";
import {
  Container,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useLocation, useHistory } from "react-router-dom";
import Select from "react-select";

const radioStyle = {
  display: "inline-block",
  position: "static",
  margin: "0 5px 0 0",
  opacity: "initial",
};

export default function AddContract() {
  const history = useHistory();
  const location = useLocation();
  const client = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const token = Cookies.get("jwt");

  const [addcontractmodal, setaddcontractmodal] = useState(false);
  const addcontractmodalToggle = () => setaddcontractmodal(!addcontractmodal);
  const [checkmodal, setcheckmodal] = useState(false);
  const checkmodalToggle = () => setcheckmodal(!checkmodal);

  const handlePackChange = (pack) => {
    setContractInputData({ ...contractinputData, contract_pack: pack });
  };

  const [contractinputData, setContractInputData] = useState({
    contract_start_date: "",
    contract_end_date: "",
    contract_video_length: "",
    contract_discount: 0,
    contract_max_budjet_ttc: 0,
    contract_total_ttc: 0,
    contract_name: "",
  });

  const [keyword, setkeyword] = useState("");
  const pattern = new RegExp(keyword, "i");

  const addNewContract = async () => {
    setIsLoading(true);
    if (
      contractinputData.contract_start_date == "" ||
      contractinputData.contract_end_date == "" ||
      contractinputData.contract_max_budjet_ttc == "" ||
      contractinputData.contract_video_length == "" ||
      contractinputData.contract_name == "" ||
      contractinputData.contract_total_ttc == "" 
    ) {
      toast.error("You have to fill in all the information !");
      return;
    }
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        client_id: client?.client_id,
        contract_start_date: contractinputData.contract_start_date,
        contract_end_date: contractinputData.contract_end_date,
        max_budjet_ttc: contractinputData.contract_max_budjet_ttc,
        total_ttc: contractinputData.contract_total_ttc,
        video_length: contractinputData.contract_video_length,
        contract_discount: contractinputData.contract_discount,
        contract_name: contractinputData.contract_name,
      }),
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/client/contract/new`,
        request,
        { mode: "cors" }
      );
      if (response.status === 200) {
        toast.success("contract added successfully");
        history.push({
          pathname: `${process.env.PUBLIC_URL}/clients/client:${client?.client_id}`,
          state: { LeftLineTab: "3" },
        });
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb hide="true" title="" />
      <Container fluid={true}>
        <Row className="justify-content-center">
          <Col sm="12" md="10" lg="8">
            <Card>
              <CardHeader className="text-center">
                <h5>Add contract For {client?.client_name}</h5>
              </CardHeader>
              <CardBody>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Contract Name: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      value={contractinputData.contract_name}
                      onChange={(e) => {
                        setContractInputData({
                          ...contractinputData,
                          contract_name: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Video length: </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="In seconds"
                      value={contractinputData.contract_video_length}
                      onChange={(e) => {
                        setContractInputData({
                          ...contractinputData,
                          contract_video_length: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Start Date: </Label>
                    <Input
                      className="form-control"
                      type="date"
                      required
                      placeholder="Start Date"
                      value={contractinputData.contract_start_date}
                      onChange={(e) => {
                        setContractInputData({
                          ...contractinputData,
                          contract_start_date: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">End Date: </Label>
                    <Input
                      className="form-control"
                      type="date"
                      required
                      placeholder="End Date"
                      value={contractinputData.contract_end_date}
                      onChange={(e) => {
                        setContractInputData({
                          ...contractinputData,
                          contract_end_date: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Max Budjet TTC: </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="Max budjet TTC"
                      value={contractinputData.contract_max_budjet_ttc}
                      onChange={(e) => {
                        setContractInputData({
                          ...contractinputData,
                          contract_max_budjet_ttc: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>{" "}
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Total TTC: </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="Total TTC"
                      value={contractinputData.contract_total_ttc}
                      onChange={(e) => {
                        setContractInputData({
                          ...contractinputData,
                          contract_total_ttc: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col col="12">
                  <FormGroup>
                    <Label className="form-label">Discount: </Label>
                    <Input
                      className="form-control"
                      type="number"
                      placeholder="Discount"
                      value={contractinputData.contract_discount}
                      onChange={(e) => {
                        setContractInputData({
                          ...contractinputData,
                          contract_discount: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </CardBody>

              <CardFooter>
                <Button
                  className="btn btn-block"
                  color="success"
                  form="addContactForm"
                  onClick={addNewContract}
                >
                  Add contract
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
