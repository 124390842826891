import Cookies from 'js-cookie';
import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Input, Button, FormGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import Breadcrumb from '../../layout/breadcrumb';
import Loader from '../../layout/loader';
import wilayas from "../assets/wilayas.json";
import communes from "../assets/communes.json";
import { useParams, useLocation, useHistory } from 'react-router-dom';


export default function ModifyContact() {
    const history = useHistory();
    const { slug } = useParams();
    const location = useLocation();
    const data = location.state || { data: { therapeutic_area: [{ complement: 0, cosmetic: 0, gynecology: 0, pain: 0, pediatrics: 0 }] } }
    // console.log(location.state)
    const [isLoading, setIsLoading] = useState(false);
    const token = Cookies.get("jwt");
    const [inputData, setInputData] = useState({
        contact_lastname: data?.contact?.contact_lastname || "",
        contact_firstname: data?.contact?.contact_firstname || "",
        contact_wilaya: data?.contact?.contact_wilaya || "",
        contact_city: data?.contact?.contact_city || "",
        contact_sexe: data?.contact?.contact_sexe || "",
        contact_establishment: data?.contact?.contact_establishment || "",
        contact_email: data?.contact?.contact_email || "",
        contact_age: data?.contact?.contact_age || "",
        contact_address: data?.contact?.contact_address || "",
        contact_phone: data?.contact?.contact_phone || "",
        contact_region: data?.contact?.contact_region || "",
        contract_start_date: data?.contact?.contract_start_date || "",
        contract_end_date: data?.contact?.contract_end_date || "",
        contact_space: data?.contact?.contact_space || "",
        contact_location_lat: data?.contact?.contact_location.x || "",
        contact_location_lng: data?.contact?.contact_location.y || "",
        referrel_name: data?.contact?.referrel_name || "",
        cosmetics: data?.therapeutic_area?.length != 0 ? data?.therapeutic_area[0]?.cosmetic || 0 : 0,
        gynecology: data?.therapeutic_area?.length != 0 ? data?.therapeutic_area[0]?.gynecology || 0 : 0,
        pain: data?.therapeutic_area?.length != 0 ? data?.therapeutic_area[0]?.pain || 0 : 0,
        pediatrics: data?.therapeutic_area?.length != 0 ? data?.therapeutic_area[0]?.pediatrics || 0 : 0,
        complements: data?.therapeutic_area?.length != 0 ? data?.therapeutic_area[0]?.complement || 0 : 0,
        business_type: data?.contact.business_type || ""
    });
    const [wilayaID, setWilayaID] = useState(wilayas.filter((w) => w.nom.toLowerCase() == inputData.contact_wilaya.toLowerCase())[0]?.code);
    const handleWilayaChange = (e) => {
        const wilayaChosen = e.target.value;
        const wilayaID = wilayas.filter((w) => w.nom.toLowerCase() == wilayaChosen.toLowerCase())[0]?.code;
        setWilayaID(wilayaID);
        const contact_city = communes.filter(
            (item) => item.wilaya_id === wilayaID
        )[0]?.nom;
        setInputData({ ...inputData, contact_city, contact_wilaya: wilayaChosen });
    };

    const update = async () => {
        if (inputData.contact_lastname == "") {
            toast.error("the contact lastname is required !")
            return
        }
        if (inputData.contact_firstname == "") {
            toast.error("the contact fistname is required !")
            return
        }
        if (inputData.contact_phone == "") {
            toast.error("the contact phone is required !")
            return
        }
        if (inputData.contact_email == "") {
            toast.error("the contact email is required !")
            return
        }
        if (inputData.contact_wilaya == "") {
            toast.error("the contact wilaya is required !")
            return
        }
        if (inputData.contact_city == "") {
            toast.error("the contact city is required !")
            return
        }
        if (inputData.contact_location_lng == "") {
            toast.error("the contact location lng is required !")
            return
        }
        if (inputData.contact_location_lat == "") {
            toast.error("the contact location lat is required !")
            return
        }
        if (inputData.contract_start_date == "") {
            toast.error("the contract start date is required !")
            return
        }
        if (inputData.contract_end_date == "") {
            toast.error("the contract end date is required !")
            return
        }
        if (inputData.contact_space == "") {
            toast.error("the contact must have a 10sec video !")
            return
        }
        if ((parseInt(inputData.cosmetics) + parseInt(inputData.gynecology) + parseInt(inputData.pain) + parseInt(inputData.pediatrics) + parseInt(inputData.complements) != 100) && (parseInt(inputData.cosmetics) + parseInt(inputData.gynecology) + parseInt(inputData.pain) + parseInt(inputData.pediatrics) + parseInt(inputData.complements)) != 0 &&
            !isNaN(parseInt(inputData.cosmetics) + parseInt(inputData.gynecology) + parseInt(inputData.pain) + parseInt(inputData.pediatrics) + parseInt(inputData.complements))) {

            toast.error("The sum of the potentials is not 100%")
            return
        }
        const request = {
            method: "PUT",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers":
                    "Origin, X-requested-With, Content-Type, Accept",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                contact_id: slug,
                ...inputData,
            })
        }
        try {
            var response = await fetch("https://admins.adspot.pub/api/admins/contact/update", request, { mode: 'cors' });
            if (response.status === 200) {
                toast.success("Contact updated successfully!")
                window.location.href = `${process.env.PUBLIC_URL}/contacts/contact:${slug}`
            } else {
                toast.error("error!");
                setIsLoading(false);
            }
        } catch (err) {
            toast.error(err.message);
            setIsLoading(false);
        }

    }
    return (
        <Fragment>
            <Loader isLoading={isLoading} />
            <Breadcrumb parent="Contacts" title="" hide={false} />
            <Container fluid="true">
                <Row className='justify-content-center'>

                    <Col sm="12" md="10" xl="8">
                        <Card>
                            <CardHeader className='p-4'>
                                <h5>Modify Contact</h5>
                            </CardHeader>
                            <CardBody>
                                <form action="">
                                    <Row>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">
                                                    Contact last name:{" "}
                                                </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Last name"
                                                    value={inputData.contact_lastname || ""}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_lastname: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">
                                                    Contact first name:{" "}
                                                </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="First name"
                                                    value={inputData.contact_firstname}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_firstname: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Sex: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="select"
                                                    placeholder="Sex"
                                                    value={inputData.contact_sexe}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_sexe: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option value="" disabled>
                                                        Sex
                                                    </option>
                                                    <option value="Man">Man</option>
                                                    <option value="Woman">Woman</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Age: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Age"
                                                    value={inputData.contact_age}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_age: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Phone: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Phone"
                                                    value={inputData.contact_phone}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_phone: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Email: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="email"
                                                    placeholder="Email"
                                                    value={inputData.contact_email}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_email: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Pharmacy name: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Name of the pharmacy"
                                                    value={!inputData.contact_establishment ? "" : inputData.contact_establishment}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_establishment: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Referral name: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Referral Name"
                                                    value={!inputData.referrel_name ? "" : inputData.referrel_name}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            referrel_name: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Region: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="select"
                                                    value={inputData.contact_region}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_region: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option value="" disabled>
                                                        Region
                                                    </option>
                                                    <option value="East">East</option>
                                                    <option value="West">West</option>
                                                    <option value="Center">Center</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Wilaya: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="select"
                                                    value={inputData.contact_wilaya.toLowerCase()}
                                                    onChange={handleWilayaChange}
                                                >
                                                    {wilayas.map((w) => {
                                                        return (
                                                            <option key={w.id} value={w.nom.toLocaleLowerCase()}>
                                                                {w.nom}
                                                            </option>
                                                        );
                                                    })}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">City: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="select"
                                                    value={inputData.contact_city}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_city: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option value="" disabled>
                                                        City
                                                    </option>
                                                    {communes
                                                        .filter((item) => item.wilaya_id === wilayaID)
                                                        .map((c) => {
                                                            return (
                                                                <option key={c.id} value={c.nom}>
                                                                    {c.nom}
                                                                </option>
                                                            );
                                                        })}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Address: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Address"
                                                    value={inputData.contact_address}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_address: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Location lng: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Location lng"
                                                    value={inputData.contact_location_lng}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_location_lng: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Location lat: </Label>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Location lat"
                                                    value={inputData.contact_location_lat}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_location_lat: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">
                                                    Contract start date:{" "}
                                                </Label>
                                                <Input
                                                    className="form-control"
                                                    type="date"
                                                    placeholder="Start Date"
                                                    value={inputData.contract_start_date.split(' ', 1)}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contract_start_date: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">
                                                    Contract end date:{" "}
                                                </Label>
                                                <Input
                                                    className="form-control"
                                                    type="date"
                                                    placeholder="End Date"
                                                    value={inputData.contract_end_date.split(' ', 1)}
                                                    onChange={(e) => {

                                                        setInputData({
                                                            ...inputData,
                                                            contract_end_date: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">
                                                    Contact has 10sec video:{" "}
                                                </Label>
                                                <Input
                                                    className="form-control"
                                                    type="select"
                                                    value={!inputData.contact_space ? "" : inputData.contact_space}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            contact_space: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    <option value="" disabled>
                                                        Contact video
                                                    </option>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Complements(%): </Label>
                                                <Input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Complements"
                                                    value={inputData.complements}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            complements: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Cosmetics(%): </Label>
                                                <Input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Cosmetics"
                                                    value={inputData.cosmetics}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            cosmetics: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Gynecology(%): </Label>
                                                <Input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Gynecology"
                                                    value={inputData.gynecology}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            gynecology: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Pain(%): </Label>
                                                <Input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Pain"
                                                    value={inputData.pain}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            pain: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className="input--group">
                                            <FormGroup>
                                                <Label className="form-label">Pediatrics(%): </Label>
                                                <Input
                                                    className="form-control"
                                                    type="number"
                                                    placeholder="Pediatrics"
                                                    value={inputData.pediatrics}
                                                    onChange={(e) => {
                                                        setInputData({
                                                            ...inputData,
                                                            pediatrics: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12" className='input--group'>
                                            <FormGroup>
                                                <Label className="form-label">Business type:  </Label>
                                                <Input
                                                    name="select"
                                                    type="select"
                                                    onChange={(e) => { setInputData({ ...inputData, business_type: e.target.value }) }}
                                                >
                                                    <option value={"Pharma"}>
                                                        Pharma
                                                    </option>
                                                    <option value={"Retail"}>
                                                        Retail
                                                    </option>
                                                    <option value={"Exposition"}>
                                                        Exposition
                                                    </option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </form>
                            </CardBody>

                            <CardFooter className='p-4'>
                                <Button className="btn-block" color="success" disabled={isLoading} onClick={update}>
                                    Save
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
