import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Card, CardBody,CardFooter, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import moment from 'moment';


export default function Broadcast() {
  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);
  const [validationParams, setValidationParams] = useState(false);
  const [status, setStatus] = useState("");
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ space_time_slots: [], diffusion_regions: [], passage: [] });
  const [videoLink, setVideoLink] = useState("");
  const token = Cookies.get("jwt");
  const history = useHistory();

  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    const fetchData = async () => {
      setIsLoading(true);
      const request = {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
          'Authorization': 'Bearer ' + token,
        }
      }
      try {
        var response = await fetch(`https://admins.adspot.pub/api/admins/diffusion?diffusion_id=${slug}`, request, { mode: "cors" });
        if (response.status === 200) {
          let data = await response.json();
          console.log(data);
          setData(data)
          if (data.landscape_video_link!=="") {
            
            setVideoLink(data.landscape_video_link);
          } else {
            setVideoLink(data.portrait_video_link);
            
          }
        } else {
          toast.error(await response.json());
        }
      } catch (err) {
        toast.error(err.message);
      }
      setIsLoading(false);
    }
    fetchData();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const validateVideo = async () => {
    if(validationParams ===false) {
      toast.error("you need to check verification first !")
      return
    }
   
    setIsLoading(true);
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        diffusion_id: slug,
        status: status,
      }),
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/diffusion/validate`, request, { mode: "cors" });
      if (response.status === 200) {
        // window.location.reload();
        history.go(0)
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Broadcast" title="" hide={true} />
      <Container fluid="true">
        <Row>
          <Col md={12}>
            <Card>
              <CardHeader className='p-4'>
                <h4 className=''><span className='client--name__header'>{data.client_name}</span> {moment(data.diffusion_end_date).isBefore(new Date()) && <span className="badge badge-danger badge-pill  f-right">EXPIRED!</span>}
                  {data.diffusion_status === "Accepted" ?
                    <span className="badge badge-success badge-pill f-right mr-2">{data.diffusion_status}</span>
                    : data.diffusion_status === "Pending" ?
                      <span className="badge badge-warning badge-pill f-right">{data.diffusion_status}</span>
                      : <span className="badge badge-secondary badge-pill f-right">{data.diffusion_status}</span>
                  }</h4>
              </CardHeader>
              <CardBody>
                <Row className='px-0'>

                  <Col sm="6" className='text-left '>
                    <p><span className=' h6 f-16 '>Phone: </span><span className=''>{data.client_phone}</span></p>
                    <p><span className=' h6 f-16 '>Email: </span><span className='f-12'>{data.client_email}</span></p>
                    <p><span className=' h6 f-16 '>Space Pack: </span><span className=''>{data.space_pack}</span></p>
                    <p><span className=' h6 f-16 '>Total Broadcasts: </span><span className="">{data.passage.reduce((prev, obj) => prev + (+obj.total), 0)}</span></p>
                    <p><span className=' h6 f-16 '>Created at: </span><span className=''>{moment(data.created_at).format("DD/MM/YYYY HH:mm")}</span></p>
                  </Col>
                  <Col sm="6" className='text-left '>
                    <p><span className=' h6 f-16 '>Broadcast Duration: </span><span className=''>{moment(data.diffusion_end_date).diff(data.diffusion_start_date, "days")} days</span></p>
                    <p><span className=' h6 f-16 '>Start Date: </span><span className=''>{moment(data.diffusion_start_date).format("DD/MM/YYYY HH:mm")}</span></p>
                    <p><span className=' h6 f-16 '>End Date: </span><span className=''>{moment(data.diffusion_end_date).format("DD/MM/YYYY HH:mm")}</span></p>
                    <p><span className=' h6 f-16 '>Number of Screens: </span><span className=''>{data.tvs_count}</span></p>
                    {data.qrcode_link && <p><span className=' h6 f-16 '>QR code link: </span><span className=''>{data.qrcode_link}</span></p>}
                    {data.qrcode_placement && <p><span className=' h6 f-16 '>QR code link: </span><span className=''>{data.qrcode_placement}</span></p>}

                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md="12" className='text-center'>
                    <span className="f-16 h6 ">Diffusion ranges:</span>
                  </Col>
                  {data.passage.map((p) => {
                    return (
                      <Col md="4" className='px-0'>
                        <p key={data.passage.indexOf(p)}>
                          <span className="badge badge-primary">{p.diffusion_range}</span> : {p.total}
                        </p>
                      </Col>
                    )
                  })}
                </Row>


              </CardBody>
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              <CardHeader className='p-4 text-center client--name__header'>
                {/* <p><span className='h4'>Video For: </span><span >{data.video_product_name}</span></p> */}
                <h4>{data.video_product_name}</h4>
              </CardHeader>
              <CardBody>
             
                {videoLink && <video id={data.video_name} controls width="100%">
                  <source src={videoLink} type="video/mp4" />
                  Your browser does not support the video tag. I suggest you upgrade your browser.
                </video>}
                    <p className='mt-3'><span className="h4 f-16"> Product description: </span><span ><i>{data.video_product_description}</i></span></p>
                <CardFooter className='pb-0'>

                <div className="d-flex justify-content-center">
                  
                    <Button color='success' className='mx-3 ' onClick={() => { Verticalcentermodaltoggle(); setStatus("Accepted") }}>Accept</Button>
                    <Button color='danger' className='mx-3 ' onClick={() => { Verticalcentermodaltoggle(); setStatus("Rejected") }}>Reject</Button>
                </div>
                </CardFooter>
                  

                  <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered size='lg'>
                    <ModalHeader toggle={Verticalcentermodaltoggle}>
                      Validation
                    </ModalHeader>
                    <ModalBody className='pb-0 mb-0'>
                      <ul style={{listStyleType:"disc", margin:"20px"}}>
                        <li>
                        this video respects the interests of consumers: not ambiguous, not misleading.
                        </li>
                        <li>
                        this video doesn't cause confusion with another service or commercial name of a competitor and must not discredit another comapny's product or image
                        </li>
                        <li>
                        this video complies with requirements of veracity, decency and respect for the human person.
                        </li>
                        <li>
                        this video doesn't discredit the state.
                        </li>
                        <li>
                        this video doesn't offend political, religious or political convictions.
                        </li>
                        <li>
                        this video is free of racial or sexual discrimination, scenes of violence, elements that may cause fear, encourage abuse, recklessness or negligence.
                        </li>
                      </ul>
                      <div >
                        <input className='m-1' type="checkbox" name='valid' id="valid" checked={validationParams} onChange={() => {setValidationParams( !validationParams ) }} />
                        <label htmlFor="valid">this video is verified</label>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="success" onClick={() => { Verticalcentermodaltoggle(); validateVideo() }}>Validate</Button>
                      <Button color="danger" onClick={Verticalcentermodaltoggle}>Close</Button>
                    </ModalFooter>
                  </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
