import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../../layout/loader';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import Select from 'react-select';

export default function Clients() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const token = Cookies.get("jwt");
  const history = useHistory();

  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);

  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    NIF: "",
    NIS: "",
    RC: "",
    AI: "",
    business_type: "Pharma",
    available_services: []
  });
  const handleRowClicked = (row) => {

    history.push({
      pathname: `${process.env.PUBLIC_URL}/clients/client:${row.client_id}`,
      state: row
    });
  };

  const clientColumns = [
    {
      name: "Name ",
      selector: (row) => row.client_name,
      sortable: true,
      center: true,
    },
    {
      name: "Phone",
      selector: (row) => row.client_phone,
      sortable: true,
      center: true,
    },
    {
      name: "Address",
      selector: (row) => row.client_address,
      sortable: true,
      center: true,
    }



  ];


  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      }
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/clients/all`, request, { mode: "cors" });
      if (response.status === 200) {
        let data = await response.json();
        setData(data);
        setIsLoading(false);
      } else {
        toast.error(await response.json());
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  }
  const addClient = async () => {
    setIsLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-requested-With, Content-Type, Accept",
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        client_name: inputData.name,
        client_phone: inputData.phone,
        client_email: inputData.email,
        client_address: inputData.address,
        client_nif: inputData.NIF,
        client_nis: inputData.NIS,
        client_rc: inputData.RC,
        client_ai: inputData.AI,
        business_type: inputData.business_type,
        available_services: inputData.available_services
      })
    }
    try {
      var response = await fetch(`https://admins.adspot.pub/api/admins/clients/new`, request, { mode: "cors" });
      if (response.status === 200) {
        toast.success(await response.json());
        setIsLoading(false);
      } else {
        toast.error(await response.json());
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    Verticalcentermodaltoggle();
    fetchData();
    // window.location.reload()
  }

  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    fetchData();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const availableServicesOptions = [
    { value: "Qr Code", label: "Qr Code" },
    { value: "Diffusion", label: "Diffusion" },
    { value: "Text Diffusion", label: "Text Diffusion" },
    { value: "Playlist", label: "Playlist" }
  ]


  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Breadcrumb parent="Dashboard" title="Clients" hide={false} />
      <Container fluid="true">
        <Row>
          <Col className='mb-4'>

            <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered>
              <ModalHeader toggle={Verticalcentermodaltoggle}>
                Add Client
              </ModalHeader>
              <ModalBody>
                <form action="">
                  <Row>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Name: </Label>
                        <Input className="form-control" type="text" placeholder="Name" value={inputData.name} onChange={(e) => { setInputData({ ...inputData, name: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Email: </Label>
                        <Input className="form-control" type="email" placeholder="Email" value={inputData.email} onChange={(e) => { setInputData({ ...inputData, email: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Phone: </Label>
                        <Input className="form-control" type="text" placeholder="Phone" value={inputData.phone} onChange={(e) => { setInputData({ ...inputData, phone: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Address: </Label>
                        <Input className="form-control" type="text" placeholder="Address" value={inputData.address} onChange={(e) => { setInputData({ ...inputData, address: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">NIF: </Label>
                        <Input className="form-control" type="text" placeholder="NIF" value={inputData.NIF} onChange={(e) => { setInputData({ ...inputData, NIF: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">NIS: </Label>
                        <Input className="form-control" type="text" placeholder="NIS" value={inputData.NIS} onChange={(e) => { setInputData({ ...inputData, NIS: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">RC: </Label>
                        <Input className="form-control" type="text" placeholder="RC" value={inputData.RC} onChange={(e) => { setInputData({ ...inputData, RC: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">AI: </Label>
                        <Input className="form-control" type="text" placeholder="AI" value={inputData.AI} onChange={(e) => { setInputData({ ...inputData, AI: e.target.value }) }} />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                      <FormGroup>
                        <Label className="form-label">Business type:  </Label>
                        <Input
                          name="select"
                          type="select"
                          onChange={(e) => { setInputData({ ...inputData, business_type: e.target.value}) }}
                        >
                          <option value={"Pharma"}>
                            Pharma
                          </option>
                          <option value={"Retail"}>
                            Retail
                          </option>
                          <option value={"Exposition"}>
                            Exposition
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="6" className='input--group'>
                    <FormGroup>
                    <Label className="form-label">Available Services: </Label>
                    <Select
                      isMulti
                      options={availableServicesOptions}
                      placeholder="Available Services"
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          available_services: (e.map(obj => { return obj.value }))
                        })
                      }}
                    />
                  </FormGroup>
                    </Col>
                  </Row>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={Verticalcentermodaltoggle}>Close</Button>
                <Button color="success" disabled={isLoading} onClick={addClient}>Add</Button>
              </ModalFooter>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col>


          </Col>
          <Col md="12">
            <Card>

              <CardBody className='p-4 pt-0'>
                <div className="table-responsive product-table">
                  <DataTable onRowClicked={handleRowClicked} columns={clientColumns} data={data} pagination highlightOnHover={true} pointerOnHover={true}
                    actions={<Button color='success' onClick={Verticalcentermodaltoggle}>Add Client</Button>} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    </Fragment>
  )
}
