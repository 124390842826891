import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../layout/loader";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Table,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import moment from "moment";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import CountUp from "react-countup";
import Swal from "sweetalert2";
import Select from 'react-select';

export default function ClientDetails() {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const tab = location.state;
  const { slug } = useParams();
  const token = Cookies.get("jwt");
  const [data, setData] = useState({
    client: {},
    spaces: [],
    textSpaces: [],
    invoices: [],
    videos: [],
    contracts: [],
    tvs:[]
  });
  const [info, setInfo] = useState();
  const [videos, setVideos] = useState([]);
  const [contract, setContract] = useState();
  const [spaces, setSpaces] = useState([]);
  const [textspaces, setTextSpaces] = useState([]);
  const [contractSpaces, setContractSpaces] = useState([]);
  const [contractTextSpaces, setContractTextSpaces] = useState([]);

  const [Verticalcenter, setVerticalcenter] = useState(false);
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter);
  const [Verticalcenter2, setVerticalcenter2] = useState(false);
  const Verticalcentermodaltoggle2 = () => setVerticalcenter2(!Verticalcenter2);
  const [Verticalcenter3, setVerticalcenter3] = useState(false);
  const Verticalcentermodaltoggle3 = () => setVerticalcenter3(!Verticalcenter3);
  const [notification, setNotification]= useState("")
  const [inputData, setInputData] = useState({
    client_name: "",
    client_email: "",
    client_phone: "",
    client_address: "",
    client_nif: "",
    client_nis: "",
    client_rc: "",
    client_ai: "",
    business_type: "",
    available_services: []
  });
  const modifyClient = async () => {
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        client_id: slug,
        client_name: inputData.client_name,
        client_phone: inputData.client_phone,
        client_email: inputData.client_email,
        client_address: inputData.client_address,
        client_nif: inputData.client_nif,
        client_nis: inputData.client_nis,
        client_rc: inputData.client_rc,
        client_ai: inputData.client_ai,
        business_type: inputData.business_type,
        available_services: inputData.available_services
      }),
    };

    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/clients/update`,
        request,
        { mode: "cors" }
      );
      if (response.status === 200) {
        toast.success(await response.json());
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
    Verticalcentermodaltoggle();
    fetchData();
    // window.location.reload()
  };
  const handleChangePassword = (id) => {
    Swal.fire({
      text: "Are you sure you want to change the password ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm!",
    }).then((result) => {
      if (result.isConfirmed) {
        changePwd(id);
      } else if (result.isDenied) {
        return;
      }
    });
  };

  const changePwd = async (id) => {
    const request = {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ client_id: id }),
    };

    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/clients/password/update`,
        request,
        { mode: "cors" }
      );

      if (response.status === 200) {
        toast.success("Password changed successfully");
      } else {
        toast.error(await response.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleRowClicked = (row, contractid) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/spaces/space:${row.space_id}`,
      state: { ...row, ...data.client, ...contractid },
    });
  };
  const handleTextRowClicked = (row, contractid) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/textspaces/space:${row.text_space_id}`,
      state: { ...row, ...data.client, ...contractid },
    });
  };
 
  const handleRowContractClicked = (row, spaces, textSpaces) => {
    setContractSpaces([]);
    setContractTextSpaces([]);
    spaces= spaces.filter((space)=>{
      return space.contract_id===row.contract_id
    })
    textSpaces= textSpaces.filter((space)=>{
      return space.contract_id===row.contract_id
    })
     setContract(row)
    if (spaces.length > 0 && spaces[0].space_id !== null) {
      setContractSpaces(spaces);
    } 
    if (textSpaces.length > 0 && textSpaces[0].text_space_id !== null) {
      setContractTextSpaces(textSpaces);
    }
    setLeftLineTab("5");
  };

  const spacesColumns = [
    {
      name: "Creation Date ",
      selector: (row) => moment(row.created_at).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.space_start_date).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "End Date",
      selector: (row) => moment(row.space_end_date).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "Discount",
      selector: (row) => row.space_discount,
      sortable: true,
      center: true,
    },
    {
      name: "Time Slot",
      selector: (row) => row.space_time_slots.map(mapSpaces) ?? [],
      sortable: false,
      center: true,
    },
    {
      name: "Video Length",
      selector: (row) => row.space_video_length,
      sortable: true,
      center: true,
    },
    {
      name: "Screens",
      selector: (row) => row.space_tvs_count,
      sortable: true,
      center: true,
    },
  ];
  const textSpacesColumns = [
    {
      name: "Creation Date ",
      selector: (row) => moment(row.created_at).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.text_space_start_date).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "End Date",
      selector: (row) => moment(row.text_space_end_date).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "Discount",
      selector: (row) => row.text_space_discount,
      sortable: true,
      center: true,
    },
    {
      name: "Time Slot",
      selector: (row) => row.text_space_time_slots?.map(mapSpaces) ?? [],
      sortable: false,
      center: true,
    },
    {
      name: "Text Length",
      selector: (row) => row.space_text_length,
      sortable: true,
      center: true,
    },
    {
      name: "Screens",
      selector: (row) => row.space_tvs_count,
      sortable: true,
      center: true,
    },
  ];
  const contractsColumns = [
    {
      name: "Contract Name ",
      selector: (row) => row.contract_name,
      sortable: true,
      center: true,
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.contract_start_date).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "End Date",
      selector: (row) => moment(row.contract_end_date).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "Max Budjet",
      selector: (row) => row.max_budjet_ttc,
      sortable: true,
      center: true,
    },
    {
      name: "Total TTC",
      selector: (row) => row.total_ttc,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Link
          to={{
            pathname: `${process.env.PUBLIC_URL}/contracts/contract:${row.contract_id}`,
            state: { ...row, ...data.client },
          }}
        >
          Details
        </Link>
      ),
      sortable: true,
      center: true,
    },
  ];

  const invoicesColumns = [
    {
      name: "Invoice Number",
      selector: (row) => row.invoice_number,
      sortable: true,
      center: true,
    },
    {
      name: "Invoice Date",
      selector: (row) => moment(row.invoice_date).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "Due Date",
      selector: (row) => moment(row.invoice_dead_line).format("DD/MM/YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "Total HT",
      selector: (row) => row.total_ht,
      sortable: true,
      center: true,
    },
    {
      name: "Total TTC",
      selector: (row) => row.total_ttc,
      sortable: true,
      center: true,
    },
    {
      name: "Paid",
      selector: (row) =>
        row.invoice_paid ? (
          <span className="badge badge-success badge-pill">Paid</span>
        ) : (
          <span className="badge badge-danger badge-pill">Not Paid</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: "Link",
      selector: (row) => (
        <a href={row.invoice_link} target="_blank">
          <i className="icofont icofont-external-link"></i>
        </a>
      ),
      sortable: false,
      center: true,
    },
  ];
  const statusPill = (status) => {
    switch (status) {
      case "added":
        return <span className="badge badge-dark badge-pill">
          {status}</span>
      case "to prepare":
        return <span className="badge badge-info badge-pill">
          {status}</span>
      case "ready to install":
        return <span className="badge badge-primary badge-pill">
          {status}</span>
      case "to install":
        return <span className="badge badge-warning badge-pill">
          {status}</span>
      case "installed":
        return <span className="badge badge-success badge-pill">
          {status}</span>
      default:

        return <span className="badge badge-danger badge-pill">
          {status}</span>
    }
  }


  const getOffDuration = (inteval) => {
    if (!inteval) {
      return "00:00"
    }
    const hours = inteval.hours != null ? inteval.hours : "00"
    const minutes = inteval.minutes != null ? inteval.minutes : "00"
    return hours + ":" + minutes
  }
  const screensColumns = [
    {
    name: "SN°",
      selector: (row) => row.tv_serial_number,
      sortable: true,
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row.tv_name,
      sortable: true,
      center: true,
    },
    {
      name: "Status",
      selector: (row) => statusPill(row.install_status),
      sortable: true,
      center: true,
    },
    {
      name: "First Connection",
      selector: (row) => moment(row.first_connection).format('DD/MM/YYYY HH:mm'),
      sortable: true,
      center: true,
    },
    {
      name: "Last Connection",
      selector: (row) =>  moment(row.last_connection).format('DD/MM/YYYY HH:mm'),
      sortable: true,
      center: true,
    },
    {
      name: "Off Duration",
      selector: (row) => getOffDuration(row.off_duration),
      sortable: true,
      center: true,
    }
  ];
  const handleScreenRowClicked = (row) => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/screens/screen:${row.tv_id}`,
      state: row
    });
  };
  const mapSpaces = (range) => {
    return <span className="badge badge-light badge-pill">{range}</span>;
  };

  const fetchData = async () => {
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
    };
    try {
      var response1 = await fetch(
        `https://admins.adspot.pub/api/admins/client?client_id=${slug}`,
        request,
        { mode: "cors" }
      );
      if (response1.status === 200) {
        let data = await response1.json();
        console.log(data);
        setData(data);
        setInputData(data.client);
        setVideos(data.videos);
        setSpaces(data.spaces);
        setTextSpaces(data.textSpaces);
        if (tab.contract_id !=null) {
          let contract =data.contracts?.find(cont => cont.contract_id === tab.contract_id);
          if (contract) {
            handleRowContractClicked(contract, data.spaces, data.textSpaces);
          }
        }
      } else {
        toast.error(await response1.json());
      }
      var response2 = await fetch(
        `https://admins.adspot.pub/api/admins/monitoring/client?client_id=${slug}`,
        request,
        { mode: "cors" }
      );
      if (response2.status === 200) {
        let data = await response2.json();

        setInfo(data);
      } else {
        toast.error(await response2.json());

      }
    } catch (err) {
      console.log(err.message);
    }
  };
  const [LeftLineTab, setLeftLineTab] = useState("1");
  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    if (typeof tab != "undefined") {
      setLeftLineTab(tab.LeftLineTab);
    }
    fetchData();
    return () => {
      unmount = true;
      window.scrollTo(0, 0);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const availableServicesOptions = [
    { value: "Qr Code", label: "Qr Code" },
    { value: "Diffusion", label: "Diffusion" },
    { value: "Text Diffusion", label: "Text Diffusion" },
    { value: "Playlist", label: "Playlist" }
  ]
  const [screenData, setScreenData] = useState({
    tv_type: "landscape",
    tv_position: "Wall",
    tv_internet_connection: "",
    tv_serial_number: "",
    tv_modem_phone: "",
    tv_size: "32",
    tv_resolution: "HD",
    business_type: data?.client?.business_type,
    tv_name: "",
    owned_by: slug
  });
  const addTv = async () => {
    if ( screenData.tv_serial_number === "") {
      toast.error("You must enter the SN!")
      return
    }
    setIsLoading(true);
    const body = {
      ...screenData,
    };
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ ...body }),
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/client/tvs/new`,
        request,
        { mode: "cors" }
      );

      if (response.status === 200) {
        toast.success("Screen added successfully");
        setIsLoading(false);
      } else {
        toast.error("error !");
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    fetchData();
    Verticalcentermodaltoggle2();
  };
  const notifyClient = async () => {
    if ( notification === "") {
      toast.error("Please enter a valid message!")
      return
    }
    setIsLoading(true);
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ "notification_content":notification }),
    };
    try {
      var response = await fetch(
        `https://admins.adspot.pub/api/admins/client/notifications/create?client_id=`+slug,
        request,
        { mode: "cors" }
      );

      if (response.status === 200) {
        toast.success("Notification sent successfully");
        setIsLoading(false);
      } else {
        toast.error("error !");
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
    Verticalcentermodaltoggle3();
  };
  return (
    <Fragment>
      <Loader isLoading={isLoading} />
      <Container fluid="true">
        <Modal
          isOpen={Verticalcenter}
          toggle={Verticalcentermodaltoggle}
          centered
        >
          <ModalHeader toggle={Verticalcentermodaltoggle}>
            Modify Client
          </ModalHeader>
          <ModalBody>
            <form action="">
              <Row>
                <Col md="4" lg="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Name: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      value={inputData.client_name}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          client_name: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Email: </Label>
                    <Input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      value={inputData.client_email}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          client_email: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Phone: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Phone"
                      value={inputData.client_phone}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          client_phone: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Address: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Address"
                      value={inputData.client_address}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          client_address: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">NIF: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="NIF"
                      value={inputData.client_nif}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          client_nif: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">NIS: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="NIS"
                      value={inputData.client_nis}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          client_nis: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">RC: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="RC"
                      value={inputData.client_rc}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          client_rc: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">AI: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="AI"
                      value={inputData.client_ai}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          client_ai: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className='input--group'>
                  <FormGroup>
                    <Label className="form-label">Business type:  </Label>
                    <Input
                      name="select"
                      type="select"
                      value={inputData.business_type}
                      onChange={(e) => { setInputData({ ...inputData, business_type: e.target.value }) }}
                    >
                      <option value={"Pharma"}>
                        Pharma
                      </option>
                      <option value={"Retail"}>
                        Retail
                      </option>
                      <option value={"Exposition"}>
                        Exposition
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="4" lg="6" className="input--group">
                <FormGroup>
                    <Label className="form-label">Available Services: </Label>
                    <Select
                      isMulti
                      options={availableServicesOptions}
                      placeholder="Available Services"
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          available_services: (e.map(obj => { return obj.value }))
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={Verticalcentermodaltoggle}>
              Close
            </Button>
            <Button color="success" onClick={modifyClient}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={Verticalcenter3}
          toggle={Verticalcentermodaltoggle3}
          centered
        >
          <ModalHeader toggle={Verticalcentermodaltoggle3}>
            Notify Client
          </ModalHeader>
          <ModalBody>
            <form action="">
              <Row>
                <Col md="12"  className="input--group">
                  <FormGroup>
                    <Label className="form-label">Notification Message: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Notification Message"
                      value={notification}
                      onChange={(e) => {
                        setNotification( e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={Verticalcentermodaltoggle3}>
              Close
            </Button>
            <Button color="success" onClick={notifyClient}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col md="12" className="mt-4 ">
            <Card>
              <CardBody>
                <Nav className="justify-content-center nav-pills  nav-left">
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={
                        LeftLineTab === "1" || typeof LeftLineTab == "undefined"
                          ? "active"
                          : ""
                      }
                      onClick={() => setLeftLineTab("1")}
                    >
                      Client Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={LeftLineTab === "2" ? "active" : ""}
                      onClick={() => setLeftLineTab("2")}
                    >
                      Videos
                    </NavLink>
                  </NavItem>
                  {!data.client.available_services?.includes("Playlist")&&
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={LeftLineTab === "3" ? "active" : ""}
                      onClick={() => setLeftLineTab("3")}
                    >
                      Contracts
                    </NavLink>
                  </NavItem>}
                  
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={LeftLineTab === "4" ? "active" : ""}
                      onClick={() => setLeftLineTab("4")}
                    >
                      Invoices
                    </NavLink>
                  </NavItem>
                  {
                    data.client.available_services?.includes("Playlist")&&
                  <NavItem>
                    <NavLink
                      href="#javascript"
                      className={LeftLineTab === "6" ? "active" : ""}
                      onClick={() => setLeftLineTab("6")}
                    >
                     Screens
                    </NavLink>
                  </NavItem>
                  }
                </Nav>
              </CardBody>
            </Card>
          </Col>
          <Col md="12" className="mt-4">
            <Card>
              <CardBody>
                <TabContent
                  activeTab={
                    typeof LeftLineTab == "undefined" ? "1" : LeftLineTab
                  }
                >
                  <TabPane className="fade show" tabId="1">
                    <Row>
                      <Col md="12">
                        <Card>
                          <CardBody className="p-4">
                            <Row>
                              <Col md="12">
                                <Table>
                                  <thead className="text-center">
                                    <h5 className="mb-2 text-primary">
                                      {data.client.client_name}
                                    </h5>
                                  </thead>
                                  <tbody className="table-bordered">
                                    <tr>
                                      <th>Phone </th>
                                      <td>
                                        <p>{data.client.client_phone}</p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Email</th>
                                      <td>{data.client.client_email}</td>
                                    </tr>
                                    <tr>
                                      <th>Address</th>
                                      <td>{data.client.client_address}</td>
                                    </tr>
                                    <tr>
                                      <th>NIF</th>
                                      <td>{data.client.client_nif}</td>
                                    </tr>
                                    <tr>
                                      <th>NIS</th>
                                      <td>{data.client.client_nis}</td>
                                    </tr>
                                    <tr>
                                      <th>RC</th>
                                      <td>{data.client.client_rc}</td>
                                    </tr>
                                    <tr>
                                      <th>AI</th>
                                      <td>{data.client.client_ai}</td>
                                    </tr>
                                    <tr>
                                      <th>Business Type</th>
                                      <td>{data.client.business_type}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter className="p-4">
                            <Row>
                              <Col md="4" sm="12">
                                <Button
                                  className="btn-block "
                                  color="primary"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleChangePassword(slug);
                                  }}
                                >
                                  Change Password
                                </Button>
                              </Col>
                              <Col md="4" sm="12">
                                <Button
                                  className="btn-block"
                                  color="info"
                                  onClick={Verticalcentermodaltoggle}
                                >
                                  Modify Info
                                </Button>
                              </Col>
                              <Col md="4" sm="12">
                                <Button
                                  className="btn-block"
                                  color="warning"
                                  onClick={Verticalcentermodaltoggle3}
                                >
                                  Notify Client
                                </Button>
                              </Col>
                            </Row>
                          </CardFooter>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col col="12">
                        <Card className="browser-widget">
                          <CardBody className="media">
                            <div className="media-img">
                              <img
                                src={require("../assets/storage.jpg")}
                                alt=""
                              />
                            </div>
                            <div className="media-body align-self-center">
                              <div>
                                <p>Total Storage </p>
                                <h4>
                                  <span className="counter">
                                    <CountUp
                                      start={0}
                                      end={
                                        info?.main_free
                                          ? info?.main_free
                                          : 0 + info?.main_used
                                            ? info?.main_used
                                            : 0
                                      }
                                    />
                                  </span>
                                  {"%"}
                                </h4>
                              </div>
                              <div>
                                <p>Free</p>
                                <h4>
                                  <span className="counter">
                                    <CountUp
                                      start={0}
                                      end={
                                        info?.main_free ? info?.main_free : 0
                                      }
                                    />
                                  </span>
                                  {"%"}
                                </h4>
                              </div>
                              <div>
                                <p>Used </p>
                                <h4>
                                  <span className="counter">
                                    <CountUp
                                      start={0}
                                      end={
                                        info?.main_used ? info?.main_used : 0
                                      }
                                    />
                                  </span>
                                  {"%"}
                                </h4>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      {videos?.map((video) => {
                        return (
                          <Col md="6" xl="4 box-col-6 xl-50">
                            <Card>
                              <div className="blog-box blog-grid text-center">
                                <video
                                  id={video.video_name}
                                  controls
                                  width="100%"
                                >
                                  <source
                                    src={video.landscape_video_link}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag. I
                                  suggest you upgrade your browser.
                                </video>
                                <div className="blog-details-main">
                                  <ul className="blog-social">
                                    <li className="digits">
                                      {video.video_product_name}
                                    </li>
                                    <li className="digits">
                                      {video.landscape_video_length} s
                                    </li>
                                    <li className="digits">
                                      {video.video_status}
                                    </li>
                                  </ul>
                                  <hr />
                                  <h6 className="blog-bottom-details">
                                    {video.video_product_description}
                                  </h6>
                                </div>
                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <DataTable
                      title="Contracts"
                      onRowClicked={(row) => {
                        handleRowContractClicked(row,spaces, textspaces);
                      }}
                      columns={contractsColumns}
                      data={data.contracts}
                      pagination
                      highlightOnHover={true}
                      pointerOnHover={true}
                      actions={
                        <Button color="success" className=" ml-2">
                          <Link
                            to={{
                              pathname: `${process.env.PUBLIC_URL}/contracts/add`,
                              state: data.client,
                            }}
                            style={{ color: "white" }}
                          >
                            Add Contract
                          </Link>
                        </Button>
                      }
                    />
                  </TabPane>
                  <TabPane tabId="4">
                    <DataTable
                      title="Invoices"
                      columns={invoicesColumns}
                      data={data.invoices}
                      pagination
                      highlightOnHover
                      actions={
                        <Button
                          color="success"
                          className="ml-2"
                          onClick={() => {
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/invoices/add`,
                              state: data.client,
                            });
                          }}
                        >
                          Add Invoice
                        </Button>
                      }
                    />
                  </TabPane>
                  <TabPane tabId="5">
                  <Modal
          isOpen={Verticalcenter2}
          toggle={Verticalcentermodaltoggle2}
          centered
          size="lg"
        >
          <ModalHeader toggle={Verticalcentermodaltoggle2}>
            Add Screen
          </ModalHeader>
          <ModalBody>
            <form action="">
              <Row>
                <Col md="6" className='input--group'>
                  <FormGroup>
                    <Label className="form-label" >TV name: </Label>
                    <Input type="text" className="form-control" required placeholder="Tv Name" value={screenData.tv_name || ""} onChange={(e) => { setScreenData({ ...screenData, tv_name: e.target.value, }); }}>

                    </Input>

                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen serial N°: </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Serial Number"
                      value={screenData.tv_serial_number}
                      required
                      onChange={(e) => {
                        setScreenData({
                          ...screenData,
                          tv_serial_number: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen type: </Label>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="Screen Type"
                      value={screenData.tv_type}
                      onChange={(e) => {
                        setScreenData({
                          ...screenData,
                          tv_type: e.target.value,
                        });
                      }}
                    >
                      <option value="landscape">Landscape</option>
                      <option value="portrait">Portrait</option>
                    </Input>

                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen position: </Label>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="TV position"

                      value={screenData.tv_position}
                      onChange={(e) => {
                        setScreenData({
                          ...screenData,
                          tv_position: e.target.value,
                        });
                      }}
                    >
                      <option value="wall">Wall</option>
                      <option value="roof">Roof</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">
                      internet connection:{" "}
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="TV internet connection"
                      value={screenData.tv_internet_connection}
                      onChange={(e) => {
                        setScreenData({
                          ...screenData,
                          tv_internet_connection: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">
                      Modem Phone:{" "}
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Modem Phone"
                      value={screenData.tv_modem_phone}
                      onChange={(e) => {
                        setScreenData({
                          ...screenData,
                          tv_modem_phone: e.target.value,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen Size: </Label>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="Screen Size"

                      value={screenData.tv_size}
                      onChange={(e) => {
                        setScreenData({
                          ...screenData,
                          tv_size: e.target.value,
                        });
                      }}
                    >
                      <option value="32">32"</option>
                      <option value="40">40"</option>
                      <option value="43">43"</option>
                      <option value="50">50"</option>
                      <option value="55">55"</option>
                      <option value="60">60"</option>
                      <option value="65">65"</option>
                      <option value="70">70"</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="6" className="input--group">
                  <FormGroup>
                    <Label className="form-label">Screen Resolution: </Label>
                    <Input
                      className="form-control"
                      type="select"
                      placeholder="Screen Resolution"

                      value={screenData.tv_resolution}
                      onChange={(e) => {
                        setScreenData({
                          ...screenData,
                          tv_resolution: e.target.value,
                        });
                      }}
                    >
                      <option value="HD">HD</option>
                      <option value="FHD">FHD"</option>
                      <option value="4K">4K</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>



            </form>
          </ModalBody>
          <ModalFooter>
            <Button color="success" disabled={isLoading} onClick={addTv}>
              Add
            </Button>
            <Button color="danger" onClick={Verticalcentermodaltoggle2}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
                    <Row>
                      <Col sm="6">
                      <DataTable
                      title="Spaces"
                      onRowClicked={(row) => {
                        handleRowClicked(row, contract.contractId);
                      }}
                      columns={spacesColumns}
                      data={contractSpaces ?? []}
                      pagination
                      highlightOnHover={true}
                      pointerOnHover={true}
                      actions={
                        <Button color="success" className=" ml-2">
                          <Link
                            to={{
                              pathname: `${process.env.PUBLIC_URL}/spaces/add`,
                              state: { ...contract, ...data.client },
                            }}
                            style={{ color: "white" }}
                          >
                            Add Space
                          </Link>
                        </Button>
                      }
                    />
                      </Col>
                      <Col sm="6">
                      <DataTable
                      title="Text Spaces"
                      onRowClicked={(row) => {
                        handleTextRowClicked(row, contract.contractId);
                      }}
                      columns={textSpacesColumns}
                      data={contractTextSpaces ?? []}
                      pagination
                      highlightOnHover={true}
                      pointerOnHover={true}
                      actions={
                        <Button color="success" className=" ml-2">
                          <Link
                            to={{
                              pathname: `${process.env.PUBLIC_URL}/textspaces/Add`,
                              state: { ...contract, ...data.client },
                            }}
                            style={{ color: "white" }}
                          >
                            Add Text Space
                          </Link>
                        </Button>
                      }
                    />
                      </Col>
                    </Row>
                    
                  </TabPane>
                  <TabPane tabId="6">
                    <DataTable
                      title="Screens"
                      columns={screensColumns}
                      data={data.tvs}
                      pagination
                      highlightOnHover
                      onRowClicked={handleScreenRowClicked}
                      pointerOnHover
                      actions={
                        <Button
                          color="success"
                          className="ml-2"
                          onClick={Verticalcentermodaltoggle2}
                        >
                          Add Screen
                        </Button>
                      }
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
