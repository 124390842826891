import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../layout/loader";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
} from "reactstrap";
import { Button, ButtonGroup } from "reactstrap";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Search } from "react-feather";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function Videos() {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setAlignment] = useState("mp4");
  const token = Cookies.get("jwt");
  const [data, setData] = useState([]);
  const [keyword, setkeyword] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    const request = {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-requested-With, Content-Type, Accept",
        Authorization: "Bearer " + token,
      },
    };
    try {
      var response1 = await fetch(
        `https://admins.adspot.pub/api/admins/videos/all`,
        request,
        { mode: "cors" }
      );

      if (response1.status === 200) {
        let data = await response1.json();
        setData(data.data);
        console.log(data);
      } else {
        toast.error(await response1.json());
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    let unmount = false;
    if (unmount) {
      return;
    }
    fetchData();
    return () => {
      unmount = true;
      setIsLoading(false);
      window.scrollTo(0, 0);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  let pattern = new RegExp(keyword, "i");
  let searchedVideos = data.filter(function (video) {
    return (
      pattern.test(video.client_name) || pattern.test(video.video_product_name)
    );
  });
  const handleChange = (event, newType) => {
    setAlignment(newType);
  };

  return (
    <Fragment>
      <Container fluid="true">
        <Row className="pt-4 px-4">
          <Col col="12">
            <Card>
              <CardBody>
                <Row>
                  <Col col="12">
                    <FormGroup>
                      <Label className="form-label">
                        Search for Video By Video Name or Client Name:
                      </Label>
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <Search />
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          id="inlineFormInputGroup"
                          placeholder="Search"
                          value={keyword}
                          onChange={(e) => {
                            setkeyword(e.target.value);
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row className="pt-4 px-4">
          <Col col="12">
            <Card>
              <ToggleButtonGroup
                className="pt-4 px-4"
                color="primary"
                value={type}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="mp4">Videos</ToggleButton>
                <ToggleButton value="jpg">Pictures</ToggleButton>
              </ToggleButtonGroup>
              <CardBody>
                <Row>
                  {searchedVideos.map((video) => {
                    if (video.landscape_video_link.split(".").pop() == type) {
                      return (
                        // <p style={{ marginLeft: 50 }}>{video.landscape_video_link.split('.').pop()}</p>
                        <Col md="6" xl="4 box-col-6 xl-50" key={video.video_id}>
                          <Card>
                            <div className="blog-box blog-grid text-center">
                              {video.landscape_video_link.split(".").pop() !==
                              "mp4" ? (
                                <img src={video.landscape_video_link} />
                              ) : (
                                <video
                                  id={video.video_name}
                                  controls
                                  width="100%"
                                >
                                  <source
                                    src={video.landscape_video_link}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag. I
                                  suggest you upgrade your browser.
                                </video>
                              )}

                              <div className="blog-details-main">
                                <ul className="blog-social">
                                  <li className="digits">
                                    {video.video_product_name}
                                  </li>
                                  <li className="digits">
                                    {video.landscape_video_length} s
                                  </li>
                                  <li className="digits">
                                    {video.video_status}
                                  </li>
                                </ul>
                                <hr />
                                <h6 className="blog-bottom-details">
                                  {video.client_name}
                                </h6>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
